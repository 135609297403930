import React from "react";
import { useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Box } from "../../../components/Layout";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { approveCustomerCredit } from "../../Registers/registersActions";
import useStyles from "./VendorValidationInfo.style";
import { dateFunction } from "../../../constants/dateFunction";
import I18n from "../../../utils/i18n";

const CustomerInfoCard = (props) => {
  const { type, duplicateKey, customer, customerId, duplicateCustomer } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");

  const topText =
    type === "duplicate"
      ? `${duplicateKey} registrado nuevamente por:`
      : "Esta información corresponde a:";
  const dispatch = useDispatch();

  let actionButton;

  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  const handleClick = (s, m) => {
    setOpen(true);
    setSeverity(s);
    setMessage(m);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  if (props.block) {
    actionButton = (
      /*<Button variant="outlined" color="secondary" onClick={() => dispatch(rejectCustomerCredit(customerId))}>
        Rechazar Crédito
      </Button>*/
      <Text>-</Text>
    );
  } else {
    actionButton = (
      <Button
        variant="outlined"
        color="secondary"
        onClick={() =>
          dispatch(
            approveCustomerCredit(
              customerId,
              customer.account_customer_id,
              duplicateCustomer.account_id
            )
          )
            .then(() => {
              handleClick(
                "success",
                `Proceso de ${i18n.nacionatilityDocument} duplicado finalizado exitosamente!`
              );
            })
            .catch((e) => {
              handleClick(
                "error",
                `Hubo un error procesando ${i18n.nacionatilityDocument} duplicado`
              );
            })
        }
      >
        Aceptar cliente
      </Button>
    );
  }

  return (
    <Box className={classes.CustomerInfoCard}>
      <Text>{topText}</Text>
      {type === "original" && (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            margin: 5,
          }}
        >
          {" "}
          <Text variant="h5">Fecha de registro:</Text>
          {` ${dateFunction(customer.record_date)}`}
        </Box>
      )}
      <Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            margin: 5,
          }}
        >
          <Text variant="h5">Nombre:</Text>
          {`${customer.full_name}`}
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            margin: 5,
          }}
        >
          <Text variant="h5">Perfil:</Text>
          Cliente
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            margin: 5,
          }}
        >
          <Text variant="h5">Teléfono:</Text>
          {` ${customer.phone_number}`}
        </Box>
        <Box style={{ marginTop: 20 }}>{actionButton}</Box>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomerInfoCard;
