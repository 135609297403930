import React from "react";
import moment from "moment";
import "moment/locale/es";

import Text from "../../../../components/Text";
import Icons from "../../../../assets/icons";
import { simpleDateFunction } from "../../../../constants/dateFunction";
import I18n from "../../../../utils/i18n";
moment.locale("es");

const TransactionItem = ({
  dataMovement,
  indexMovements,
  movementList,
  transactionsDetail,
}) => {
  let icon,
    color,
    textDecorationMark = "none",
    isDisbursement,
    isCepURL,
    isPersonalDisbursement,
    isPayment,
    isDeleted,
    titleMovement = "",
    sign = dataMovement.type === "PAYMENT" ? "+" : "",
    date = dataMovement?.date
      ? moment(dataMovement.date).format("hh:mm a ddd DD")
      : "",
    amount = dataMovement?.amount || "",
    principalAmount = dataMovement?.principal_amount || "",
    vatAmount = dataMovement?.vat_amount || "",
    interestAmount = dataMovement?.interest_amount || "",
    inventory = dataMovement?.inventory || "",
    lateVatAmount = dataMovement?.late_vat_amount || "",
    lateInterestAmount = dataMovement?.late_interest_amount || "",
    wasDisbursed = dataMovement?.was_disbursed,
    cepURL = dataMovement?.cep_url || "",
    vourchersDueDate = dataMovement?.vouchers_due_date
      ? simpleDateFunction(dataMovement?.vouchers_due_date)
      : "",
    acceptedAmount = dataMovement?.accepted_amount || 0,
    paidAmount = dataMovement?.paid_amount || 0,
    insurance_amount = dataMovement?.insurance_amount || 0,
    preventInterestDueDate = dataMovement?.prevent_interest_due_date
      ? simpleDateFunction(dataMovement?.prevent_interest_due_date)
      : "",
    allMovements = [],
    buys = [];

  const getBuys = (batchId) => {
    if (
      allMovements.length == 0 &&
      transactionsDetail &&
      transactionsDetail.length > 0
    ) {
      transactionsDetail.map((tx) => {
        allMovements = allMovements.concat(tx.movements);
      });
    }

    let buys = [];
    allMovements.map((movement) => {
      if (
        batchId === movement.batch_id &&
        (movement.type === "INVENTORY_PURCHASE" ||
          movement.type === "SUPPLIER_PURCHASE")
      ) {
        buys = buys.concat(movement);
      }
    });
    return buys;
  };

  switch (dataMovement.type) {
    case "PAYMENT":
      color = "#4db050";
      isPayment = true;
      switch (dataMovement.payment_type) {
        case "ADJUSTMENT":
          titleMovement = "Abono para regularización";
          icon = Icons.iconAdjustment;
          break;
        case "CANCELLATION":
          titleMovement = "Condonación de moratorios";
          icon = Icons.iconCancellation;
          break;
        case "CASHBACK":
          titleMovement = "Abono por promoción";
          icon = Icons.iconCashback;
          break;
        case "RESTRUCTURING":
          titleMovement = "Abono para reestructurar";
          icon = Icons.iconRestructuring;
          break;
        case "CHANGE_OF_CONDITIONS":
          titleMovement = "Abono para cambio de condiciones";
          icon = Icons.iconChangeOfConditions;
          break;
        case "REFERRAL":
          titleMovement = "Abono por referir";
          icon = Icons.iconReferral;
          break;
        default:
          titleMovement = "¡Gracias por tu pago!";
          icon = Icons.amountCuota;
          break;
      }
      break;
    case "BUY":
      color = "#000";
      icon = Icons.buyStock;
      titleMovement = "Compra en Tiendita";
      break;
    case "DISBURSEMENT":
      color = "#000";
      icon = Icons.moneyDisbursment;
      titleMovement = "Disposición";
      isDisbursement = true;
      isPersonalDisbursement = true;
      isCepURL = cepURL !== "" ? true : false;
      break;
    case "INVENTORY_PURCHASE":
      color = "#000";
      icon = Icons.buyStock;
      titleMovement = `Compra de inventario a ${dataMovement.trade_name}`;
      break;
    case "UNVERIFIED_SUPPLIER_PURCHASE":
      color = "#000";
      icon = Icons.totalPaymentTransaction;
      titleMovement = `Recibo sin subir`;
      break;
    case "UNPAID_INVENTORY_PURCHASE":
      color = "#000";
      icon = Icons.totalPaymentTransaction;
      titleMovement = `Fecha límite de pago expirada`;
      buys = getBuys(dataMovement.batch_id);
      break;
    case "LATE_PAYMENT_FEE":
      color = "#000";
      icon = Icons.headerTransaction;
      titleMovement = `Comisión por moratorios`;
      break;
    case "SUPPLIER_PURCHASE":
      color = "#000";
      icon = Icons.disbursmentBuy;
      titleMovement = `Compra a proveedor`;
      isDisbursement = true;
      isCepURL = cepURL !== "" ? true : false;
      break;
    case "DISBURSEMENT_FEE":
      color = "#000";
      icon = Icons.headerTransaction;
      titleMovement = `Comisión por desembolso`;
      break;
    default:
      color = "#000";
      icon = Icons.cashDisbursement;
      titleMovement = "Retiro de efectivo";
      break;
  }

  if (dataMovement.payment_status === "PAID") {
    textDecorationMark = "line-through";
  }
  if (dataMovement.payment_status === "DELETED") {
    titleMovement = "Disposición Cancelada";
    isDeleted = true;
    icon = Icons.deletedDisbursment;
    wasDisbursed = true;
    isPersonalDisbursement = true;
    if (dataMovement.type === "SUPPLIER_PURCHASE") {
      icon = Icons.disbursmentBuy;
      isPersonalDisbursement = false;
      titleMovement = "Cancelada";
    }
  }

  return (
    <div
      style={{
        display: "flex",
        background: "#f6f9fc",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "right",
        padding: 15,
        borderBottom:
          movementList.length !== indexMovements + 1 ? "1px solid #d9e2eb" : "",
        borderTopLeftRadius: indexMovements === 0 ? 10 : 0,
        borderTopRightRadius: indexMovements === 0 ? 10 : 0,
        borderBottomLeftRadius:
          movementList.length === indexMovements + 1 ? 10 : 0,
        borderBottomRightRadius:
          movementList.length === indexMovements + 1 ? 10 : 0,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={icon} style={{ width: 30, height: 30, marginRight: 5 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ flexDirection: "row", display: "flex" }}>
              {isDeleted && (
                <Text
                  style={{
                    fontSize: "14px",
                    color: "black",
                    textDecoration: "line-through",
                    marginRight: "5px",
                  }}
                >
                  {dataMovement.type === "SUPPLIER_PURCHASE"
                    ? "Compra a proveedor"
                    : "Disposición"}
                </Text>
              )}
              <Text
                style={{
                  fontSize: "14px",
                  color: "black",
                  textDecoration: textDecorationMark,
                }}
              >
                {titleMovement}
              </Text>
            </div>
            <Text
              style={{
                fontSize: "14px",
                color: "rgba(102, 110, 117, 0.79)",
                marginTop: 5,
              }}
            >
              {date}
            </Text>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontSize: "14px",
              color: color,
              textDecoration: isDeleted ? "line-through" : textDecorationMark,
            }}
          >
            {`${sign} $ ${amount}`}
          </Text>
        </div>
      </div>
      {isDisbursement && !wasDisbursed && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "15px",
          }}
        >
          <Text align="center">Trabajando en tu transferencia</Text>
        </div>
      )}
      {buys && buys.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "15px 10px 0",
          }}
        >
          {buys.map((buy) => {
            let textDecoration = "none";
            if (buy.payment_status === "PAID") {
              textDecoration = "line-through";
            }
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "12px",
                  textDecoration: textDecoration,
                }}
              >
                <Text>Compra {moment(buy.date).format("hh:mm a ddd DD")}</Text>
                <Text>$ {buy.amount}</Text>
              </div>
            );
          })}
          {paidAmount > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              <Text>Pagos</Text>
              <Text style={{ color: "#4db050" }}>$ {paidAmount}</Text>
            </div>
          )}
        </div>
      )}
      {isDisbursement && wasDisbursed && !isPersonalDisbursement && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "15px 10px 0",
            borderTop: "0.5px solid rgb(217, 226, 235)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "12px",
            }}
          >
            <Text>L&iacute;mite para comprobar</Text>
            <Text
              style={{
                fontWeight: "bolder",
                textDecoration: isDeleted ? "line-through" : textDecorationMark,
              }}
            >
              {vourchersDueDate}
            </Text>
          </div>
          {preventInterestDueDate && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              <Text>L&iacute;mite de pago sin inter&eacute;s</Text>
              <Text
                style={{
                  fontWeight: "bolder",
                  textDecoration: isDeleted
                    ? "line-through"
                    : textDecorationMark,
                }}
              >
                {preventInterestDueDate}
              </Text>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "12px",
            }}
          >
            <Text>Comprobado</Text>
            <Text
              style={{
                textDecoration: isDeleted ? "line-through" : textDecorationMark,
              }}
            >
              $ {acceptedAmount}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "12px",
            }}
          >
            <Text>Por comprobar</Text>
            <Text
              style={{
                textDecoration: isDeleted ? "line-through" : textDecorationMark,
              }}
            >
              $ {amount - acceptedAmount < 0 ? 0 : amount - acceptedAmount}
            </Text>
          </div>
          {paidAmount > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "12px",
                color: "#4db050",
              }}
            >
              <Text>Pagado</Text>
              <Text>$ {paidAmount}</Text>
            </div>
          )}
        </div>
      )}
      {isCepURL && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "15px 10px 0",
            borderTop: "0.5px solid rgb(217, 226, 235)",
          }}
        >
          <Text style={{ marginTop: 15 }}>
            <a target={"_blank"} href={cepURL}>
              Ver comprobante electrónico de pago
            </a>
          </Text>
        </div>
      )}
      {isPayment && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "15px 10px 0",
            borderTop: "0.5px solid rgb(217, 226, 235)",
          }}
        >
          {principalAmount != 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              <Text>Aporte a capital</Text>
              <Text
                style={{
                  fontWeight: "bolder",
                }}
              >
                $ {principalAmount}
              </Text>
            </div>
          )}
          {vatAmount != 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              <Text>{I18n(process.env.REACT_APP_COUNTRY).tax}</Text>
              <Text
                style={{
                  fontWeight: "bolder",
                }}
              >
                $ {vatAmount}
              </Text>
            </div>
          )}
          {interestAmount != 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              <Text>Interés</Text>
              <Text
                style={{
                  fontWeight: "bolder",
                }}
              >
                $ {interestAmount}
              </Text>
            </div>
          )}
          {inventory != 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              <Text>Inventario</Text>
              <Text
                style={{
                  fontWeight: "bolder",
                }}
              >
                $ {inventory}
              </Text>
            </div>
          )}
          {lateVatAmount != 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              <Text>{I18n(process.env.REACT_APP_COUNTRY).tax} Moratorio</Text>
              <Text
                style={{
                  fontWeight: "bolder",
                }}
              >
                $ {lateVatAmount}
              </Text>
            </div>
          )}
          {lateInterestAmount != 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              <Text>Interés Moratorio</Text>
              <Text
                style={{
                  fontWeight: "bolder",
                }}
              >
                $ {lateInterestAmount}
              </Text>
            </div>
          )}
          {insurance_amount != 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              <Text>Seguro</Text>
              <Text
                style={{
                  fontWeight: "bolder",
                }}
              >
                $ {insurance_amount}
              </Text>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TransactionItem;
