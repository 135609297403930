function I18n(country) {
  const langMap = {
    mx: {
      rfc: "RFC",
      rfc_length: 13,
      format_rfc: false,
      generate_rfc: true,
      grocery: "Tienda de Abarrotes y Misceláneas",
      grocery_value: "Tienda de Abarrotes y Misceláneas",
      neighborhood: "Colonia",
      include_zip_code: true,
      company_name: "FRESH LENDING SAPI DE CV",
      tax: "IVA",
      currency: "MXN",
      appName: "YoFio",
      interbankName: "CLABE",
      interbankLength: 18,
      nacionatilityDocument: "CURP",
      country: "mx",
      lada: "+52",
    },

    do: {
      rfc: "RNC",
      rfc_length: 13,
      format_rfc: true,
      generate_rfc: false,
      grocery: "Colmado",
      grocery_value: "Tienda de Abarrotes y Misceláneas",
      neighborhood: "Sector",
      include_zip_code: false,
      company_name: "FRESH LENDING S.A.C.",
      tax: "ITBIS",
      currency: "DOP",
      appName: "Soy",
      interbankName: "NUBE",
      interbankLength: 20,
      nacionatilityDocument: "RNC",
      country: "do",
      lada: "+1",
    },
  };
  return langMap[country];
}

export default I18n;
