function validarRFC(rfc) {
  const regex = /^[A-Z]{4}\d{6}[A-Z\d]{3}$/;
  return regex.test(rfc);
}

function validarRNC(rnc) {
  const regex = /^\d{3}-\d{7}-\d$/;
  return regex.test(rnc);
}

export default function validateTaxId(taxId, country) {
  if (country === "mx") {
    return validarRFC(taxId);
  }
  if (country === "do") {
    return validarRNC(taxId);
  }
  return false;
}
