import React from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Text from "../../../components/Text";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { Box } from "../../../components/Layout";
import Button from "../../../components/Button";
import useStyles from "./IdentityValidationInfo.style";
import MissingDataAlert from "../../../components/MissingDataAlert/MissingDataAlert";
import I18n from "../../../utils/i18n";

const IdentityValidationInfo = (props) => {
  const { curp, document_type, status, mati_url } = props;

  let missingInfo = false;

  if (!curp || !status || !mati_url || !document_type) {
    missingInfo = true;
  }

  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  const classes = useStyles();
  let alertText;
  let alertStatus;

  switch (status) {
    case "verified":
      alertText = "Información de documento verificada.";
      alertStatus = "GOOD";
      break;
    case "NO_MATCH":
      alertText =
        "La información introducida por el usuario y la proveniente de MATI es diferente";
      alertStatus = "GOOD";
      break;
    case "REJECTED":
      alertText = "Documento rechazado";
      alertStatus = "BAD";
      break;
    case "DELETED":
      alertText = "Documento rechazado";
      alertStatus = "BAD";
      break;
    case "REVIEWNEEDED":
      alertText =
        "Información de documento NO VALIDADA por MATI. Requiere verificación manual.";
      alertStatus = "BAD";
      break;
    default:
      alertText = "Error desconocido";
      alertStatus = "BAD";
      break;
  }

  return (
    <Box className={classes.IdentityValidationInfoContainer}>
      <MissingDataAlert status={alertStatus} text={alertText} />
      <SectionTitle>INFORMACIÓN DE MATI</SectionTitle>
      <Text>Tipo de Documento: {document_type}</Text>
      <Text>
        `${i18n.nacionatilityDocument} : {curp}`
      </Text>
      <a target="_blank" href={mati_url}>
        <Button disabled={!mati_url} className={classes.matiButton}>
          <Text className={classes.buttonLabel}>Ver en MATI</Text>
          <OpenInNewIcon htmlColor={!mati_url ? "#0044a8" : "white"} />
        </Button>
      </a>
    </Box>
  );
};

export default IdentityValidationInfo;
