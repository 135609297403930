import React from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnSharpIcon from "@material-ui/icons/LocationOnSharp";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";

import Text from "../Text";
import TopMenuItem from "../../containers/Shop/SubMenu/TopMenuItem";
import useStyles from "./TopBarBuyDealer.styles";
import defaultUser from "../../assets/user_profile.png";
import { statusType } from "../../constants/constants";
import I18n from "../../utils/i18n";

const TopBarBuyDealer = ({ shokeeper_detail, credit_line_shop_detail }) => {
  const classes = useStyles();
  const img = shokeeper_detail.shop?.BuildingPhotoURL || defaultUser;
  let colorStatus;
  let colorAllowDisbursment = shokeeper_detail.allow_disbursements
    ? "#009805"
    : "#ee4e52";
  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  if (Object.keys(shokeeper_detail).length !== 0) {
    if (shokeeper_detail.status !== "CURRENT") {
      colorStatus = "#ee4e52";
    } else {
      colorStatus = "#009805";
    }
  }

  return (
    <div className={classes.topBarDetail}>
      <div className={classes.containerLogo}>
        <img className={classes.userLogo} src={img} alt="shop_logo" />
        <TopMenuItem
          mainText={shokeeper_detail.shop?.PersonhoodName || "-"}
          icon={<PhoneIcon fontSize="small" style={{ marginRight: "4px" }} />}
          secondText={shokeeper_detail.owner_data?.PhoneNumber || "-"}
        />
      </div>
      <TopMenuItem
        mainText={shokeeper_detail.address?.Delegation || "-"}
        icon={
          <LocationOnSharpIcon
            fontSize="small"
            style={{ marginRight: "4px" }}
          />
        }
        secondText={"Municipio"}
        styleContainer={{ marginLeft: "10px" }}
      />
      <TopMenuItem
        mainText={shokeeper_detail.owner_data?.FullName || "-"}
        icon={
          <PhoneAndroidIcon fontSize="small" style={{ marginRight: "4px" }} />
        }
        secondText={shokeeper_detail.shop?.BusinessPhoneNumber || "-"}
        styleContainer={{ marginLeft: "10px" }}
      />
      <TopMenuItem
        mainText={credit_line_shop_detail.sales_advisor?.advisor_name || "-"}
        rigthIcon={
          <PhoneAndroidIcon
            fontSize="small"
            style={{ marginRight: "4px", color: "transparent" }}
          />
        }
        secondText={"Asesor"}
        styleContainer={{ marginLeft: "10px" }}
      />
      <TopMenuItem
        mainText={
          credit_line_shop_detail?.bank && credit_line_shop_detail.clabe
            ? credit_line_shop_detail.clabe
            : "-"
        }
        rigthIcon={
          <PhoneAndroidIcon
            fontSize="small"
            style={{ marginRight: "4px", color: "transparent" }}
          />
        }
        secondText={`${i18n.interbankName} Pago a ${i18n.appName} por STP`}
        styleContainer={{ marginLeft: "10px" }}
      />
      <TopMenuItem
        rigthIcon={
          <PhoneAndroidIcon
            fontSize="small"
            style={{ marginRight: "4px", color: "transparent" }}
          />
        }
        secondText={"Estatus"}
        mainTextComponent={
          <Text className={classes.mainTextBar} style={{ color: colorStatus }}>
            {statusType[credit_line_shop_detail.status]}
          </Text>
        }
        styleContainer={{ marginLeft: "10px" }}
      />
      <TopMenuItem
        rigthIcon={
          <PhoneAndroidIcon
            fontSize="small"
            style={{ marginRight: "4px", color: "transparent" }}
          />
        }
        secondText={"Bloqueo"}
        mainTextComponent={
          <Text
            className={classes.mainTextBar}
            style={{ color: colorAllowDisbursment }}
          >
            {shokeeper_detail.allow_disbursements ? "Sin bloqueo" : "Bloqueado"}
          </Text>
        }
        styleContainer={{ marginLeft: "10px" }}
      />
    </div>
  );
};

export default TopBarBuyDealer;
