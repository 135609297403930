import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import DocumentPdfViewer from "./DocumentPdf";
import LabelValueTextList from "../../../components/LabelValueTextList/LabelValueTextList";
import Text from "../../../components/Text";
import ContractPdfViewer from "./ContractPdf";
import { postWhatsappMessage } from "../../../reducers/notifications.action";
import { getBureaus } from "../../../reducers/users.action";
import AdminAction from "../../../components/AdminAction/AdminAction";
import ComparisonComponent from "../../../components/ComparisonComponent/ComparisonComponent";
import BureauDatePicker from "../../../components/BureauDatePicker/BureauDatePicker";
import ApprovalTableForm from "../../../components/ApprovalTableForm/ApprovalTableForm";
import I18n from "../../../utils/i18n";
const DocumentTab = ({
  phone_number,
  avals,
  account_type_id,
  account_id,
  isOpenApprovalTable,
  veredictInputErrors,
  checkboxValues,
  handleCheckboxChange,
  handleVeredictInputChange,
  veredictInputValues,
  shopkeeperInfo,
}) => {
  const [openMessageSnackbar, setOpenMessageSnackbar] = useState(false);
  const [isOpenBureauModal, setIsOpenBureauModal] = useState(false);
  const [collateralPhone, setCollateralPhone] = useState("");
  const [selectedCollateral, setSelectedCollateral] = useState("");
  const [firstSelectedItemInfo, setFirstSelectedItemInfo] = useState(null);
  const [secondSelectedItemInfo, setSecondSelectedItemInfo] = useState(null);
  const [isError, setIsError] = useState(false);
  const [userType, setUserType] = useState("");
  const [isComparison, setIsComparison] = useState(false);
  const [accountPdfPath, setAccountPdfPath] = useState(null);
  const [partnerPdfPath, setPartnerPdfPath] = useState(null);
  const [collateralPdfPath, setCollateralPdfPath] = useState(null);
  const [collateralPdfPaths, setCollateralPdfPaths] = useState([]);
  const { error, loading } = useSelector((state) => state.notificationsReducer);
  const { error: usersError, bureausData } = useSelector(
    (state) => state.usersReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (error?.POST_WHATSAPP_MESSAGE) {
      if (error?.POST_WHATSAPP_MESSAGE != null) {
        setIsError(true);
        setOpenMessageSnackbar(true);
      }
    }
  }, [error?.POST_WHATSAPP_MESSAGE]);

  useEffect(() => {
    if (usersError?.GET_BUREAUS) {
      if (usersError?.GET_BUREAUS != null) {
        setIsError(true);
      }
    }
  }, [usersError?.GET_BUREAUS]);

  useEffect(() => {
    dispatch(getBureaus(account_type_id, account_id));
  }, []);

  useEffect(() => {
    if (bureausData && bureausData.data && bureausData.data.Payload) {
      const accountData = bureausData.data.Payload.find(
        (entry) => entry.person_type === "ACCOUNT"
      );
      const partnerData = bureausData.data.Payload.find(
        (entry) => entry.person_type === "PARTNER"
      );
      const collateralData = bureausData.data.Payload.filter(
        (entry) => entry.person_type === "COLLATERAL"
      );
      if (accountData) {
        setAccountPdfPath(accountData.pdf_path);
      }
      if (partnerData) {
        setPartnerPdfPath(partnerData.pdf_path);
      }
      if (collateralData && collateralData.length > 0) {
        const collateralPdfPaths = {};
        collateralData.forEach((collateral) => {
          if (
            !collateralPdfPaths[collateral.phone_number] ||
            new Date(collateralPdfPaths[collateral.phone_number].created_at) <
              new Date(collateral.created_at)
          ) {
            collateralPdfPaths[collateral.phone_number] = collateral;
          }
        });
        const uniqueCollateralPdfPaths = {};
        Object.keys(collateralPdfPaths).forEach((phone_number) => {
          uniqueCollateralPdfPaths[phone_number] =
            collateralPdfPaths[phone_number].pdf_path;
        });
        setCollateralPdfPaths(uniqueCollateralPdfPaths);
      }
    }
  }, [bureausData]);

  const sendWhatsappMessage = (phoneNumber) => {
    let body = {
      phone_number: phoneNumber,
      account_type_id: account_type_id,
      account_id: account_id,
      message:
        "Hola! Al continuar autorizas la consulta de tu historial crediticio a favor FRESH LENDING S.A.P.I. DE C.V.",
      template_name: "auto_reconsulta",
    };
    dispatch(postWhatsappMessage(body)).then(handleOpenMessageStatusSnackbar());
  };

  const handleOpenMessageStatusSnackbar = () => {
    setOpenMessageSnackbar(true);
    setIsOpenBureauModal(false);
    setTimeout(() => {
      setOpenMessageSnackbar(false);
    }, 3000);
  };

  const handleButtonClick = () => {
    setIsComparison(true);
  };

  const handleChangeUserType = (event) => {
    setUserType(event.target.value);
    setSelectedCollateral("");
    setCollateralPhone("");
  };

  const handleChangeCollateral = (event) => {
    const selectedValue = event.target.value;
    setSelectedCollateral(selectedValue);

    const selectedAval = avals.find(
      (aval) => aval.name + " " + aval.lastname === selectedValue
    );
    if (selectedAval) {
      setCollateralPhone(selectedAval.phone);
    }
  };

  const handleCloseMessageStatusSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessageSnackbar(false);
  };

  const handleFirstItem = (itemInfo) => {
    setFirstSelectedItemInfo(itemInfo);
  };

  const handleSecondItem = (itemInfo) => {
    setSecondSelectedItemInfo(itemInfo);
  };

  const avalTypeText = (aval_type) => {
    switch (aval_type) {
      case "PARTNER":
        return "Obligado Solidario";
      case "COLLATERAL":
        return "Aval";
      default:
        return "No Definido";
    }
  };

  return (
    <Box display="flex" flexDirection="row">
      <Box flex={1}>
        <Box style={{ marginBottom: 25 }}>
          {Object.keys(bureausData).length !== 0 && !isComparison ? (
            <ComparisonComponent onClick={handleButtonClick} />
          ) : (
            Object.keys(bureausData).length !== 0 &&
            isComparison && (
              <Box flexDirection={"row"} display={"flex"}>
                <BureauDatePicker
                  dateData={bureausData?.data?.Payload}
                  onSelectedItemChange={handleFirstItem}
                  selectedValue={firstSelectedItemInfo}
                  setSelectedValue={setFirstSelectedItemInfo}
                />
                <BureauDatePicker
                  dateData={bureausData?.data?.Payload}
                  onSelectedItemChange={handleSecondItem}
                  selectedValue={setSecondSelectedItemInfo}
                  setSelectedValue={setSecondSelectedItemInfo}
                />
              </Box>
            )
          )}
          <AdminAction
            splashBtnText="Reconsulta de buró"
            isOpenModal={isOpenBureauModal}
            setOpenModal={setIsOpenBureauModal}
            isOpenSnackbar={openMessageSnackbar}
            handleCloseSnackbar={handleCloseMessageStatusSnackbar}
            isLoading={loading?.POST_WHATSAPP_MESSAGE}
            msgType={isError ? "error" : "success"}
            snackBarMsg={
              isError
                ? error?.POST_WHATSAPP_MESSAGE?.response?.data?.readable_message
                : "Mensaje enviado correctamente"
            }
            onClickSplashBtn={() => setIsOpenBureauModal(true)}
            children={
              <FormControl style={{ width: 150 }}>
                <InputLabel>Participantes:</InputLabel>
                <Select value={userType} onChange={handleChangeUserType}>
                  <MenuItem value={"OWNER"}>Propietario</MenuItem>
                  {avals.some((aval) => aval.aval_type === "PARTNER") && (
                    <MenuItem value={"PARTNER"}>Obligado Solidario</MenuItem>
                  )}
                  {avals.some((aval) => aval.aval_type === "COLLATERAL") && (
                    <MenuItem value={"COLLATERAL"}>Aval</MenuItem>
                  )}
                </Select>
                {userType === "COLLATERAL" && (
                  <FormControl style={{ marginTop: 20, marginBottom: 20 }}>
                    <InputLabel>Aval:</InputLabel>
                    <Select
                      value={selectedCollateral}
                      onChange={handleChangeCollateral}
                    >
                      {avals
                        .filter((aval) => aval.aval_type === "COLLATERAL")
                        .map((aval) => (
                          <MenuItem
                            key={aval.name}
                            value={aval.name + " " + aval.lastname}
                          >
                            {aval.name +
                              " " +
                              aval.lastname +
                              " - " +
                              aval.phone}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </FormControl>
            }
            onClickModalBtn={() =>
              userType === "OWNER"
                ? sendWhatsappMessage(phone_number)
                : userType === "PARTNER"
                ? sendWhatsappMessage(
                    avals.find((aval) => aval.aval_type === "PARTNER").phone
                  )
                : sendWhatsappMessage(collateralPhone)
            }
          />
        </Box>
        {isComparison ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: 12,
            }}
          >
            {isComparison &&
              firstSelectedItemInfo &&
              secondSelectedItemInfo && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: 12,
                  }}
                >
                  <DocumentPdfViewer
                    pdf_path={firstSelectedItemInfo?.pdf_path}
                  />
                  <DocumentPdfViewer
                    pdf_path={secondSelectedItemInfo?.pdf_path}
                  />
                </Box>
              )}
          </Box>
        ) : (
          <>
            <Text variant="h3">Bur&oacute; del Titular</Text>
            <DocumentPdfViewer pdf_path={accountPdfPath} />
            {avals.map((aval) => {
              return (
                <Box style={{ marginTop: "35px" }}>
                  <Box>
                    <Text variant="h3">
                      Buró del {avalTypeText(aval.aval_type)}
                    </Text>
                    <Box style={{ display: "flex" }}>
                      <LabelValueTextList
                        rows={[
                          {
                            label: "Nombre",
                            value: `${aval.name} ${aval.lastname} ${aval.maternal_lastname}`,
                          },
                          {
                            label: "Teléfono",
                            value: aval.phone,
                          },
                          {
                            label: I18n(process.env.REACT_APP_COUNTRY).rfc,
                            value: aval.rfc,
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                  <DocumentPdfViewer
                    pdf_path={
                      aval.aval_type === "PARTNER"
                        ? partnerPdfPath
                        : collateralPdfPaths[aval.phone]
                    }
                  />
                </Box>
              );
            })}
            <Box style={{ marginTop: "35px" }}>
              <Text variant="h3">Contrato</Text>
              <ContractPdfViewer
                account_type_id={account_type_id}
                account_id={account_id}
              />
            </Box>
          </>
        )}
      </Box>
      {isOpenApprovalTable && (
        <Box flex={1} marginLeft={15}>
          <ApprovalTableForm
            checkboxValues={checkboxValues}
            handleCheckboxChange={handleCheckboxChange}
            handleInputChange={handleVeredictInputChange}
            inputValues={veredictInputValues}
            shopkeeperInfo={shopkeeperInfo}
            inputErrors={veredictInputErrors}
          />
        </Box>
      )}
    </Box>
  );
};

export default DocumentTab;
