import React from "react";
import moment from "moment";
import "moment/locale/es";

import useStyles from "../SubMenuPage.styles";
import Text from "../../../../components/Text";
import I18n from "../../../../utils/i18n";

moment.locale("es");

const LegacyTransactionItem = (props) => {
  const classes = useStyles();
  let textName;
  let sign = props.operationType === "debit" ? "-" : "+";
  let color = props.operationType === "debit" ? "#e77672" : "#4db050";
  const i18n = I18n(process.env.REACT_APP_COUNTRY);
  const formatDate = moment(props.date ? props.date : Date.now).format(
    "DD [de] MMMM [a las] HH:mm"
  );
  switch (props.transactionType) {
    case "Buy":
      textName = `🤑 Le vendiste a ${props.customerName}`;
      break;
    case "Pay":
      textName = `💵 Le cobraste a ${props.customerName}`;
      break;
    case "Wd":
      textName = "📆 Yo fio te paga tus ventas";
      break;
    case "Rf":
      textName = "😎 Por referir usuarios";
      break;
    case "Dp":
      textName = `👍 Depositaste a ${i18n.appName}`;
      break;
    case "SkIR":
      textName = `🎁 Te cobramos por saldo a favor de ${i18n.appName}`;
      textName = props.productName;
      break;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Text
          style={{
            color: "#000000",
            fontWeight: "normal",
            marginBottom: "5px",
            fontSize: "13px",
          }}
        >
          {textName}
        </Text>
        <Text
          className={classes.transactionTitleText}
          style={{
            color: "#858d9c",
            fontWeight: "normal",
            marginBottom: "5px",
            fontSize: "11px",
          }}
        >
          {formatDate}
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Text style={{ fontSize: "13px", color: color }}>
          {props?.amount ? sign + props.amount : "$0.00"}
        </Text>
      </div>
    </div>
  );
};

export default LegacyTransactionItem;
