import React from "react";
import { useSelector } from "react-redux";
import { Box } from "../../../components/Layout";
import useStyles from "./VendorValidationInfo.style";
import CustomerInfoCard from "./CustomerInfoCard";
import Text from "../../../components/Text";
import MissingDataAlert from "../../../components/MissingDataAlert/MissingDataAlert";
import I18n from "../../../utils/i18n";

const VendorValidationInfo = (props) => {
  const classes = useStyles();

  const { yofio_validation_info } = props;

  const { displayedCustomer: customer } = useSelector(
    (state) => state.registersState
  );

  const { user_incident: duplicateCustomer, status } = yofio_validation_info;

  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  return (
    <Box>
      {status === "No match" && (
        <MissingDataAlert text="Error NO MATCH" status="BAD" />
      )}
      {status === "approved" && (
        <MissingDataAlert
          text="Todos los documentos del cliente han sido validados correctamente."
          status="GOOD"
        />
      )}
      {status === "DUPLICATED_DOCUMENT" && (
        <Box className={classes.VendorValidationInfoContainer}>
          <MissingDataAlert
            status="BAD"
            text={`Documento ${i18n.nacionatilityDocument} duplicado en el sistema.`}
          />
          <Text>
            {i18n.nacionatilityDocument}
            {customer.personal_data.curp}
          </Text>
          {
            <Box className={classes.CardContainer}>
              <CustomerInfoCard
                duplicateCustomer={duplicateCustomer}
                customer={customer.personal_data}
                customerId={customer.personal_data.user_auth_id}
                type="original"
              />
              <CustomerInfoCard
                customerId={duplicateCustomer.account_id}
                customer={duplicateCustomer}
                block
                type="duplicate"
                duplicateKey="CURP"
              />
            </Box>
          }
        </Box>
      )}
    </Box>
  );
};

export default VendorValidationInfo;
