import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import useStyles from "./App.styles";
import { Box } from "../components/Layout";
import { darkTheme, lightTheme } from "./Theme";
import useFirebaseAuth from "../hooks/useFirebaseAuth";
import I18n from "../utils/i18n";
import logoMap from "../utils/logos";
import { Helmet } from "react-helmet";

const App = (props) => {
  const { isAuthorized } = useFirebaseAuth();

  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  const history = useHistory();

  useEffect(() => {
    if (!isAuthorized) {
      history.push(
        "/?previous_path=" + encodeURIComponent(window.location.pathname)
      );
    }
  }, [isAuthorized]);

  const { children } = props;
  const { isLightThemeSelected } = useState(true);
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" href={logoMap[i18n.country]} />
        <title>{`${i18n.appName} Admin`}</title>
      </Helmet>
      <MuiThemeProvider theme={isLightThemeSelected ? lightTheme : darkTheme}>
        <Box className={classes.container}>{children}</Box>
      </MuiThemeProvider>
    </>
  );
};

App.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.shape({}),
};

App.defaultProps = {
  classes: {},
};

export default App;
