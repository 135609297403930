import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MenuItem from "@material-ui/core/MenuItem";
import {
  TextField,
  Select,
  FormHelperText,
  Input,
  InputAdornment,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@material-ui/core/FormControl";

import { getFindShop } from "./expressAction";
import * as ACTIONS from "./actionTypes";
import PageContainer from "../../components/PageContainer";
import Box from "../../components/Layout/Box";
import Button from "../../components/Button";
import SectionTitle from "../../components/SectionTitle";
import Text from "../../components/Text";
import MissingDataAlert from "../../components/MissingDataAlert/MissingDataAlert";

const ExpressExtension = ({
  classes,
  dispatch,
  values,
  setValues,
  handleChange,
  errorMessages,
  setErrorMessages,
  errorMsgs,
  handleErrorMessages,
  nothingFoundNoticeShown,
  showNothingFoundNotice,
  inputValue,
  setInputValue,
  MonthlyDays,
  advisorList,
  shops,
  shopList,
  CssTextField,
  creatingExtensionCreditLine,
  setCreatingExtensionCreditLine,
  handleSubmit,
  i18n,
}) => {
  console.log(shops);
  return (
    <PageContainer>
      <Box className={classes.mainPanel}>
        <SectionTitle>Ampliaci&oacute;n Express</SectionTitle>
      </Box>

      <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
        <Text variant="h4" className={classes.textBlue}>
          Tipo de cr&eacute;dito
        </Text>
        <Select
          displayEmpty
          style={{ display: "flex" }}
          labelId="demo-simple-select-label"
          id="advisor"
          value={values.accountType}
          onChange={handleChange("accountType")}
        >
          <MenuItem value="" disabled>
            Selecciona un tipo de cr&eacute;dito
          </MenuItem>
          <MenuItem value="SK">Tendero</MenuItem>
        </Select>
        <FormHelperText>{errorMessages.accountType}</FormHelperText>
      </Box>

      <Box width="100%">
        <Box>
          {nothingFoundNoticeShown && (
            <MissingDataAlert status="BAD" text="No se encontraron tiendas" />
          )}
        </Box>

        <Box>
          <Text variant="h4" className={classes.textBlue}>
            Nombre de la tienda
          </Text>
        </Box>

        <Box component="form" className={classes.searchContainer}>
          <InputBase
            value={inputValue}
            onChange={(event) => {
              setInputValue(event.target.value);
              dispatch({
                type: ACTIONS.SET_STATE,
                label: "searchString",
                value: event.target.value,
              });
            }}
            className={classes.input}
            placeholder="Nombre de la tienda"
            inputProps={{ "aria-label": "search user" }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                //dispatch(getFindShop());
                event.preventDefault();
              }
            }}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            onClick={() => {
              dispatch(getFindShop());
            }}
            className={classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Box>
        <FormHelperText>{errorMessages.shopSelected}</FormHelperText>

        {shops && shops.length >= 1 ? (
          <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>
              Elige una tienda
            </Text>
            <Select
              displayEmpty
              style={{ display: "flex" }}
              labelId="demo-simple-select-label"
              id="advisor"
              value={values.shopSelected}
              onChange={handleChange("shopSelected")}
            >
              <MenuItem value="" disabled>
                Selecciona una tienda
              </MenuItem>
              {shopList}
            </Select>
          </Box>
        ) : (
          <Box></Box>
        )}
        {values.shopSelected ? (
          <Box>
            <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                id="custom-css-standard-input"
                label="Responsable de la tienda"
                defaultValue=""
                value={values.shopSelected.shopkeeper_name}
                InputLabelProps={{ shrink: true }}
              />
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                label="Teléfono del responsable"
                id="custom-css-outlined-input"
                defaultValue="No viene del WS"
                value={values.shopSelected.shopkeeper_phone_number}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                label={`${i18n.nacionatilityDocument} del responsable de la tienda`}
                id="custom-css-outlined-input"
                defaultValue=""
                value={values.shopSelected.shopkeeper_curp}
                InputLabelProps={{ shrink: true }}
              />
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                label="Dirección de la tienda"
                id="custom-css-standard-input"
                defaultValue=""
                value={`${values.shopSelected.street_name} ${values.shopSelected.street_number} ${values.shopSelected.apartment_number} CP ${values.shopSelected.zip_code} - ${values.shopSelected.neighborhood}, ${values.shopSelected.delegation}, ${values.shopSelected.state}`}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
        ) : (
          <Box></Box>
        )}

        <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
          <Text variant="h4" className={classes.textBlue}>
            ¿Cu&aacute;nto necesita?
          </Text>
          <Input
            id="credit-amount"
            style={{ display: "flex" }}
            value={values.amount}
            type="number"
            onChange={handleChange("amount")}
            placeholder="De $5,000 a $20,000"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
          <FormHelperText>{errorMessages.amount}</FormHelperText>
        </Box>

        <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
          <Text variant="h4" className={classes.textBlue}>
            ¿Cu&aacute;nto dinero puede pagar semanalmente?
          </Text>
          <Input
            id="installment-amount"
            style={{ display: "flex" }}
            value={values.installment}
            type="number"
            onChange={handleChange("installment")}
            placeholder="Escribe la cantidad"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
          <FormHelperText>{errorMessages.installment}</FormHelperText>
        </Box>

        <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
          <Text variant="h4" className={classes.textBlue}>
            ¿Con que frecuencia va a realizar el pago?
          </Text>
          <Select
            displayEmpty
            style={{ display: "flex" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.payment_frequency}
            onChange={handleChange("payment_frequency")}
          >
            <MenuItem value="" disabled>
              Selecciona la frecuencia de pago
            </MenuItem>
            <MenuItem value={"WEEKLY"}>Semanal</MenuItem>
            <MenuItem value={"BIWEEKLY"}>Catorcenal</MenuItem>
            <MenuItem value={"MONTHLY"}>Mensual</MenuItem>
          </Select>
          <FormHelperText>{errorMessages.payment_frequency}</FormHelperText>
        </Box>
        {values.payment_frequency === "MONTHLY" && (
          <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>
              Selecciona un día del mes para realizar tú pago
            </Text>
            <Select
              displayEmpty
              style={{ display: "flex" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.monthly_day}
              onChange={handleChange("monthly_day")}
            >
              <MenuItem value="" disabled>
                Selecciona un día
              </MenuItem>
              {MonthlyDays()}
            </Select>
            <FormHelperText>{errorMessages.monthly_day}</FormHelperText>
          </Box>
        )}
        {values.payment_frequency !== "MONTHLY" &&
          values.payment_frequency !== "" && (
            <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>
                ¿Qu&eacute; día va a realizar el pago?
              </Text>
              <Select
                displayEmpty
                style={{ display: "flex" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.day}
                onChange={handleChange("day")}
              >
                <MenuItem value="" disabled>
                  Selecciona el día
                </MenuItem>
                <MenuItem value={"MONDAY"}>Lunes</MenuItem>
                <MenuItem value={"TUESDAY"}>Martes</MenuItem>
                <MenuItem value={"WEDNESDAY"}>Miércoles</MenuItem>
                <MenuItem value={"THURSDAY"}>Jueves</MenuItem>
                <MenuItem value={"FRIDAY"}>Viernes</MenuItem>
              </Select>
              <FormHelperText>{errorMessages.day}</FormHelperText>
            </Box>
          )}

        <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
          <Text variant="h4" className={classes.textBlue}>
            N&uacute;mero de cartera
          </Text>
          <Select
            displayEmpty
            style={{ display: "flex" }}
            labelId="demo-simple-select-label"
            id="advisor"
            value={values.advisor}
            onChange={handleChange("advisor")}
          >
            <MenuItem value="" disabled>
              Selecciona la cartera
            </MenuItem>
            {advisorList}
          </Select>
          <FormHelperText>{errorMessages.advisor}</FormHelperText>
        </Box>

        <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
          <Text variant="h4" className={classes.textBlue}>
            ¿Cu&aacute;l es el inter&eacute;s <b>mensual</b> de la l&iacute;nea
            de cr&eacute;dito?
          </Text>
          <Input
            id="bank-account-number"
            style={{ display: "flex" }}
            value={values.interest_rate}
            type="number"
            onChange={handleChange("interest_rate")}
            placeholder="Ingresa el inter&eacute;s de la l&iacute;nea de cr&eacute;dito"
            inputProps={{ maxLength: 5 }}
          />
          <FormHelperText>{errorMessages.interest_rate}</FormHelperText>
        </Box>

        <Box style={{ width: "35%" }}>
          <FormControl className={classes.formStyles}>
            <Box className={classes.buttonContainer}>
              <Button
                variant="outlined"
                className={classes.button}
                style={{ backgroundColor: "#0253cc", color: "white" }}
                onClick={() => {
                  handleSubmit();
                }}
                disabled={creatingExtensionCreditLine}
              >
                Crear extensi&oacute;n de cr&eacute;dito
              </Button>
            </Box>
            <Box
              style={{
                display: creatingExtensionCreditLine ? "block" : "none",
              }}
            >
              <CircularProgress />
            </Box>
          </FormControl>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default ExpressExtension;
