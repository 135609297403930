import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  CircularProgress,
  FormControl,
  List,
  MenuItem,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box } from "../../components/Layout";
import Text from "../../components/Text";
import useStyles from "./CustomerDetails.style";
import PageContainer from "../../components/PageContainer/PageContainer";
import { SectionTitle } from "../../components/SectionTitle/SectionTitle";
import CustomerValidationStatus from "./CustomerValidationStatus/CustomerValidationStatus";
import { getClient } from "../Registers/registersActions";
import { postWhatsappMessage } from "../../reducers/notifications.action";
import StepsInfo from "../../components/StepsInfo";
import MissingDataAlert from "../../components/MissingDataAlert/MissingDataAlert";
import BlockAccountModal from "../../components/BlockAccountModal/BlockAccountModal";
import GeneralTab from "./GeneralTab/GeneralTab";
import SubMenuTabBar from "./SubMenuTabBar/SubMenuTabBar";
import InventoryTab from "./InventoryTab/InventoryTab";
import DocumentTab from "./DocumentsTab/DocumentTab";
import TableDatingHistory from "../../components/TableDatingHistory";
import CustomModal from "../../components/CustomModal/CustomModal";
import MessageListItem from "../../components/MessageListItem/MessageListItem";
import Button from "../../components/Button/Button";
import { Alert } from "@material-ui/lab";
import CopyButton from "../../components/CopyButton/CopyButton";
import AdminAction from "../../components/AdminAction/AdminAction";
import { getRejectReasonsCatalog } from "../../reducers/catalog.action";
import {
  postRejectRegister,
  putAssignPassword,
  putLightCreditLine,
} from "../../reducers/users.action";
import I18n from "../../utils/i18n";

export const CustomerDetails = (props) => {
  const dispatch = useDispatch();
  const { displayedCustomer: customer, isFetchingCustomers } = useSelector(
    (state) => state.registersState
  );
  const { error } = useSelector((state) => state.notificationsReducer);
  const { error: usersError } = useSelector((state) => state.usersReducer);

  const { rejectReasonsCatalog } = useSelector((state) => state.catalogReducer);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const { customerType, customerID } = props.match.params;
  const [showSpinner, setShowSpinner] = useState("none");
  const [subMenu, setSubMenu] = useState("general");
  const [isError, setIsError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openLostLeadSnackbar, setOpenLostLeadSnackbar] = useState(false);
  const [openMessageSnackbar, setOpenMessageSnackbar] = useState(false);
  const [isApprovalTableVisible, setIsApprovalTableVisible] = useState(false);
  const [selectedReasonId, setSelectedReasonId] = useState("");
  const [isOpenAssignPasswordSnackbar, setIsOpenAssignPasswordSnackbar] =
    useState(false);
  const [isOpenLightStatusSnackbar, setIsOpenLightStatusSnackbar] =
    useState(false);
  const [selectedMessage, setSelectedMessage] = useState(
    "💡 Los rechazos se deben a que no se cumplieron con todos los requisitos. Te recordamos que para poder ofrecerte un mejor servicio, nosotros evaluamos lo siguiente:\n📍 Ubicarse dentro de nuestras zonas con cobertura \n🔎 Buró de Crédito \n👶 Edad del dueño y antigüedad del negocio \n 🏬 Giro comercial de tu negocio \n💵 Ventas semanales\n\n😕 Si no cumples con alguno de estos requisitos, no podremos otorgar tu línea de crédito."
  );
  const [checkboxValues, setCheckboxValues] = useState({});
  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    additionalInput: "",
  });
  const [inputErrors, setInputErrors] = useState({
    input1: false,
    input2: false,
  });

  const headers = [
    "Rechazo causas",
    "Fuera de zona",
    "Reagendar cita",
    "Cita agendar",
    "Registro inicio",
    "48 horas",
    "Documentos",
    "Cierre inactividad",
    "Objeción",
    "Rechazo Buró",
    "Rechazo General",
    "Cierre conversación",
    "Presentación Lau",
    "Presentación Jaz",
  ];
  const messages = [
    "💡 Los rechazos se deben a que no se cumplieron con todos los requisitos. Te recordamos que para poder ofrecerte un mejor servicio, nosotros evaluamos lo siguiente:\n📍 Ubicarse dentro de nuestras zonas con cobertura \n🔎 Buró de Crédito \n👶 Edad del dueño y antigüedad del negocio \n 🏬 Giro comercial de tu negocio \n💵 Ventas semanales\n\n😕 Si no cumples con alguno de estos requisitos, no podremos otorgar tu línea de crédito.",
    "💙 Agradecemos tu interés pero por el momento no contamos con cobertura en tu zona. pero no te preocupes muy pronto estaremos expandiéndonos a nuevos territorios.\n👍 Síguenos en redes sociales y no te pierdas ninguna sorpresa.",
    "😕 Nos intentamos comunicar contigo para realizar tu videollamada pero no tuvimos éxito \n\n¿Para cuando reagendamos tu entrevista? ",
    "🚀 El siguiente paso para finalizar con tu proceso es agendar una videollamada ¡Esto te ayudará a obtener hoy mismo tu línea de crédito sin salir de tu negocio!\n\n⏰ ¿En qué horario te gustaría programarla?\n\n*Recuerda que es importante encontrarte en tu negocio para realizar tu videollamada*",
    "🚀 ¡Comencemos! Inicia tu solicitud dando clic al siguiente enlace\n👉 https://bit.ly/3yGaPOX\n\n📝 En cuanto termines avísanos para poder continuar con tu proceso y si tienes alguna duda, seguimos al pendiente.",
    "👋🔎 Gracias por ponerte en contacto con YoFio. Estamos revisando tu solicitud y nos estaremos comunicando contigo en un lapso no mayor a 48 horas.\n\n🤔 Si tienes alguna duda, escríbela y con gusto te apoyaremos.",
    "⚡ Para poder acelerar tu proceso, recuerda tener a la mano lo siguiente:\n\n\n1. INE\n2. Comprobante de domicilio\n3. Notas, facturas o documentos a tu nombre\n4. 2 referencias\n5. descargar la app de yoigo tendero.\n⏰ Con estos documentos podrás obtener tú línea de crédito hoy mismo.",
    "🤔 ¿Sigues con nosotros?\n\n⏰ Debido a la inactividad en el chat, daremos por finalizada la conversación. Recuerda que estamos al pendiente de lo que necesites, puedes contactarnos por este mismo medio o por nuestra aplicación.\n\n 👇 Sería genial si nos ayudas calificando nuestro servicio en\n\nhttps://bit.ly/servicio_califica",
    "😕 Comprendemos.\n\n💬 Tu opinión es muy importante para nosotros. Nos podrías indicar el  motivo por el cual ya no requieres nuestra línea de crédito.",
    "💙 Agradecemos tu interés en formar parte de YoFio. Pero lamentablemente no podremos continuar con el proceso por temas en tu buró de crédito 😪\n\nEsperamos en un futuro poder trabajar juntos.🙂",
    "💙 Agradecemos tu interés en formar parte de YoFio. Esperamos en un futuro poder trabajar juntos.🙂",
    "💙 ¡Gracias por contactarnos! Que tengas excelente día.",
    "¡Qué tal! Mi nombre es Laura Diaz y daré seguimiento a tu solicitud, recibirás una llamada de este número 5570058703",
    "¡Qué tal! Mi nombre es Jazmín Soto y daré seguimiento a tu solicitud, recibirás una llamada de este número 5570058703",
  ];
  const [openList, setOpenList] = useState({
    firstItem: false,
    secondItem: false,
    thirdItem: false,
    fourthItem: false,
    fifthItem: false,
    sixthItem: false,
    seventhItem: false,
    eighthItem: false,
    ninthItem: false,
    tenthItem: false,
    eleventhItem: false,
    twelfthItem: false,
    thirteenthItem: false,
    fourteenthItem: false,
  });
  const [isOpenLostLeadModal, setIsOpenLostLeadModal] = useState(false);
  const [isOpenLightModal, setIsOpenLightModal] = useState(false);
  const sendWhatsappMessage = (
    phone_number,
    account_type_id,
    account_id,
    message
  ) => {
    let body = {
      phone_number: phone_number,
      account_type_id: account_type_id,
      account_id: account_id,
      message: message,
    };
    dispatch(postWhatsappMessage(body)).then(handleOpenMessageStatusSnackbar());
  };

  const lostLead = () => {
    let body = {
      reject_reason_id: selectedReasonId,
    };
    dispatch(postRejectRegister("SK", registrationData?.id, body)).then(
      handleOpenLostLeadSnackbar()
    );
  };

  const handleOpenLostLeadSnackbar = () => {
    setIsOpenLostLeadModal(false);
    setTimeout(() => {
      setOpenLostLeadSnackbar(true);
    }, 200);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleOpenAssignPasswordSnackbar = () => {
    setIsOpenAssignPasswordSnackbar(true);
    setTimeout(() => {
      setIsOpenAssignPasswordSnackbar(false);
      window.location.reload();
    }, 3000);
  };

  const handleOpenLostLeadModal = () => {
    setIsOpenLostLeadModal(true);
    dispatch(getRejectReasonsCatalog());
  };

  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  const assignPassword = () => {
    let body = {
      password: "",
    };
    dispatch(putAssignPassword(customerID, "SK", body)).then(
      handleOpenAssignPasswordSnackbar()
    );
  };

  const normalToLight = () => {
    dispatch(putLightCreditLine("SK", customerID)).then(
      handleOpenLightStatusSnackbar()
    );
  };

  const handleOpenMessageStatusSnackbar = () => {
    setSendMessage(false);
    setOpenMessageSnackbar(true);
  };
  const handleOpenLightStatusSnackbar = () => {
    setIsOpenLightModal(false);
    setIsOpenLightStatusSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessageSnackbar(false);
    setOpenLostLeadSnackbar(false);
    setIsOpenAssignPasswordSnackbar(false);
    setIsOpenLightStatusSnackbar(false);
  };
  const handleInputChange = (name, value) => {
    if (name === "input1" || name === "input2") {
      const isValidEmail = /^[a-zA-Z0-9._-]+@yofio\.co$/.test(value);
      setInputErrors({ ...inputErrors, [name]: !isValidEmail });
    }
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };
  const handleListItemClick = (event, index, message) => {
    setSelectedIndex(index);
    setSelectedMessage(message[index - 1]);
    setOpenList({
      firstItem: index === 1 && !openList.firstItem,
      secondItem: index === 2 && !openList.secondItem,
      thirdItem: index === 3 && !openList.thirdItem,
      fourthItem: index === 4 && !openList.fourthItem,
      fifthItem: index === 5 && !openList.fifthItem,
      sixthItem: index === 6 && !openList.sixthItem,
      seventhItem: index === 7 && !openList.seventhItem,
      eighthItem: index === 8 && !openList.eighthItem,
      ninthItem: index === 9 && !openList.ninthItem,
      tenthItem: index === 10 && !openList.tenthItem,
      eleventhItem: index === 11 && !openList.eleventhItem,
      twelfthItem: index === 12 && !openList.twelfthItem,
      thirteenthItem: index === 13 && !openList.thirteenthItem,
      fourteenthItem: index === 14 && !openList.fourteenthItem,
    });
  };

  const handleCheckboxChange = (name, checked) => {
    setCheckboxValues({
      ...checkboxValues,
      [name]: checked,
    });
  };

  useEffect(() => {
    dispatch(getClient(customerType, customerID));
  }, []);

  useEffect(() => {
    if (error?.POST_WHATSAPP_MESSAGE) {
      if (error?.POST_WHATSAPP_MESSAGE != null) {
        setIsError(true);
        setOpenMessageSnackbar(true);
      }
    }
  }, [error?.POST_POST_WHATSAPP_MESSAGE]);

  useEffect(() => {
    if (usersError?.POST_REJECT_REGISTER) {
      if (usersError?.POST_REJECT_REGISTER != null) {
        setIsError(true);
      }
    }
  }, [usersError?.POST_REJECT_REGISTER]);

  useEffect(() => {
    if (usersError?.PUT_ASSIGN_PASSWORD) {
      if (usersError?.PUT_ASSIGN_PASSWORD != null) {
        setIsError(true);
      }
    }
  }, [usersError?.PUT_ASSIGN_PASSWORD]);

  useEffect(() => {
    if (usersError?.PUT_LIGHT_CREDIT_LINE) {
      if (usersError?.PUT_LIGHT_CREDIT_LINE != null) {
        setIsError(true);
      }
    }
  }, [usersError?.PUT_LIGHT_CREDIT_LINE]);

  useEffect(() => {
    if (isFetchingCustomers) {
      setShowSpinner("block");
    } else {
      setShowSpinner("none");
    }
  }, [isFetchingCustomers]);

  let personalData;
  let vendorValidation;
  let identityValidation;
  let full_name;
  let phone_number;
  let creditValidation;
  let registrationData;
  let kycData;

  if (customer) {
    personalData = "personal_data" in customer ? customer.personal_data : {};
    vendorValidation =
      "yofio_validation" in customer ? customer.yofio_validation : {};
    identityValidation = customer.mati_data;
    creditValidation = customer.credit_validation;
    full_name = personalData.full_name;
    phone_number = personalData.phone_number || customer.owner_data.PhoneNumber;
    registrationData = customer.registration_data || {};
    kycData = customer.kyc_data || {};
  }
  const missingValidationInfo =
    !creditValidation || !identityValidation || !vendorValidation;
  const kycNoMatch =
    kycData && kycData.reason === "NO_MATCH" && customerType === "shopkeeper";
  const kycManualReview =
    kycData &&
    kycData.reason === "REVIEW_NEEDED" &&
    customerType === "shopkeeper";

  const subMenuDetail = () => {
    switch (subMenu) {
      case "inventory":
        return (
          <InventoryTab
            phone_number={phone_number}
            isOpenApprovalTable={isApprovalTableVisible}
            handleCheckboxChange={handleCheckboxChange}
            checkboxValues={checkboxValues}
            handleVeredictInputChange={handleInputChange}
            veredictInputValues={inputValues}
            shopkeeperInfo={registrationData}
            veredictInputErrors={inputErrors}
          />
        );
      case "documents":
        return (
          <DocumentTab
            phone_number={phone_number}
            avals={registrationData.avals || []}
            account_type_id="SK"
            account_id={registrationData.id || ""}
            isOpenApprovalTable={isApprovalTableVisible}
            handleCheckboxChange={handleCheckboxChange}
            checkboxValues={checkboxValues}
            handleVeredictInputChange={handleInputChange}
            veredictInputValues={inputValues}
            shopkeeperInfo={registrationData}
            veredictInputErrors={inputErrors}
          />
        );
      case "quote":
        return <TableDatingHistory phone_number={phone_number} />;
      case "general":
      default:
        return (
          <GeneralTab
            registrationData={registrationData}
            customerType={customerType}
            kycData={kycData}
            phone_number={phone_number}
            customer={customer}
            isOpenApprovalTable={isApprovalTableVisible}
            handleCheckboxChange={handleCheckboxChange}
            checkboxValues={checkboxValues}
            handleVeredictInputChange={handleInputChange}
            veredictInputValues={inputValues}
            shopkeeperInfo={registrationData}
            veredictInputErrors={inputErrors}
          />
        );
    }
  };

  const classes = useStyles();
  return (
    <PageContainer>
      <Link to="/registers" className={classes.controls}>
        <ArrowBackIcon />
        <Text> Registros / Detalle Cliente </Text>
      </Link>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
          height: "20%",
        }}
      >
        {(registrationData?.request || {}).status === "IN_PROGRESS" && (
          <AdminAction
            customStyle={{ marginRight: 8 }}
            splashBtnText="Perder Lead"
            isOpenModal={isOpenLostLeadModal}
            setOpenModal={setIsOpenLostLeadModal}
            onClickSplashBtn={handleOpenLostLeadModal}
            isOpenSnackbar={openLostLeadSnackbar}
            handleCloseSnackbar={handleCloseSnackbar}
            isLostLead
            msgType={isError ? "error" : "success"}
            onClickModalBtn={lostLead}
            snackBarMsg={
              isError
                ? error?.POST_REJECT_REGISTER?.response?.data
                    ?.readable_message || "Ocurrió un error"
                : "Lead perdido con éxito"
            }
            children={
              <FormControl style={{ width: 350 }}>
                <TextField
                  select
                  label={"Razón para perder lead"}
                  value={selectedReasonId}
                  onChange={(e) => setSelectedReasonId(e.target.value)}
                >
                  {rejectReasonsCatalog?.data?.map((reason) => (
                    <MenuItem key={reason.id} value={reason.id}>
                      {reason.description}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            }
          />
        )}
        {(registrationData?.request || {}).next_step === "PENDING_APPROVAL" && (
          <Button
            variant="outlined"
            className={classes.controlButtons}
            onClick={() => {
              setIsApprovalTableVisible(!isApprovalTableVisible);
            }}
          >
            Iniciar mesa de aprobación
          </Button>
        )}

        <Button
          variant="outlined"
          className={classes.controlButtons}
          onClick={() => {
            setSendMessage(true);
          }}
        >
          Envío de mensajes
        </Button>
        {(registrationData?.request || {}).next_step === "PASSWORD" && (
          <Button
            variant="outlined"
            className={classes.controlButtons}
            onClick={() => assignPassword()}
          >
            Crear contraseña temporal
          </Button>
        )}
        <Button
          variant="outlined"
          className={classes.controlButtons}
          onClick={() => {
            setIsOpenLightModal(true);
          }}
        >
          Cambiar a línea light
        </Button>
      </div>
      <Snackbar
        open={openMessageSnackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={isError ? "error" : "success"}
        >
          {isError ? "Ocurrió un error" : "Mensaje enviado correctamente"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isOpenAssignPasswordSnackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={isError ? "error" : "success"}
        >
          {isError ? "Ocurrió un error" : "Contraseña asignada correctamente"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isOpenLightStatusSnackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={isError ? "error" : "success"}
        >
          {isError
            ? usersError?.PUT_LIGHT_CREDIT_LINE?.response?.data
                ?.readable_message ?? "Ocurrió un error"
            : "Línea light asignada correctamente"}
        </Alert>
      </Snackbar>
      <Box className={classes.mainPanel}>
        <Box className={classes.validationContainer}>
          <SectionTitle>{full_name}</SectionTitle>

          <Box>
            <Text variant="h4" style={{ color: "#3a4760" }}>
              {`Teléfono celular registrado: ${phone_number}`}
              <CopyButton
                elementToCopy={phone_number}
                toolTipText={"Copiar número de teléfono"}
              />
            </Text>
          </Box>
          <Box>
            <Text variant="h4" style={{ color: "#3a4760" }}>
              {`ID Cuenta: ${registrationData?.id}`}
              <CopyButton
                elementToCopy={registrationData?.id}
                toolTipText={"Copiar id de la cuenta"}
              />
            </Text>
          </Box>
          <Box style={{ display: showSpinner }}>
            <CircularProgress />
          </Box>
          {missingValidationInfo && customerType === "customer" && (
            <MissingDataAlert text="El cliente no tiene información sobre validación." />
          )}
          {kycNoMatch && (
            <MissingDataAlert
              text={`El ${i18n.nacionatilityDocument} del cliente no coincide en la validación de Mati.`}
            />
          )}
          {kycManualReview && (
            <MissingDataAlert text="El registro de Mati requiere validación manual." />
          )}
          {customer && identityValidation && customerType === "customer" && (
            <CustomerValidationStatus
              identityValidation={identityValidation.status}
              creditValidation={creditValidation}
              vendorValidation={vendorValidation.status}
            />
          )}
          {customer && (
            <StepsInfo
              step={(registrationData.request || {}).next_step || customer.step}
              userType={customerType}
              kycError={kycNoMatch}
              status={(registrationData.request || {}).status || "IN_PROGRESS"}
            />
          )}
          <SubMenuTabBar subMenu={subMenu} setSubMenu={setSubMenu} />
          {subMenuDetail()}
        </Box>
      </Box>
      {isModalVisible && (
        <BlockAccountModal setOpen={setIsModalVisible} open={isModalVisible} />
      )}
      {sendMessage && (
        <CustomModal
          title={"Enviar mensaje"}
          open={sendMessage}
          setOpen={setSendMessage}
          closeButton
          isMessage
        >
          <List component="nav">
            {Object.keys(openList).map((key, index) => {
              return (
                <MessageListItem
                  openListItem={openList[key]}
                  onClick={(event) => {
                    handleListItemClick(event, index + 1, messages);
                  }}
                  selectedIndex={index + 1}
                  isSelected={openList[key]}
                  listHeader={headers[index]}
                  body={messages[index]}
                />
              );
            })}
          </List>
          <Box style={{ marginTop: 12, display: "flex", height: 30 }}>
            <Button
              variant="contained"
              color="secondary"
              children={"Enviar mensaje seleccionado"}
              isDisabled={
                Object.values(openList).some((value) => value === true)
                  ? false
                  : true
              }
              onClick={() => {
                sendWhatsappMessage(
                  personalData.phone_number || customer.owner_data.PhoneNumber,
                  "SK",
                  registrationData.id || "",
                  selectedMessage
                );
              }}
            />
          </Box>
        </CustomModal>
      )}
      {isOpenLightModal && (
        <CustomModal
          title={"Cambiar a línea light"}
          open={isOpenLightModal}
          setOpen={setIsOpenLightModal}
          closeButton
          isMessage
        >
          <Text>
            ¿Estás seguro que quiere hacer el cambio a una solicitud light?
          </Text>
          <Box style={{ marginTop: 12, display: "flex", height: 30 }}>
            <Button
              variant="contained"
              color="secondary"
              children={"Aceptar"}
              onClick={() => {
                normalToLight();
              }}
            />
            <Button
              variant="text"
              color="secondary"
              children={"Cancelar"}
              onClick={() => {
                setIsOpenLightModal(false);
              }}
            />
          </Box>
        </CustomModal>
      )}
    </PageContainer>
  );
};

export default CustomerDetails;
