import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableFooter,
  TablePagination,
  Link,
  Modal,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, AlertTitle } from "@material-ui/lab";

import Text from "../Text";
import Button from "../Button";
import Box from "../Layout/Box";
import TextInput from "../TextInput";

import { headCellMatiVerification } from "../../constants/constants";
import CopyButton from "../CopyButton";

function TableMatiVerification({
  classes,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  dataMati,
  allData,
  isModalAction,
  setIsModalAction,
  handleVerificationAction,
  newRFC,
  handleInputChange,
  validatedRFC,
  handleChanceRFC,
  messagesMATI,
  isSnackBar,
  isSnackBarError,
  isPutError,
  i18n,
}) {
  const informationTable = () => {
    return (
      <TableBody>
        {dataMati?.map((data, index) => (
          <TableRow key={index} style={{ maxHeight: 43 }}>
            <TableCell>
              <Text className={classes.textCellTable}>
                {data?.account_id.slice(-6)}
              </Text>
              <CopyButton
                elementToCopy={data?.account_id || ""}
                toolTipText={"Copiar ID de la cuenta"}
              />
            </TableCell>
            <TableCell>
              <Text className={classes.textCellTable}>{data?.store_name}</Text>
            </TableCell>
            <TableCell>
              <Text className={classes.textCellTable}>{data?.store_phone}</Text>
            </TableCell>
            <TableCell>
              <Text className={classes.textCellTable}>
                {data?.person_type !== "ACCOUNT"
                  ? data?.person_information?.full_name
                  : data?.shopkeeper_name}
              </Text>
            </TableCell>
            <TableCell>
              <Text className={classes.textCellTable}>
                {data?.person_information?.rfc
                  ? data.person_information.rfc
                  : "-"}
              </Text>
            </TableCell>
            <TableCell>
              <Text className={classes.textCellTable}>
                {data?.person_information?.curp
                  ? data.person_information.curp
                  : "-"}
              </Text>
            </TableCell>
            <TableCell>
              <Text className={classes.textCellTable}>
                {transformTypePerson(data?.person_type)}
              </Text>
            </TableCell>
            <TableCell>
              {transformStatus(
                data?.internal_document_review,
                data?.last_attempt_status
              )}
            </TableCell>
            <TableCell>
              {data?.verification_url ? (
                <Link
                  href={data?.verification_url}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.linkStyle}
                >
                  Link a MATI
                </Link>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              <Text className={classes.textCellTable}>
                {Object.keys(data?.meta_map_error_list).length !== 0
                  ? translateReasonMati(data?.meta_map_error_list)
                  : translateReason(data?.internal_document_review)}
              </Text>
            </TableCell>
            <TableCell>
              {data?.internal_document_review === "CREATED" && (
                <Box className={classes.buttonsContainer}>
                  <Button
                    style={{
                      backgroundColor: "#64B5F6",
                      color: "#FFF",
                      marginTop: 10,
                    }}
                    onClick={() =>
                      setIsModalAction({
                        isVisible: true,
                        titleModal: "Confirmar nuevo intento",
                        contentModal: `Vas a validar el nuevo intento de MATI de ${data?.shopkeeper_name}`,
                        typeStatus: "NEW_ATTEMPT",
                        personType: data?.person_type,
                        accountID: data?.account_id,
                        contractID: data?.contract_id,
                        action: "Aceptar",
                      })
                    }
                  >
                    Nuevo Intento
                  </Button>
                </Box>
              )}
              {data?.internal_document_review === "PENDING" && (
                <Box className={classes.buttonsContainer}>
                  <Box className={classes.buttonRowContainer}>
                    <Button
                      style={{
                        backgroundColor: "#E57373",
                        color: "#FFF",
                        marginRight: 7,
                      }}
                      onClick={() =>
                        setIsModalAction({
                          isVisible: true,
                          titleModal: "Motivo de rechazo",
                          contentModal: `Vas a rechazar a ${data?.shopkeeper_name} por la verificación de MATI`,
                          typeStatus: "NO_MATCH",
                          personType: data?.person_type,
                          accountID: data?.account_id,
                          contractID: data?.contract_id,
                          action: "Rechazar",
                        })
                      }
                    >
                      Rechazar
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#81C784",
                        color: "#FFF",
                        marginLeft: 7,
                      }}
                      onClick={() =>
                        setIsModalAction({
                          isVisible: true,
                          titleModal: "Confirma la validación",
                          contentModal: `Vas a validar la verificación de MATI de ${data?.shopkeeper_name}`,
                          typeStatus: "APPROVED",
                          personType: data?.person_type,
                          accountID: data?.account_id,
                          contractID: data?.contract_id,
                          action: "Validar",
                        })
                      }
                    >
                      Validar
                    </Button>
                  </Box>
                  <Button
                    style={{
                      backgroundColor: "#64B5F6",
                      color: "#FFF",
                      marginTop: 10,
                    }}
                    onClick={() =>
                      setIsModalAction({
                        isVisible: true,
                        titleModal: "Confirmar nuevo intento",
                        contentModal: `Vas a validar el nuevo intento de MATI de ${data?.shopkeeper_name}`,
                        typeStatus: "NEW_ATTEMPT",
                        personType: data?.person_type,
                        accountID: data?.account_id,
                        contractID: data?.contract_id,
                        action: "Aceptar",
                      })
                    }
                  >
                    Nuevo Intento
                  </Button>
                </Box>
              )}
              {data?.internal_document_review !== "APPROVED" &&
                data?.internal_document_review !== "CREATED" &&
                data?.internal_document_review !== "PENDING" && (
                  <Box className={classes.buttonsContainer}>
                    {data?.internal_document_review !== "NO_MATCH" ? (
                      <Button
                        style={{
                          backgroundColor: "#64B5F6",
                          color: "#FFF",
                          marginTop: 10,
                        }}
                        onClick={() =>
                          setIsModalAction({
                            isVisible: true,
                            titleModal: "Confirmar nuevo intento",
                            contentModal: `Vas a validar el nuevo intento de MATI de ${data?.shopkeeper_name}`,
                            typeStatus: "NEW_ATTEMPT",
                            personType: data?.person_type,
                            accountID: data?.account_id,
                            contractID: data?.contract_id,
                            action: "Aceptar",
                          })
                        }
                      >
                        Nuevo Intento
                      </Button>
                    ) : (
                      <>
                        <Button
                          style={{ backgroundColor: "#0253cc", color: "#FFF" }}
                          onClick={() =>
                            setIsModalAction({
                              isVisible: true,
                              titleModal: `Cambiar ${i18n.rfc}:`,
                              contentModal: `Vas a cambiar el ${i18n.rfc} de ${data?.shopkeeper_name}`,
                              typeStatus: "CHANGE_RFC",
                              personType: data?.person_type,
                              accountID: data?.account_id,
                              personPhoneNumber:
                                data?.person_information?.phone_number,
                              action: "Aceptar",
                            })
                          }
                        >
                          Cambiar {i18n.rfc}
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#64B5F6",
                            color: "#FFF",
                            marginTop: 10,
                          }}
                          onClick={() =>
                            setIsModalAction({
                              isVisible: true,
                              titleModal: "Confirmar nuevo intento",
                              contentModal: `Vas a validar el nuevo intento de MATI de ${data?.shopkeeper_name}`,
                              typeStatus: "NEW_ATTEMPT",
                              personType: data?.person_type,
                              accountID: data?.account_id,
                              contractID: data?.contract_id,
                              action: "Aceptar",
                            })
                          }
                        >
                          Nuevo Intento
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#81C784",
                            color: "#FFF",
                            marginTop: 10,
                          }}
                          onClick={() =>
                            setIsModalAction({
                              isVisible: true,
                              titleModal: "Confirma la validación",
                              contentModal: `Vas a validar la verificación de MATI de ${data?.shopkeeper_name}`,
                              typeStatus: "APPROVED",
                              personType: data?.person_type,
                              accountID: data?.account_id,
                              contractID: data?.contract_id,
                              action: "Validar",
                            })
                          }
                        >
                          Validar
                        </Button>
                      </>
                    )}
                  </Box>
                )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  function transformStatus(status, last_attempt_status) {
    switch (status) {
      case "CREATED":
        return (
          <Box className={classes.statusContainer}>
            <div
              className={classes.circleStyle}
              style={
                last_attempt_status === "EXPIRED"
                  ? { backgroundColor: "#f1773d" }
                  : { backgroundColor: "#fae912" }
              }
            />
            <Text className={classes.textCellTable}>
              {last_attempt_status === "EXPIRED" ? "Expirado" : "Firmando"}
            </Text>
          </Box>
        );
      case "PENDING":
        return (
          <Box className={classes.statusContainer}>
            <div
              className={classes.circleStyle}
              style={{ backgroundColor: "#0253cc" }}
            />
            <Text className={classes.textCellTable}>Por verificar</Text>
          </Box>
        );
      case "APPROVED":
        return (
          <Box className={classes.statusContainer}>
            <div
              className={classes.circleStyle}
              style={{ backgroundColor: "#34c721" }}
            />
            <Text className={classes.textCellTable}>Verificado</Text>
          </Box>
        );
      case "NO_MATCH":
      case "DUPLICATED_DOCUMENT":
        return (
          <Box className={classes.statusContainer}>
            <div
              className={classes.circleStyle}
              style={{ backgroundColor: "#ff0000" }}
            />
            <Text className={classes.textCellTable}>Rechazado</Text>
          </Box>
        );
      default:
        return <Text className={classes.textCellTable}>-</Text>;
    }
  }

  function transformTypePerson(typePerson) {
    switch (typePerson) {
      case "ACCOUNT":
        return "Titular";
      case "COLLATERAL":
        return "Aval";
      case "PARTNER":
        return "Cónyugue";
      default:
        return "-";
    }
  }

  function translateReasonMati(reasonMati) {
    if (Object.keys(reasonMati).length !== 0) {
      const rawMessage = reasonMati[0];
      if (rawMessage !== "") {
        const messageErrorMati = messagesMATI[rawMessage.raw_message];
        return messageErrorMati ? messageErrorMati : "-";
      }
    }
  }

  function translateReason(status) {
    switch (status) {
      case "NO_MATCH":
        return `No hay coincidencia entre el ${i18n.rfc} y el ${i18n.nacionatilityDocument} extraído del documento de identidad`;
      case "DUPLICATED_DOCUMENT":
        return "Ya existe un usuario con el mismo ${i18n.nacionatilityDocument} registrado";
      default:
        return "-";
    }
  }

  return (
    <Box>
      {Object.keys(dataMati).length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress size={55} style={{ marginRight: 10 }} />
          <Text>Cargando...</Text>
        </Box>
      )}
      <TableContainer>
        <Table aria-label="a dense table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headCellMatiVerification.map((headCell) => (
                <TableCell align="center" scope="col">
                  <Text className={classes.titleTable}>{headCell.label}</Text>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {informationTable()}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                colSpan={15}
                count={allData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Modal
        open={isModalAction.isVisible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
      >
        <Box className={classes.modalContainer}>
          <Box className={classes.headerContainer}>
            <Text className={classes.titleModal}>
              {isModalAction.titleModal}
            </Text>
            <CloseIcon
              style={{ color: "#0253cc" }}
              onClick={() =>
                setIsModalAction({
                  isVisible: false,
                  titleModal: "",
                  contentModal: "",
                  typeStatus: "",
                  personType: "",
                  accountID: "",
                  contractID: "",
                  personPhoneNumber: "",
                  action: "",
                })
              }
            />
          </Box>
          <Text className={classes.textModal}>
            {isModalAction.contentModal}
          </Text>
          {isModalAction.typeStatus === "CHANGE_RFC" && (
            <Box style={{ marginTop: 15, marginBottom: 15 }}>
              <TextInput
                label={i18n.rfc + " del usuario"}
                variant="standard"
                value={newRFC}
                onChange={handleInputChange}
                error={!validatedRFC ? true : false}
                helperText={
                  newRFC?.length > 0 && !validatedRFC
                    ? `${i18n.rfc} incorrecto`
                    : ""
                }
                inputProps={{ maxLength: 13 }}
              />
            </Box>
          )}
          <Box className={classes.buttonModalContainer}>
            <Button
              style={{
                backgroundColor: "#FFF",
                color: "#0253cc",
                fontWeight: "bold",
              }}
              onClick={() =>
                setIsModalAction({
                  isVisible: false,
                  titleModal: "",
                  contentModal: "",
                  typeStatus: "",
                  personType: "",
                  accountID: "",
                  contractID: "",
                  personPhoneNumber: "",
                  action: "",
                })
              }
            >
              Cancelar
            </Button>
            <Button
              style={{
                backgroundColor: "#FFF",
                color: "#0253cc",
                fontWeight: "bold",
              }}
              onClick={
                isModalAction.typeStatus !== "CHANGE_RFC"
                  ? () =>
                      handleVerificationAction(
                        isModalAction.typeStatus,
                        isModalAction.personType,
                        isModalAction.accountID,
                        isModalAction.contractID
                      )
                  : () =>
                      handleChanceRFC(
                        isModalAction.personType,
                        isModalAction.accountID,
                        isModalAction.personPhoneNumber
                      )
              }
            >
              {isModalAction.action}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar open={isSnackBar}>
        <Alert severity="success" variant="filled">
          <AlertTitle>{"ÉXITO"}</AlertTitle>
          {`${i18n.rfc} actualizado correctamente`}
        </Alert>
      </Snackbar>
      <Snackbar open={isSnackBarError}>
        <Alert severity="error" variant="filled">
          <AlertTitle>{"ERROR"}</AlertTitle>
          {isPutError ? "Algo salió mal" : `${i18n.rfc} incorrecto`}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default TableMatiVerification;
