import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../components/Text";
import Box from "../../../components/Layout/Box";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import MenuItemLanding from "../../../components/MenuItemLanding";
import TextInput from "../../../components/TextInput";
import useStyles from "./RegistrationInfo.style";
import moment from "moment";
import LabelValueTextList from "../../../components/LabelValueTextList/LabelValueTextList";
import {
  clearZipCode,
  getAvalStatus,
  getZipCode,
  postAvalData,
} from "../../../reducers/avals.action";
import {
  getPresaleAdvisors,
  putPresaleAdvisor,
} from "../../../reducers/presaleadvisors.action";
import { getClient } from "../../Registers/registersActions";
import generatedRFC from "../../../constants/generatedRFC";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import AdminAction from "../../../components/AdminAction/AdminAction";
import { patchInfoProperties } from "../../../reducers/modifications.action";
import { getComparsionAlerts } from "../../../reducers/users.action";
import { Alert } from "@material-ui/lab";
import StatusAlert from "../../../components/StatusAlert/StatusAlert";
import { getMatiVerification } from "../../../reducers/kyc.action";
import I18n from "../../../utils/i18n";
import { formatTaxId } from "../../../utils/formatTaxId";

const RegistrationInfo = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [partnerModal, setPartnerModal] = useState(false);
  const [avalModal, setAvalModal] = useState(false);
  const [isOpenPresaleAdvisorModal, setIsOpenPresaleAdvisorModal] =
    useState(false);
  const [isOpenPresaleAdvisorSnackbar, setIsOpenPresaleAdvisorSnackbar] =
    useState(false);
  const [isOpenEditOwnerInfomodal, setIsOpenEditOwnerInfoModal] =
    useState(false);
  const [isOpenEditOwnerInfoSnackbar, setIsOpenEditOwnerInfoSnackbar] =
    useState(false);
  const [isOpenEditPartnerInfoModal, setIsOpenEditPartnerInfoModal] =
    useState(false);
  const [isOpenEditPartnerInfoSnackbar, setIsOpenEditPartnerInfoSnackbar] =
    useState(false);
  const [isOpenEditCollateralInfoModal, setIsOpenEditCollateralInfoModal] =
    useState(false);
  const [
    isOpenEditCollateralInfoSnackbar,
    setIsOpenEditCollateralInfoSnackbar,
  ] = useState(false);
  const [isUserRfcAlert, setIsUserRfcAlert] = useState(false);
  const [isCollateralRfcAlert, setIsCollateralRfcAlert] = useState(false);
  const [isFacadePhotoAlert, setIsFacadePhotoAlert] = useState(false);
  const [presaleAdvisorValue, setPresaleAdvisorValue] = useState("");
  const [presaleAdvisorId, setPresaleAdvisorId] = useState("");
  const [presaleAdvisorEmail, setPresaleAdvisorEmail] = useState("");
  const [selectAddress, setSelectAddress] = useState(null);
  const [selectedOwnerField, setSelectedOwnerField] = useState("name");
  const [selectedPartnerField, setSelectedPartnerField] = useState("name");
  const [selectedCollateralField, setSelectedCollateralField] =
    useState("name");
  const [userInfoType, setUserInfoType] = useState("");
  const [collateralIds, setCollateralIds] = useState([]);
  const [nearbyShopsPhoneNumbers, setNearbyShopsPhoneNumbers] = useState([]);
  const [disable, setDisabled] = useState(true);
  const [isError, setIsError] = useState(false);
  const [presaleAdvisorsInfo, setPresaleAdvisorsInfo] = useState([]);
  const initialValues = {
    names: "",
    first_lastname: "",
    second_lastname: "",
    date: "",
    rfc: "",
    phoneNumber: "",
    zip_code: "",
    suburb: "",
    municipality: "",
    state: "",
    street: "",
    numberStreet: "",
    apartmentNumber: "",
  };

  const [values, setValues] = useState(initialValues);
  const [errorMessages, setErrorMessages] = useState({
    names: "",
    first_lastname: "",
    second_lastname: "",
    date: "",
    rfc: "",
    phoneNumber: "",
    zip_code: "",
    suburb: "",
    municipality: "",
    state: "",
    street: "",
    numberStreet: "",
    apartmentNumber: "",
  });
  const { registrationData, kycData } = props;
  const user_id = registrationData.id;
  const user_type = "SK";
  const [newOwnerValues, setNewOwnerValues] = useState({
    name: "",
    maternal_last_name: "",
    last_name: "",
    zip_code: "",
    neighborhood: "",
    city: "",
    state: "",
    street_name: "",
    external_number: "",
    appartment: "",
  });
  const [newCollateralValues, setNewCollateralValues] = useState({
    name: "",
    maternal_last_name: "",
    last_name: "",
    zip_code: "",
    neighborhood: "",
    city: "",
    state: "",
    street_name: "",
    external_number: "",
    appartment: "",
  });
  const [newPartnerValues, setNewPartnerValues] = useState({
    name: "",
    maternal_last_name: "",
    last_name: "",
    zip_code: "",
    neighborhood: "",
    city: "",
    state: "",
    street_name: "",
    external_number: "",
    appartment: "",
  });

  const { avalStatus, zipCode, avalData } = useSelector(
    (state) => state.avalsReducer
  );

  const { presaleAdvisorsData, loading, error } = useSelector(
    (state) => state.presaleAdvisorsReducer
  );

  const { loading: propertiesLoading, error: propertiesError } = useSelector(
    (state) => state.modificationsReducer
  );

  const { error: comparisonDataError, comparsionAlertsData } = useSelector(
    (state) => state.usersReducer
  );

  const { dataGetMatiVerification } = useSelector((state) => state.kycReducer);

  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  useEffect(() => {
    if (registrationData && registrationData.avals) {
      const avals = registrationData.avals;
      const approvedPartners = avals.find(
        (item) =>
          item.bureau_decision !== "REJECTED" && item.aval_type === "PARTNER"
      );
      const approvedCollaterals = avals.find(
        (item) =>
          item.bureau_decision !== "REJECTED" && item.aval_type === "COLLATERAL"
      );
      setNewCollateralValues({
        name: approvedCollaterals ? approvedCollaterals.name || "" : "",
        maternal_last_name: approvedCollaterals
          ? approvedCollaterals.maternal_lastname || ""
          : "",
        last_name: approvedCollaterals
          ? approvedCollaterals.lastname || ""
          : "",
        zip_code: approvedCollaterals
          ? approvedCollaterals.address.zip_code || ""
          : "",
        neighborhood: approvedCollaterals
          ? approvedCollaterals.address.neighborhood || ""
          : "",
        city: approvedCollaterals ? approvedCollaterals.address.city || "" : "",
        state: approvedCollaterals
          ? approvedCollaterals.address.state || ""
          : "",
        street_name: approvedCollaterals
          ? approvedCollaterals.address.street_name || ""
          : "",
        external_number: approvedCollaterals
          ? approvedCollaterals.address.street_number || ""
          : "",
        appartment: approvedCollaterals
          ? approvedCollaterals.address.apartment_number || ""
          : "",
      });

      setNewPartnerValues({
        name: approvedPartners ? approvedPartners.name || "" : "",
        maternal_last_name: approvedPartners
          ? approvedPartners.maternal_lastname || ""
          : "",
        last_name: approvedPartners ? approvedPartners.lastname || "" : "",
        zip_code: approvedPartners
          ? approvedPartners.address.zip_code || ""
          : "",
        neighborhood: approvedPartners
          ? approvedPartners.address.neighborhood || ""
          : "",
        city: approvedPartners ? approvedPartners.address.city || "" : "",
        state: approvedPartners ? approvedPartners.address.state || "" : "",
        street_name: approvedPartners
          ? approvedPartners.address.street_name || ""
          : "",
        external_number: approvedPartners
          ? approvedPartners.address.street_number || ""
          : "",
        appartment: approvedPartners
          ? approvedPartners.address.apartment_number || ""
          : "",
      });
    }

    if (registrationData) {
      const { contract_id } = registrationData;
      setNewOwnerValues({
        name: registrationData?.name || "",
        maternal_last_name: registrationData?.maternal_lastname || "",
        last_name: registrationData?.last_name || "",
        zip_code: registrationData?.address?.zip_code || "",
        neighborhood: registrationData?.address?.neighborhood || "",
        city: registrationData?.address?.city || "",
        state: registrationData?.address?.state || "",
        street_name: registrationData?.address?.street_name || "",
        external_number: registrationData?.address?.street_number || "",
        appartment: registrationData?.address?.apartment_number || "",
      });
      if (contract_id) {
        dispatch(getMatiVerification(contract_id));
      }
    }
  }, [registrationData]);

  useEffect(() => {
    if (error?.PUT_PRESALE_ADVISORS) {
      if (error?.PUT_PRESALE_ADVISORS != null) {
        setIsError(true);
      }
    }
  }, [error?.PUT_PRESALE_ADVISORS]);

  useEffect(() => {
    if (propertiesError?.PATCH_INFO_PROPERTIES) {
      if (propertiesError?.PATCH_INFO_PROPERTIES != null) {
        setIsError(true);
      }
    }
  }, [propertiesError?.PATCH_INFO_PROPERTIES]);

  useEffect(() => {
    if (comparisonDataError?.GET_COMPARISON_ALERTS) {
      if (comparisonDataError?.GET_COMPARISON_ALERTS != null) {
        setIsError(true);
      }
    }
  }, [comparisonDataError?.GET_COMPARISON_ALERTS]);

  useEffect(() => {
    dispatch(getAvalStatus(user_type, user_id));
  }, []);

  useEffect(() => {
    dispatch(getComparsionAlerts(user_id));
  }, []);

  useEffect(() => {
    if (comparsionAlertsData?.data?.user_rfc_alert === true) {
      setIsUserRfcAlert(true);
    }
    if (comparsionAlertsData?.data?.collateral_rfc_alert) {
      comparsionAlertsData.data.collateral_rfc_alert.forEach((alert) => {
        if (alert?.rfc_alert === true) {
          setIsCollateralRfcAlert(true);
          setCollateralIds((prevCollateralIds) => [
            ...prevCollateralIds,
            alert.collateral_id,
          ]);
        }
      });
    }
    if (
      comparsionAlertsData?.data?.nearby_shops_alert &&
      comparsionAlertsData?.data?.nearby_shops_alert?.length !== 0
    ) {
      setIsFacadePhotoAlert(true);

      const shopsList = comparsionAlertsData.data.nearby_shops_alert;

      const phoneNumbers = [];

      shopsList.forEach((item) => {
        if (Array.isArray(item)) {
          item.forEach((innerItem) => {
            if (
              innerItem &&
              typeof innerItem === "object" &&
              innerItem.phone_number
            ) {
              phoneNumbers.push(innerItem.phone_number);
            }
          });
        } else if (item && typeof item === "object" && item.phone_number) {
          phoneNumbers.push(item.phone_number);
        }
      });

      setNearbyShopsPhoneNumbers(phoneNumbers);
    }
  }, [comparsionAlertsData?.data]);

  useEffect(() => {
    dispatch(getPresaleAdvisors());
  }, []);

  useEffect(() => {
    if (Object.keys(presaleAdvisorsData).length !== 0) {
      if (presaleAdvisorsData?.data !== null) {
        setPresaleAdvisorsInfo(presaleAdvisorsData?.data);
      }
    }
  }, [presaleAdvisorsData]);

  useEffect(() => {
    if (values.zip_code.length === 5) {
      dispatch(getZipCode(values.zip_code));
    } else {
      dispatch(clearZipCode());
    }
  }, [values.zip_code]);

  useEffect(() => {
    if (values.suburb !== "" && userInfoType === undefined) {
      const newAddress = zipCode.data.data.filter(
        (addressObject) => addressObject.neighborhood === values.suburb
      );
      if (newAddress.length !== 0) {
        setValues((prevState) => ({
          ...prevState,
          state: newAddress[0].state,
          municipality: newAddress[0].city,
          suburb: newAddress[0].neighborhood,
        }));
      }
    }
  }, [values.suburb]);

  useEffect(() => {
    if (Object.keys(zipCode).length !== 0) {
      if (Object.keys(zipCode.data).length !== 0) {
        if (zipCode.data.data !== null && zipCode.data.data.length !== 0) {
          setValues((prevState) => ({
            ...prevState,
            state: zipCode.data.data[0].state,
            municipality: zipCode.data.data[0].city,
            suburb: zipCode.data.data[0].neighborhood,
          }));
        } else {
          setValues((prevState) => ({
            ...prevState,
            state: "",
            municipality: "",
            suburb: "",
          }));
        }
      } else {
        setValues((prevState) => ({
          ...prevState,
          state: "",
          municipality: "",
          suburb: "",
        }));
      }
    } else {
      setValues((prevState) => ({
        ...prevState,
        state: "",
        municipality: "",
        suburb: "",
      }));
    }
  }, [zipCode]);

  useEffect(() => {
    if (
      values.date !== "" &&
      values.names !== "" &&
      values.first_lastname !== "" &&
      values.second_lastname !== "" &&
      i18n.generate_rfc
    ) {
      const dateFormat = moment(values.date).format("YYMMDD");
      const rfcGenerated = generatedRFC(
        values.first_lastname,
        values.second_lastname,
        values.names,
        dateFormat
      );
      setValues((prevState) => ({
        ...prevState,
        rfc: rfcGenerated,
      }));
    }
  }, [values.date]);

  useEffect(() => {
    if (
      values.names !== "" &&
      values.first_lastname !== "" &&
      values.second_lastname !== "" &&
      values.date !== "" &&
      values.rfc !== "" &&
      values.phoneNumber !== ""
    ) {
      if (selectAddress) {
        setDisabled(false);
      } else if (
        values.zip_code !== "" &&
        values.suburb !== "" &&
        values.municipality !== "" &&
        values.state !== "" &&
        values.street !== "" &&
        values.numberStreet !== "" &&
        values.apartmentNumber !== ""
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [
    values.names,
    values.first_lastname,
    values.second_lastname,
    values.date,
    values.rfc,
    values.phoneNumber,
    values.zip_code,
    values.suburb,
    values.municipality,
    values.state,
    values.street,
    values.numberStreet,
    values.apartmentNumber,
    selectAddress,
  ]);

  useEffect(() => {
    if (
      newOwnerValues.zip_code.length === 5 &&
      userInfoType === "OWNER" &&
      i18n.generate_rfc
    ) {
      dispatch(getZipCode(newOwnerValues.zip_code));
    } else {
      dispatch(clearZipCode());
    }
  }, [newOwnerValues.zip_code]);

  useEffect(() => {
    if (
      newCollateralValues.zip_code.length === 5 &&
      userInfoType === "COLLATERAL"
    ) {
      dispatch(getZipCode(newCollateralValues.zip_code));
    } else {
      dispatch(clearZipCode());
    }
  }, [newCollateralValues.zip_code]);

  useEffect(() => {
    if (
      newPartnerValues.zip_code.length === 5 &&
      userInfoType === "PARTNER" &&
      i18n.generate_rfc
    ) {
      dispatch(getZipCode(newPartnerValues.zip_code));
    } else {
      dispatch(clearZipCode());
    }
  }, [newPartnerValues.zip_code]);

  useEffect(() => {
    if (
      newOwnerValues.neighborhood !== "" &&
      userInfoType === "OWNER" &&
      i18n.generate_rfc
    ) {
      const newAddress = zipCode.data.data.filter(
        (addressObject) =>
          addressObject.neighborhood === newOwnerValues.neighborhood
      );
      if (newAddress.length !== 0) {
        setNewOwnerValues((prevState) => ({
          ...prevState,
          state: newAddress[0].state,
          city: newAddress[0].city,
          neighborhood: newAddress[0].neighborhood,
        }));
      }
    }
  }, [newOwnerValues.neighborhood]);

  useEffect(() => {
    if (
      newCollateralValues.neighborhood !== "" &&
      userInfoType === "COLLATERAL" &&
      i18n.generate_rfc
    ) {
      const newAddress = zipCode.data.data.filter(
        (addressObject) =>
          addressObject.neighborhood === newCollateralValues.neighborhood
      );
      if (newAddress.length !== 0) {
        setNewCollateralValues((prevState) => ({
          ...prevState,
          state: newAddress[0].state,
          city: newAddress[0].city,
          neighborhood: newAddress[0].neighborhood,
        }));
      }
    }
  }, [newCollateralValues.neighborhood]);

  useEffect(() => {
    if (
      newPartnerValues.neighborhood !== "" &&
      userInfoType === "PARTNER" &&
      i18n.generate_rfc
    ) {
      const newAddress = zipCode.data.data.filter(
        (addressObject) =>
          addressObject.neighborhood === newPartnerValues.neighborhood
      );
      if (newAddress.length !== 0) {
        setNewPartnerValues((prevState) => ({
          ...prevState,
          state: newAddress[0].state,
          city: newAddress[0].city,
          neighborhood: newAddress[0].neighborhood,
        }));
      }
    }
  }, [newPartnerValues.neighborhood]);

  useEffect(() => {
    if (Object.keys(zipCode).length !== 0 && i18n.generate_rfc) {
      if (Object.keys(zipCode.data).length !== 0) {
        if (
          zipCode.data.data !== null &&
          zipCode.data.data.length !== 0 &&
          userInfoType === "OWNER"
        ) {
          setNewOwnerValues((prevState) => ({
            ...prevState,
            state: zipCode.data.data[0].state,
            city: zipCode.data.data[0].city,
            neighborhood: zipCode.data.data[0].neighborhood,
          }));
        } else {
          setNewOwnerValues((prevState) => ({
            ...prevState,
            state: "",
            city: "",
            neighborhood: "",
          }));
        }
      } else {
        setNewOwnerValues((prevState) => ({
          ...prevState,
          state: "",
          city: "",
          neighborhood: "",
        }));
      }
    } else {
      setNewOwnerValues((prevState) => ({
        ...prevState,
        state: "",
        city: "",
        neighborhood: "",
      }));
    }
  }, [zipCode]);

  useEffect(() => {
    if (Object.keys(zipCode).length !== 0 && i18n.generate_rfc) {
      if (Object.keys(zipCode.data).length !== 0) {
        if (
          zipCode.data.data !== null &&
          zipCode.data.data.length !== 0 &&
          userInfoType === "COLLATERAL"
        ) {
          setNewCollateralValues((prevState) => ({
            ...prevState,
            state: zipCode.data.data[0].state,
            city: zipCode.data.data[0].city,
            neighborhood: zipCode.data.data[0].neighborhood,
          }));
        } else {
          setNewCollateralValues((prevState) => ({
            ...prevState,
            state: "",
            city: "",
            neighborhood: "",
          }));
        }
      } else {
        setNewCollateralValues((prevState) => ({
          ...prevState,
          state: "",
          city: "",
          neighborhood: "",
        }));
      }
    } else {
      setNewCollateralValues((prevState) => ({
        ...prevState,
        state: "",
        city: "",
        neighborhood: "",
      }));
    }
  }, [zipCode]);

  useEffect(() => {
    if (Object.keys(zipCode).length !== 0 && i18n.generate_rfc) {
      if (Object.keys(zipCode.data).length !== 0) {
        if (
          zipCode.data.data !== null &&
          zipCode.data.data.length !== 0 &&
          userInfoType === "PARTNER"
        ) {
          setNewPartnerValues((prevState) => ({
            ...prevState,
            state: zipCode.data.data[0].state,
            city: zipCode.data.data[0].city,
            neighborhood: zipCode.data.data[0].neighborhood,
          }));
        } else {
          setNewPartnerValues((prevState) => ({
            ...prevState,
            state: "",
            city: "",
            neighborhood: "",
          }));
        }
      } else {
        setNewPartnerValues((prevState) => ({
          ...prevState,
          state: "",
          city: "",
          neighborhood: "",
        }));
      }
    } else {
      setNewPartnerValues((prevState) => ({
        ...prevState,
        state: "",
        city: "",
        neighborhood: "",
      }));
    }
  }, [zipCode]);

  const handleChangeOwnerField = (event) => {
    setSelectedOwnerField(event.target.value);
  };

  const handleChangeCollateralField = (event) => {
    setSelectedCollateralField(event.target.value);
  };

  const handleChangePartnerField = (event) => {
    setSelectedPartnerField(event.target.value);
  };

  const handleOwnerValueChange = (prop) => (event) => {
    let { value } = event.target;
    setNewOwnerValues({ ...newOwnerValues, [prop]: value });
    if (prop === "zip_code") {
      if (event.target.value.length <= 5)
        setNewOwnerValues({ ...newOwnerValues, [prop]: event.target.value });
    } else {
      setNewOwnerValues({ ...newOwnerValues, [prop]: event.target.value });
    }
  };

  const handleCollateralValueChange = (prop) => (event) => {
    const { value } = event.target;

    if (prop === "zip_code" && value.length > 5) {
      return;
    }

    setNewCollateralValues((prevValues) => ({
      ...prevValues,
      [prop]: value,
    }));
  };

  const handlePartnerValueChange = (prop) => (event) => {
    const { value } = event.target;

    if (prop === "zip_code" && value.length > 5) {
      return;
    }

    setNewPartnerValues((prevValues) => ({
      ...prevValues,
      [prop]: value,
    }));
  };

  const zipCodeData =
    Object.keys(zipCode).length == 0
      ? []
      : Object.keys(zipCode.data).length === 0
      ? []
      : zipCode.data.data !== null && zipCode.data.data.length !== 0
      ? zipCode.data.data
      : [];
  const kycMoment = moment(
    kycData?.start_time ? kycData?.start_time * 1000 : Date.now()
  );
  const timeElapsedMs = Date.now() - (kycData?.start_time * 1000 || Date.now());
  const handleChangeInput = (prop) => (event) => {
    let { value } = event.target;

    // Formatear el RFC
    if (prop === "rfc") {
      value = value.toUpperCase();
      value = formatTaxId(value);
    }

    if (prop === "zip_code" && value.length > 5) {
      return;
    }
    setValues((prevValues) => ({
      ...prevValues,
      [prop]: value,
    }));
  };

  const handleTrim = (prop) => (event) => {
    let { value } = event.target;
    value = (value || "").trim();
    setValues({ ...values, [prop]: value });
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const saveAvalInformation = () => {
    let hasError = false;
    const errors = {};

    handleErrorMessages(errors);

    if (hasError) {
      return;
    }

    _sendAvalInformation();
    setAvalModal(false);
  };

  const _sendAvalInformation = () => {
    // Send data to backend
    let body = {
      aval_type: "COLLATERAL",
      name: values.names,
      lastname: values.first_lastname,
      maternal_lastname: values.second_lastname,
      rfc: values.rfc,
      phone: `${i18n.lada}${values.phoneNumber}`,
      birth_date:
        values.date != "" ? moment(values.date).format("YYYY-MM-DD") : null,
      same_address: null,
      address: {
        zip_code: values.zip_code,
        street_name: values.street,
        apartment_number: values.apartmentNumber,
        street_number: values.numberStreet,
        neighborhood: values.suburb,
        state: values.state,
        city: values.municipality,
      },
    };
    dispatch(postAvalData(user_id, body));
  };

  const updatePresaleAdvisor = (presaleAdvisorId, presaleAdvisorEmail) => {
    let body = {
      presale_advisor_id: presaleAdvisorId,
      email: presaleAdvisorEmail,
    };
    dispatch(putPresaleAdvisor(user_type, user_id, body)).then(
      handleOpenPresaleAdvisorSnackbar()
    );
  };

  const updateInfoProperties = (userInfoType, requiredField) => {
    let payload = {
      partner_properties: {
        name:
          userInfoType === "PARTNER" && requiredField === "name"
            ? newPartnerValues.name
            : undefined,
        maternal_last_name:
          userInfoType === "PARTNER" && requiredField === "name"
            ? newPartnerValues.maternal_last_name
            : undefined,
        last_name:
          userInfoType === "PARTNER" && requiredField === "name"
            ? newPartnerValues.last_name
            : undefined,
        address: {
          street_name:
            userInfoType === "PARTNER" && requiredField === "address"
              ? newPartnerValues.street_name
              : undefined,
          external_number:
            userInfoType === "PARTNER" && requiredField === "address"
              ? newPartnerValues.external_number
              : undefined,
          appartment:
            userInfoType === "PARTNER" && requiredField === "address"
              ? newPartnerValues.appartment
              : undefined,
          neighborhood:
            userInfoType === "PARTNER" && requiredField === "address"
              ? newPartnerValues.neighborhood
              : undefined,
          city:
            userInfoType === "PARTNER" && requiredField === "address"
              ? newPartnerValues.city
              : undefined,
          state:
            userInfoType === "PARTNER" && requiredField === "address"
              ? newPartnerValues.state
              : undefined,
          zip_code:
            userInfoType === "PARTNER" && requiredField === "address"
              ? newPartnerValues.zip_code
              : undefined,
        },
      },
      collateral_properties: {
        name:
          userInfoType === "COLLATERAL" && requiredField === "name"
            ? newCollateralValues.name
            : undefined,
        maternal_last_name:
          userInfoType === "COLLATERAL" && requiredField === "name"
            ? newCollateralValues.maternal_last_name
            : undefined,
        last_name:
          userInfoType === "COLLATERAL" && requiredField === "name"
            ? newCollateralValues.last_name
            : undefined,
        address: {
          street_name:
            userInfoType === "COLLATERAL" && requiredField === "address"
              ? newCollateralValues.street_name
              : undefined,
          external_number:
            userInfoType === "COLLATERAL" && requiredField === "address"
              ? newCollateralValues.external_number
              : undefined,
          appartment:
            userInfoType === "COLLATERAL" && requiredField === "address"
              ? newCollateralValues.appartment
              : undefined,
          neighborhood:
            userInfoType === "COLLATERAL" && requiredField === "address"
              ? newCollateralValues.neighborhood
              : undefined,
          city:
            userInfoType === "COLLATERAL" && requiredField === "address"
              ? newCollateralValues.city
              : undefined,
          state:
            userInfoType === "COLLATERAL" && requiredField === "address"
              ? newCollateralValues.state
              : undefined,
          zip_code:
            userInfoType === "COLLATERAL" && requiredField === "address"
              ? newCollateralValues.zip_code
              : undefined,
        },
      },
    };
    if (userInfoType === "OWNER") {
      if (requiredField === "name") {
        payload = {
          user_properties: {
            name: newOwnerValues.name ? newOwnerValues.name : undefined,
            maternal_last_name: newOwnerValues.maternal_last_name
              ? newOwnerValues.maternal_last_name
              : undefined,
            last_name: newOwnerValues.last_name
              ? newOwnerValues.last_name
              : undefined,
          },
        };
      } else if (requiredField === "address") {
        payload = {
          user_properties: {
            address: {
              street_name: newOwnerValues.street_name
                ? newOwnerValues.street_name
                : undefined,
              external_number: newOwnerValues.external_number
                ? newOwnerValues.external_number
                : undefined,
              appartment: newOwnerValues.appartment
                ? newOwnerValues.appartment
                : undefined,
              neighborhood: newOwnerValues.neighborhood
                ? newOwnerValues.neighborhood
                : undefined,
              city: newOwnerValues.city ? newOwnerValues.city : undefined,
              state: newOwnerValues.state ? newOwnerValues.state : undefined,
              zip_code: newOwnerValues.zip_code
                ? newOwnerValues.zip_code
                : undefined,
            },
          },
        };
      }
      dispatch(patchInfoProperties(user_type, user_id, payload)).then(
        handleOpenEditOwnerInfoSnackbar()
      );
    } else if (userInfoType === "PARTNER") {
      if (requiredField === "name") {
        payload = {
          partner_properties: {
            name: newPartnerValues.name ? newPartnerValues.name : undefined,
            maternal_last_name: newPartnerValues.maternal_last_name
              ? newPartnerValues.maternal_last_name
              : undefined,
            last_name: newPartnerValues.last_name
              ? newPartnerValues.last_name
              : undefined,
          },
        };
      } else {
        payload = {
          partner_properties: {
            address: {
              street_name: newPartnerValues.street_name
                ? newPartnerValues.street_name
                : undefined,
              external_number: newPartnerValues.external_number
                ? newPartnerValues.external_number
                : undefined,
              appartment: newPartnerValues.appartment
                ? newPartnerValues.appartment
                : undefined,
              neighborhood: newPartnerValues.neighborhood
                ? newPartnerValues.neighborhood
                : undefined,
              city: newPartnerValues.city ? newPartnerValues.city : undefined,
              state: newPartnerValues.state
                ? newPartnerValues.state
                : undefined,
              zip_code: newPartnerValues.zip_code
                ? newPartnerValues.zip_code
                : undefined,
            },
          },
        };
      }
      dispatch(patchInfoProperties(user_type, user_id, payload)).then(
        handleOpenEditPartnerInfoSnackbar()
      );
    } else {
      if (requiredField === "address") {
        payload = {
          collateral_properties: {
            address: {
              street_name: newCollateralValues.street_name
                ? newCollateralValues.street_name
                : undefined,
              external_number: newCollateralValues.external_number
                ? newCollateralValues.external_number
                : undefined,
              appartment: newCollateralValues.appartment
                ? newCollateralValues.appartment
                : undefined,
              neighborhood: newCollateralValues.neighborhood
                ? newCollateralValues.neighborhood
                : undefined,
              city: newCollateralValues.city
                ? newCollateralValues.city
                : undefined,
              state: newCollateralValues.state
                ? newCollateralValues.state
                : undefined,
              zip_code: newCollateralValues.zip_code
                ? newCollateralValues.zip_code
                : undefined,
            },
          },
        };
      } else {
        payload = {
          collateral_properties: {
            name: newCollateralValues.name
              ? newCollateralValues.name
              : undefined,
            maternal_last_name: newCollateralValues.maternal_last_name
              ? newCollateralValues.maternal_last_name
              : undefined,
            last_name: newCollateralValues.last_name
              ? newCollateralValues.last_name
              : undefined,
          },
        };
      }
      dispatch(patchInfoProperties(user_type, user_id, payload)).then(
        handleOpenEditCollateralInfoSnackbar()
      );
    }
  };

  const handleOpenPresaleAdvisorSnackbar = () => {
    setIsOpenPresaleAdvisorSnackbar(true);
    setIsOpenPresaleAdvisorModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  const handleOpenEditOwnerInfoSnackbar = () => {
    setIsOpenEditOwnerInfoSnackbar(true);
    setIsOpenEditOwnerInfoModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  const handleOpenEditCollateralInfoSnackbar = () => {
    setIsOpenEditCollateralInfoSnackbar(true);
    setIsOpenEditCollateralInfoModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleOpenEditPartnerInfoSnackbar = () => {
    setIsOpenEditPartnerInfoSnackbar(true);
    setIsOpenEditPartnerInfoModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpenPresaleAdvisorSnackbar(false);
    setIsOpenEditOwnerInfoSnackbar(false);
    setIsOpenEditCollateralInfoSnackbar(false);
    setIsOpenEditPartnerInfoSnackbar(false);
  };

  const handleCloseUserRfcAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsUserRfcAlert(false);
  };
  const handleCloseCollateralRfcAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsCollateralRfcAlert(false);
  };
  const handleCloseFacadePhotoAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsFacadePhotoAlert(false);
  };

  useEffect(() => {
    if (Object.keys(avalData).length !== 0) {
      dispatch(getAvalStatus(user_type, user_id));
      dispatch(getClient("shopkeeper", user_id));
    }
  }, [avalData]);

  const savePartnerInformation = () => {
    let hasError = false;
    const errors = {};

    handleErrorMessages(errors);

    if (hasError) {
      return;
    }
    _sendPartnerInformation();
    setPartnerModal(false);
  };

  const _sendPartnerInformation = () => {
    // Send data to backend
    let body;
    const firstbody = {
      aval_type: "PARTNER",
      name: values.names,
      lastname: values.first_lastname,
      maternal_lastname: values.second_lastname,
      birth_date:
        values.date != "" ? moment(values.date).format("YYYY-MM-DD") : null,
      rfc: values.rfc,
      phone: `${i18n.lada}${values.phoneNumber}`,
      address: selectAddress,
      same_address: selectAddress,
    };

    if (selectAddress === true) {
      body = {
        ...firstbody,
        address: {
          zip_code: registrationData.address.zip_code,
          street_name: registrationData.address.street_name,
          apartment_number: registrationData.address.apartment_number,
          street_number: registrationData.address.street_number,
          neighborhood: registrationData.address.neighborhood,
          state: registrationData.address.state,
          city: registrationData.address.city,
        },
      };
    } else {
      body = {
        ...firstbody,
        address: {
          zip_code: values.zip_code,
          street_name: values.street,
          apartment_number: values.apartmentNumber,
          street_number: values.numberStreet,
          neighborhood: values.suburb,
          state: values.state,
          city: values.municipality,
        },
      };
    }
    dispatch(postAvalData(user_id, body));
  };

  const rowsOwner = [
    {
      label: "Nombre",
      value: registrationData.name,
    },
    {
      label: "Apellido Paterno",
      value: registrationData.last_name,
    },
    {
      label: i18n.rfc,
      value: registrationData?.shop?.rfc,
    },
    {
      label: "Correo",
      value: registrationData.email,
    },
    {
      label: "Estado Civil",
      value: getMaritalStatus(registrationData.marital_status),
    },
    {
      label: "Dirección de residencia",
      value: getAddress(registrationData.address),
    },
    {
      label: i18n.neighborhood,
      value: getCity(registrationData.address),
    },
    {
      label: "Teléfono fijo",
      value: registrationData.address?.landline_phone
        ? registrationData.address.landline_phone
        : "-",
    },
  ];

  const rowsAvals = (aval_type) => {
    if (Object.keys(registrationData).length !== 0) {
      if (registrationData?.avals.length !== 0) {
        let avals = registrationData.avals.filter(
          (item) => item.aval_type === aval_type
        );

        if (avals.length !== 0) {
          if (aval_type === "COLLATERAL") {
            const approvedAval = avals
              .filter((item) => item.bureau_decision === "APPROVED")
              .pop();

            if (approvedAval) {
              return getAvalData(approvedAval);
            } else {
              const avalWithoutDecision = avals
                .filter((item) => !item.bureau_decision)
                .pop();

              if (avalWithoutDecision) {
                return getAvalData(avalWithoutDecision);
              }
            }
          } else if (aval_type === "PARTNER") {
            const partnerAval = avals[0];
            return getAvalData(partnerAval);
          }
        }
      }
    }

    return [];
  };

  const getAvalData = (aval) => {
    return [
      {
        label: "Nombre",
        value: aval?.name || "",
      },
      {
        label: "Apellido Paterno",
        value: aval?.lastname || "",
      },
      {
        label: "Apellido Materno",
        value: aval?.maternal_lastname || "",
      },
      {
        label: i18n.rfc,
        value: aval?.rfc || "",
      },
      {
        label: "Número celular",
        value: aval?.phone || "",
      },
      {
        label: "Código Postal",
        value: aval?.address?.zip_code || "",
      },
      {
        label: i18n.neighborhood,
        value: aval?.address?.neighborhood || "",
      },
      {
        label: "Municipio",
        value: aval?.address?.city || "",
      },
      {
        label: "Estado",
        value: aval?.address?.state || "",
      },
      {
        label: "Calle",
        value: aval?.address?.street_name || "",
      },
      {
        label: "Número exterior",
        value: aval?.address?.street_number || "",
      },
      {
        label: "Número interior",
        value: aval?.address?.apartment_number || "",
      },
    ];
  };

  const rowsShop = [
    {
      label: "Nombre",
      value: registrationData?.shop?.name,
    },
    {
      label: i18n.rfc,
      value: registrationData?.shop?.rfc,
    },
    {
      label: "Giro comercial",
      value:
        registrationData?.shop?.shop_type === i18n.grocery_value
          ? i18n.grocery
          : registrationData?.shop?.shop_type,
    },
    {
      label: "Dirección",
      value: getAddress(registrationData?.shop?.address),
    },
    {
      label: i18n.neighborhood,
      value: getCity(registrationData?.shop?.address),
    },
    {
      label: "Años de antiguedad",
      value: registrationData?.shop?.business_age,
    },
  ];

  const rowsRequest = [
    {
      label: "Asesor de preventa",
      value: registrationData?.presale_advisor?.name,
    },
    {
      label: "Estado de la solicitud",
      value: getStatus(registrationData?.request?.status),
    },
    {
      label: "Ventas promedio semanales",
      value: `${i18n.currency}$ ${registrationData?.shop?.sales_average}`,
    },
    {
      label: "ID Kiban (Unykoo ID)",
      value: registrationData?.request?.unykoo_id,
    },
    {
      label: "Motivo(s) de rechazo",
      value:
        registrationData?.request?.bureau_reject_reasons?.join(", ") ||
        registrationData?.request?.reject_reason,
    },
  ];

  const requirementsInformation = (avalStatus) => {
    let detail = [];
    if (avalStatus?.partner?.needed) {
      detail.push({
        label: "Cónyuge",
        value: `Info: ${avalStatus?.partner?.info ? " ✅" : " ⚠️"} | Buró ${
          avalStatus?.partner?.bureau ? " ✅" : " ⚠️"
        }`,
      });
    }
    if (avalStatus?.collateral?.needed) {
      detail.push({
        label: "Aval",
        value: `Info ${avalStatus?.collateral?.info ? " ✅" : " ⚠️"} | Buró ${
          avalStatus?.collateral?.bureau ? " ✅" : " ⚠️"
        }`,
      });
    }
    if (avalStatus?.facade_photo?.needed) {
      detail.push({
        label: "Foto de fachada",
        value: `${
          avalStatus?.facade_photo?.info ? "Tomada ✅" : "Pendiente ⚠️"
        }`,
      });
    }
    if (avalStatus?.inventory?.needed) {
      detail.push({
        label: "Inventario",
        value: `${
          avalStatus?.inventory?.info ? "Realizado ✅" : "Pendiente ⚠️"
        }`,
      });
    }
    return detail;
  };

  const requirementsDetail = () => {
    if (avalStatus?.data) {
      return (
        <>
          <Box className={classes.AboutMeContainer}>
            <Text variant="h3">DETALLE DE REQUERIMIENTOS</Text>
            <Box style={{ display: "flex" }}>
              <LabelValueTextList
                rows={requirementsInformation(avalStatus.data)}
              />
            </Box>
          </Box>
        </>
      );
    } else {
      return <></>;
    }
  };

  const getSignatureStatus = (person) => {
    const { internal_document_review, verification_url, meta_map_error_list } =
      person;
    // According to the internal document review show a message of the status
    let status = "";
    switch (internal_document_review) {
      case "CREATED":
        status = "📝 Pendiente de firma";
        break;
      case "PENDING":
        status = "⏳ En revisión de Mati";
        break;
      case "APPROVED":
        status = "✅ Firmado";
        break;
      case "NO_MATCH":
        status = `⚠️ No coincide ${i18n.rfc}`;
        break;
      case "DUPLICATED_DOCUMENT":
        status = "❌ Usuario duplicado";
        break;
    }
    return (
      <div>
        <span>{status}</span>
        {meta_map_error_list.length > 0 && (
          <>
            <span> | </span>
            <Tooltip
              title={
                <React.Fragment>
                  {meta_map_error_list.map((error) => (
                    <li>{error.raw_message}</li>
                  ))}
                </React.Fragment>
              }
              placement="right"
            >
              <span>Errores en Mati ‼️</span>
            </Tooltip>
          </>
        )}
        {verification_url && (
          <>
            <span> | </span>
            <Button onClick={() => window.open(verification_url, "_blank")}>
              Enlace a Mati
            </Button>
          </>
        )}
      </div>
    );
  };

  const signaturesInformation = (signatureDetail) => {
    let detail = [];
    for (let i = 0; i < signatureDetail.length; i++) {
      let personType = "";
      switch (signatureDetail[i].person_type) {
        case "ACCOUNT":
          personType = "Titular";
          break;
        case "PARTNER":
          personType = "Obligado Solidario";
          break;
        case "COLLATERAL":
          personType = "Aval";
          break;
      }
      detail.push({
        label: personType,
        value: getSignatureStatus(signatureDetail[i]),
      });
    }
    return detail;
  };

  const signaturesDetail = () => {
    if (dataGetMatiVerification?.data) {
      return (
        <>
          <Box className={classes.AboutMeContainer}>
            <Text variant="h3">FIRMAS</Text>
            <Box style={{ display: "flex" }}>
              <LabelValueTextList
                rows={signaturesInformation(dataGetMatiVerification.data)}
              />
            </Box>
          </Box>
        </>
      );
    } else {
      return <></>;
    }
  };

  const handleChangePresaleAdvisor = (event) => {
    const selectedId = event.target.value;
    const selectedAdvisor = presaleAdvisorsInfo.find(
      (advisor) => advisor.id === selectedId
    );

    setPresaleAdvisorValue(selectedId);
    setPresaleAdvisorId(selectedAdvisor.id);
    setPresaleAdvisorEmail(selectedAdvisor.email);
  };

  const partnerInformation = () => {
    if (Object.keys(avalStatus).length !== 0) {
      if (
        avalStatus?.data?.partner?.needed === true &&
        avalStatus?.data?.partner?.info === true
      ) {
        return (
          <>
            <Box className={classes.AboutMeContainer}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text variant="h3">CONYÚGE</Text>
                <AdminAction
                  isEdit
                  isOpenModal={isOpenEditPartnerInfoModal}
                  setOpenModal={setIsOpenEditPartnerInfoModal}
                  onClickSplashBtn={() => {
                    setIsOpenEditPartnerInfoModal(true);
                    setUserInfoType("PARTNER");
                    dispatch(getZipCode(newPartnerValues.zip_code));
                  }}
                  modalTitle={"Editar información conyúge"}
                  handleCloseSnackbar={handleCloseSnackbar}
                  isOpenSnackbar={isOpenEditPartnerInfoSnackbar}
                  msgType={isError ? "error" : "success"}
                  snackBarMsg={
                    isError
                      ? propertiesError?.PATCH_INFO_PROPERTIES?.response?.data
                          ?.readable_message
                      : "Valor actualizado"
                  }
                  isLoading={propertiesLoading?.PATCH_INFO_PROPERTIES}
                  onClickModalBtn={() => {
                    updateInfoProperties(userInfoType, selectedPartnerField);
                    setIsOpenEditPartnerInfoModal(false);
                  }}
                  children={
                    <>
                      <FormControl style={{ width: 150 }}>
                        <InputLabel>Campo a editar:</InputLabel>
                        <Select
                          value={selectedPartnerField}
                          onChange={handleChangePartnerField}
                        >
                          <MenuItem value={"name"}>Nombre</MenuItem>
                          <MenuItem value={"address"}>Dirección</MenuItem>
                        </Select>
                      </FormControl>
                      {selectedPartnerField === "name" ? (
                        <>
                          <TextInput
                            className={classes.formElement}
                            label="Nombre(s)"
                            variant="standard"
                            value={newPartnerValues.name}
                            onChange={handlePartnerValueChange("name")}
                            inputProps={{ maxLength: 30, pattern: "w+(sw+)*" }}
                          />
                          <TextInput
                            className={classes.formElement}
                            label="Apellido Paterno"
                            variant="standard"
                            value={newPartnerValues.last_name}
                            onChange={handlePartnerValueChange("last_name")}
                            inputProps={{ maxLength: 25 }}
                          />
                          <TextInput
                            className={classes.formElement}
                            label="Apellido Materno"
                            variant="standard"
                            value={newPartnerValues.maternal_last_name}
                            onChange={handlePartnerValueChange(
                              "maternal_last_name"
                            )}
                            inputProps={{ maxLength: 25 }}
                          />
                        </>
                      ) : (
                        <>
                          <TextInput
                            className={classes.formElement}
                            label="Código Postal"
                            variant="standard"
                            value={newPartnerValues.zip_code}
                            type={"number"}
                            inputProps={{
                              maxLength: 5,
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            onChange={handlePartnerValueChange("zip_code")}
                            autoFocus
                          />
                          <TextInput
                            className={classes.formElement}
                            label={i18n.neighborhood}
                            variant="standard"
                            value={newPartnerValues.neighborhood}
                            onChange={handlePartnerValueChange("neighborhood")}
                            select
                          >
                            {Object.keys(zipCode).length !== 0 &&
                            Object.keys(zipCode.data).length !== 0 ? (
                              zipCode.data.data.map((zipCode) => (
                                <MenuItemLanding value={zipCode.neighborhood}>
                                  {zipCode.neighborhood}
                                </MenuItemLanding>
                              ))
                            ) : (
                              <MenuItemLanding value={""}>
                                Sin datos
                              </MenuItemLanding>
                            )}
                          </TextInput>
                          <TextInput
                            className={classes.formElement}
                            label="Municipio"
                            variant="standard"
                            value={newPartnerValues.city}
                            onChange={handlePartnerValueChange("city")}
                          />
                          <TextInput
                            className={classes.formElement}
                            label="Estado"
                            variant="standard"
                            value={newPartnerValues.state}
                            onChange={handlePartnerValueChange("state")}
                            disabled={i18n.country === "mx"}
                          />
                          <TextInput
                            className={classes.formElement}
                            label="Calle"
                            variant="standard"
                            value={newPartnerValues.street_name}
                            onChange={handlePartnerValueChange("street_name")}
                          />
                          <Box className={classes.numberAddress}>
                            <TextInput
                              className={classes.formElementAddress}
                              label="Número exterior"
                              variant="standard"
                              value={newPartnerValues.external_number}
                              onChange={handlePartnerValueChange(
                                "external_number"
                              )}
                            />
                            <TextInput
                              className={classes.formElementAddress3}
                              label="Número interior"
                              variant="standard"
                              value={newPartnerValues.appartment}
                              onChange={handlePartnerValueChange("appartment")}
                            />
                          </Box>
                        </>
                      )}
                    </>
                  }
                />
              </Box>
              <Box style={{ display: "flex" }}>
                <LabelValueTextList rows={rowsAvals("PARTNER")} />
              </Box>
            </Box>
          </>
        );
      } else if (
        avalStatus?.data?.partner?.needed === true &&
        avalStatus?.data?.partner?.info === false
      ) {
        return (
          <>
            <Box className={classes.AboutMeContainer}>
              <Text variant="h3">CÓNYUGE</Text>
              <Button
                onClick={() => setPartnerModal(true)}
                className={classes.button}
                variant="contained"
                color="secondary"
              >
                Agregar información del cónyuge
              </Button>
              <Modal
                open={partnerModal}
                onClose={() => {
                  setPartnerModal(false);
                  setValues(initialValues);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.modalAval}
              >
                <Box className={classes.modalContainer}>
                  <Text
                    className={classes.titleModal}
                    color="initial"
                    align="justify"
                    variant="h3"
                  >
                    AGREGAR CÓNYUGE
                  </Text>
                  <TextInput
                    className={classes.formElement}
                    label="Nombre(s)"
                    variant="standard"
                    value={values.names}
                    onChange={handleChangeInput("names")}
                    onBlur={handleTrim("names")}
                    inputProps={{ maxLength: 30, pattern: "w+(sw+)*" }}
                    helperText={errorMessages.names}
                    error={errorMessages.names !== "" ? true : false}
                  />
                  <TextInput
                    className={classes.formElement}
                    label="Apellido Paterno"
                    variant="standard"
                    value={values.first_lastname}
                    onChange={handleChangeInput("first_lastname")}
                    onBlur={handleTrim("first_lastname")}
                    inputProps={{ maxLength: 25 }}
                    helperText={errorMessages.first_lastname}
                    error={errorMessages.first_lastname !== "" ? true : false}
                  />
                  <TextInput
                    className={classes.formElement}
                    label="Apellido Materno"
                    variant="standard"
                    value={values.second_lastname}
                    onChange={handleChangeInput("second_lastname")}
                    onBlur={handleTrim("second_lastname")}
                    inputProps={{ maxLength: 25 }}
                    helperText={errorMessages.second_lastname}
                    error={errorMessages.second_lastname !== "" ? true : false}
                  />
                  <TextInput
                    id="date"
                    label="Fecha de nacimiento"
                    type="date"
                    value={values.date}
                    onChange={handleChangeInput("date")}
                    className={classes.formElement}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextInput
                    className={classes.formElement}
                    label={i18n.rfc}
                    variant="standard"
                    value={values.rfc}
                    onChange={handleChangeInput("rfc")}
                    helperText={errorMessages.rfc}
                    error={errorMessages.rfc !== ""}
                    inputProps={{ maxLength: 13 }}
                  />
                  <TextInput
                    className={classes.formElement}
                    label="Número celular"
                    variant="standard"
                    value={values.phoneNumber}
                    onChange={handleChangeInput("phoneNumber")}
                    helperText={errorMessages.phoneNumber}
                    error={errorMessages.phoneNumber !== ""}
                    inputProps={{ maxLength: 10 }}
                  />
                  <Text
                    className={classes.textAddress}
                    color="initial"
                    align="justify"
                    variant="h3"
                  >
                    ¿Tu cónyuge vive en el mismo domicilio?
                  </Text>
                  <Box className={classes.numberAddress}>
                    <Button
                      className={classes.selectButton}
                      onClick={() => setSelectAddress(true)}
                      variant={
                        selectAddress === true ? "contained" : "outlined"
                      }
                      color="secondary"
                    >
                      SI
                    </Button>
                    <Button
                      className={classes.selectButton2}
                      onClick={() => setSelectAddress(false)}
                      variant={
                        selectAddress === false ? "contained" : "outlined"
                      }
                      color="secondary"
                    >
                      NO
                    </Button>
                  </Box>
                  {selectAddress === false ? (
                    <>
                      <TextInput
                        className={classes.formElement}
                        label="Código Postal"
                        variant="standard"
                        value={values.zip_code}
                        type={"number"}
                        inputProps={{
                          maxLength: 5,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        onChange={handleChangeInput("zip_code")}
                        helperText={errorMessages.zip_code}
                        error={errorMessages.zip_code !== "" ? true : false}
                        autoFocus
                      />
                      {i18n.country === "mx" && (
                        <TextInput
                          className={classes.formElement}
                          label={i18n.neighborhood}
                          variant="standard"
                          value={values.suburb}
                          onChange={handleChangeInput("suburb")}
                          helperText={errorMessages.suburb}
                          error={errorMessages.suburb !== "" ? true : false}
                          select
                        >
                          {zipCodeData.length !== 0 ? (
                            zipCodeData.map((zipCode) => (
                              <MenuItemLanding value={zipCode.neighborhood}>
                                {zipCode.neighborhood}
                              </MenuItemLanding>
                            ))
                          ) : (
                            <MenuItemLanding value={""}>
                              Sin datos
                            </MenuItemLanding>
                          )}
                        </TextInput>
                      )}

                      {i18n.country === "do" && (
                        <TextInput
                          className={classes.formElement}
                          label={i18n.neighborhood}
                          variant="standard"
                          value={values.suburb}
                          onChange={handleChangeInput("suburb")}
                          helperText={errorMessages.suburb}
                          error={errorMessages.suburb !== "" ? true : false}
                        ></TextInput>
                      )}

                      <TextInput
                        className={classes.formElement}
                        label="Municipio"
                        variant="standard"
                        value={values.municipality}
                        onChange={handleChangeInput("municipality")}
                        helperText={errorMessages.municipality}
                        error={errorMessages.municipality !== "" ? true : false}
                      />
                      <TextInput
                        className={classes.formElement}
                        label="Estado"
                        variant="standard"
                        value={values.state}
                        onChange={handleChangeInput("state")}
                        helperText={errorMessages.state}
                        error={errorMessages.state !== "" ? true : false}
                        disabled={i18n.country === "mx"}
                      />
                      <TextInput
                        className={classes.formElement}
                        label="Calle"
                        variant="standard"
                        value={values.street}
                        onChange={handleChangeInput("street")}
                        helperText={errorMessages.street}
                        error={errorMessages.street !== "" ? true : false}
                      />
                      <Box className={classes.numberAddress}>
                        <TextInput
                          className={classes.formElementAddress}
                          label="Número exterior"
                          variant="standard"
                          value={values.numberStreet}
                          onChange={handleChangeInput("numberStreet")}
                          helperText={errorMessages.numberStreet}
                          error={
                            errorMessages.numberStreet !== "" ? true : false
                          }
                        />
                        <TextInput
                          className={classes.formElementAddress2}
                          label="Número interior"
                          variant="standard"
                          value={values.apartmentNumber}
                          onChange={handleChangeInput("apartmentNumber")}
                          helperText={errorMessages.apartmentNumber}
                          error={
                            errorMessages.apartmentNumber !== "" ? true : false
                          }
                        />
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                  <Button
                    className={classes.buttonSave}
                    onClick={() => savePartnerInformation()}
                    variant="contained"
                    color="secondary"
                    isDisabled={disable}
                  >
                    Guardar
                  </Button>
                </Box>
              </Modal>
            </Box>
          </>
        );
      } else if (avalStatus?.data?.partner?.needed === false) {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  const avalInformation = () => {
    if (Object.keys(avalStatus).length !== 0) {
      if (
        avalStatus?.data?.collateral?.needed === true &&
        avalStatus?.data?.collateral?.info === true &&
        avalStatus?.data?.collateral?.status !== "REJECTED"
      ) {
        return (
          <>
            <Box className={classes.AboutMeContainer}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text variant="h3">AVAL</Text>
                <AdminAction
                  isEdit
                  isOpenModal={isOpenEditCollateralInfoModal}
                  setOpenModal={setIsOpenEditCollateralInfoModal}
                  onClickSplashBtn={() => {
                    setIsOpenEditCollateralInfoModal(true);
                    setUserInfoType("COLLATERAL");
                    dispatch(getZipCode(newCollateralValues.zip_code));
                  }}
                  modalTitle={"Editar información aval"}
                  handleCloseSnackbar={handleCloseSnackbar}
                  isOpenSnackbar={isOpenEditCollateralInfoSnackbar}
                  msgType={isError ? "error" : "success"}
                  snackBarMsg={
                    isError
                      ? propertiesError?.PATCH_INFO_PROPERTIES?.response?.data
                          ?.readable_message
                      : "Valor actualizado"
                  }
                  isLoading={propertiesLoading?.PATCH_INFO_PROPERTIES}
                  onClickModalBtn={() => {
                    updateInfoProperties(userInfoType, selectedCollateralField);
                    setIsOpenEditCollateralInfoModal(false);
                  }}
                  children={
                    <>
                      <FormControl style={{ width: 150 }}>
                        <InputLabel>Campo a editar:</InputLabel>
                        <Select
                          value={selectedCollateralField}
                          onChange={handleChangeCollateralField}
                        >
                          <MenuItem value={"name"}>Nombre</MenuItem>
                          <MenuItem value={"address"}>Dirección</MenuItem>
                        </Select>
                      </FormControl>
                      {selectedCollateralField === "name" ? (
                        <>
                          <TextInput
                            className={classes.formElement}
                            label="Nombre(s)"
                            variant="standard"
                            value={newCollateralValues.name}
                            onChange={handleCollateralValueChange("name")}
                            inputProps={{ maxLength: 30, pattern: "w+(sw+)*" }}
                          />
                          <TextInput
                            className={classes.formElement}
                            label="Apellido Paterno"
                            variant="standard"
                            value={newCollateralValues.last_name}
                            onChange={handleCollateralValueChange("last_name")}
                            inputProps={{ maxLength: 25 }}
                          />
                          <TextInput
                            className={classes.formElement}
                            label="Apellido Materno"
                            variant="standard"
                            value={newCollateralValues.maternal_last_name}
                            onChange={handleCollateralValueChange(
                              "maternal_last_name"
                            )}
                            inputProps={{ maxLength: 25 }}
                          />
                        </>
                      ) : (
                        <>
                          <TextInput
                            className={classes.formElement}
                            label="Código Postal"
                            variant="standard"
                            value={newCollateralValues.zip_code}
                            type={"number"}
                            inputProps={{
                              maxLength: 5,
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            onChange={handleCollateralValueChange("zip_code")}
                            autoFocus
                          />
                          <TextInput
                            className={classes.formElement}
                            label={i18n.neighborhood}
                            variant="standard"
                            value={newCollateralValues.neighborhood}
                            onChange={handleCollateralValueChange(
                              "neighborhood"
                            )}
                            select
                          >
                            {Object.keys(zipCode).length !== 0 &&
                            Object.keys(zipCode.data).length !== 0 ? (
                              zipCode.data.data.map((zipCode) => (
                                <MenuItemLanding value={zipCode.neighborhood}>
                                  {zipCode.neighborhood}
                                </MenuItemLanding>
                              ))
                            ) : (
                              <MenuItemLanding value={""}>
                                Sin datos
                              </MenuItemLanding>
                            )}
                          </TextInput>
                          <TextInput
                            className={classes.formElement}
                            label="Municipio"
                            variant="standard"
                            value={newCollateralValues.city}
                            onChange={handleCollateralValueChange("city")}
                          />
                          <TextInput
                            className={classes.formElement}
                            label="Estado"
                            variant="standard"
                            value={newCollateralValues.state}
                            onChange={handleCollateralValueChange("state")}
                            disabled={i18n.country === "mx"}
                          />
                          <TextInput
                            className={classes.formElement}
                            label="Calle"
                            variant="standard"
                            value={newCollateralValues.street_name}
                            onChange={handleCollateralValueChange(
                              "street_name"
                            )}
                          />
                          <Box className={classes.numberAddress}>
                            <TextInput
                              className={classes.formElementAddress}
                              label="Número exterior"
                              variant="standard"
                              value={newCollateralValues.external_number}
                              onChange={handleCollateralValueChange(
                                "external_number"
                              )}
                            />
                            <TextInput
                              className={classes.formElementAddress3}
                              label="Número interior"
                              variant="standard"
                              value={newCollateralValues.appartment}
                              onChange={handleCollateralValueChange(
                                "appartment"
                              )}
                            />
                          </Box>
                        </>
                      )}
                    </>
                  }
                />
              </Box>
              <Box style={{ display: "flex" }}>
                <LabelValueTextList rows={rowsAvals("COLLATERAL")} />
              </Box>
            </Box>
          </>
        );
      } else if (
        (avalStatus?.data?.collateral?.needed === true &&
          avalStatus?.data?.collateral?.info === false) ||
        (avalStatus?.data?.collateral?.needed === true &&
          avalStatus?.data?.collateral?.info === true &&
          avalStatus?.data?.collateral?.status === "REJECTED")
      ) {
        return (
          <>
            <Box className={classes.AboutMeContainer}>
              <Text variant="h3">AVAL</Text>
              <Button
                className={classes.button}
                onClick={() => setAvalModal(true)}
                variant="contained"
                color="secondary"
              >
                Agregar información de Aval
              </Button>
              <Modal
                open={avalModal}
                onClose={() => {
                  setAvalModal(false);
                  setValues(initialValues);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.modalAval}
              >
                <Box className={classes.modalContainer}>
                  <Text
                    className={classes.titleModal}
                    color="initial"
                    align="justify"
                    variant="h3"
                  >
                    AGREGAR AVAL
                  </Text>
                  <TextInput
                    className={classes.formElement}
                    label="Nombre(s)"
                    variant="standard"
                    value={values.names}
                    onChange={handleChangeInput("names")}
                    onBlur={handleTrim("names")}
                    inputProps={{ maxLength: 30, pattern: "w+(sw+)*" }}
                    helperText={errorMessages.names}
                    error={errorMessages.names !== "" ? true : false}
                  />
                  <TextInput
                    className={classes.formElement}
                    label="Apellido Paterno"
                    variant="standard"
                    value={values.first_lastname}
                    onChange={handleChangeInput("first_lastname")}
                    onBlur={handleTrim("first_lastname")}
                    inputProps={{ maxLength: 25 }}
                    helperText={errorMessages.first_lastname}
                    error={errorMessages.first_lastname !== "" ? true : false}
                  />
                  <TextInput
                    className={classes.formElement}
                    label="Apellido Materno"
                    variant="standard"
                    value={values.second_lastname}
                    onChange={handleChangeInput("second_lastname")}
                    onBlur={handleTrim("second_lastname")}
                    inputProps={{ maxLength: 25 }}
                    helperText={errorMessages.second_lastname}
                    error={errorMessages.second_lastname !== "" ? true : false}
                  />
                  <TextInput
                    id="date"
                    label="Fecha de nacimiento"
                    type="date"
                    value={values.date}
                    onChange={handleChangeInput("date")}
                    className={classes.formElement}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextInput
                    className={classes.formElement}
                    label={i18n.rfc}
                    variant="standard"
                    value={values.rfc}
                    onChange={handleChangeInput("rfc")}
                    helperText={errorMessages.rfc}
                    error={errorMessages.rfc !== ""}
                    inputProps={{ maxLength: 13 }}
                  />
                  <TextInput
                    className={classes.formElement}
                    label="Número celular"
                    variant="standard"
                    value={values.phoneNumber}
                    onChange={handleChangeInput("phoneNumber")}
                    helperText={errorMessages.phoneNumber}
                    error={errorMessages.phoneNumber !== ""}
                    inputProps={{ maxLength: 10 }}
                  />
                  <TextInput
                    className={classes.formElement}
                    label="Código Postal"
                    variant="standard"
                    value={values.zip_code}
                    type={"number"}
                    inputProps={{
                      maxLength: 12,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    onChange={handleChangeInput("zip_code")}
                    helperText={errorMessages.zip_code}
                    error={errorMessages.zip_code !== "" ? true : false}
                    autoFocus
                  />
                  {i18n.country === "mx" && (
                    <TextInput
                      className={classes.formElement}
                      label={i18n.neighborhood}
                      variant="standard"
                      value={values.suburb}
                      onChange={handleChangeInput("suburb")}
                      helperText={errorMessages.suburb}
                      error={errorMessages.suburb !== "" ? true : false}
                      select
                    >
                      {zipCodeData.length !== 0 ? (
                        zipCodeData.map((zipCode) => (
                          <MenuItemLanding value={zipCode.neighborhood}>
                            {zipCode.neighborhood}
                          </MenuItemLanding>
                        ))
                      ) : (
                        <MenuItemLanding value={""}>Sin datos</MenuItemLanding>
                      )}
                    </TextInput>
                  )}

                  {i18n.country === "do" && (
                    <TextInput
                      className={classes.formElement}
                      label={i18n.neighborhood}
                      variant="standard"
                      value={values.suburb}
                      onChange={handleChangeInput("suburb")}
                      helperText={errorMessages.suburb}
                      error={errorMessages.suburb !== "" ? true : false}
                    ></TextInput>
                  )}
                  <TextInput
                    className={classes.formElement}
                    label="Municipio"
                    variant="standard"
                    value={values.municipality}
                    onChange={handleChangeInput("municipality")}
                    helperText={errorMessages.municipality}
                    error={errorMessages.municipality !== "" ? true : false}
                  />
                  <TextInput
                    className={classes.formElement}
                    label="Estado"
                    variant="standard"
                    value={values.state}
                    onChange={handleChangeInput("state")}
                    helperText={errorMessages.state}
                    error={errorMessages.state !== "" ? true : false}
                    disabled={i18n.country === "mx"}
                  />
                  <TextInput
                    className={classes.formElement}
                    label="Calle"
                    variant="standard"
                    value={values.street}
                    onChange={handleChangeInput("street")}
                    helperText={errorMessages.street}
                    error={errorMessages.street !== "" ? true : false}
                  />
                  <Box className={classes.numberAddress}>
                    <TextInput
                      className={classes.formElementAddress}
                      label="Número exterior"
                      variant="standard"
                      value={values.numberStreet}
                      onChange={handleChangeInput("numberStreet")}
                      helperText={errorMessages.numberStreet}
                      error={errorMessages.numberStreet !== "" ? true : false}
                    />
                    <TextInput
                      className={classes.formElementAddress2}
                      label="Número interior"
                      variant="standard"
                      value={values.apartmentNumber}
                      onChange={handleChangeInput("apartmentNumber")}
                      helperText={errorMessages.apartmentNumber}
                      error={
                        errorMessages.apartmentNumber !== "" ? true : false
                      }
                    />
                  </Box>
                  <Button
                    className={classes.buttonSave}
                    onClick={() => saveAvalInformation()}
                    variant="contained"
                    color="secondary"
                    isDisabled={
                      values.names !== "" &&
                      values.first_lastname !== "" &&
                      values.second_lastname !== "" &&
                      values.date !== "" &&
                      values.rfc !== "" &&
                      values.phoneNumber !== "" &&
                      values.zip_code.length === 5 &&
                      values.suburb !== "" &&
                      values.municipality !== "" &&
                      values.street !== "" &&
                      values.numberStreet !== "" &&
                      values.apartmentNumber !== ""
                        ? false
                        : true
                    }
                  >
                    Guardar
                  </Button>
                </Box>
              </Modal>
            </Box>
          </>
        );
      } else if (avalStatus?.data?.collateral?.needed === false) {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      {!isError ? (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            zIndex: "9999",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <StatusAlert
            open={isUserRfcAlert}
            message={`En caso de que no, modificar ${i18n.rfc}  y reconsultar.`}
            severity="info"
            alertHeader={`El ${i18n.rfc} registrado del titular no coincide con el buró de crédito, revisar si se consultó a la persona correcta!`}
            isUserRfcAlert
            setOpen={setIsUserRfcAlert}
          />
          <StatusAlert
            open={isFacadePhotoAlert}
            message={nearbyShopsPhoneNumbers.join(", ")}
            severity="info"
            alertHeader={
              "Las coordenadas de GPS de la fachada están muy cerca a las coordenadas de fachada de las siguientes tiendas, revisar que no sean las mismas!"
            }
            isFacadePhotoAlert
            setOpen={setIsFacadePhotoAlert}
          />
          <StatusAlert
            open={isCollateralRfcAlert}
            message={`En caso de que no, modificar ${i18n.rfc}  y reconsultar.`}
            severity="info"
            alertHeader={`El ${i18n.rfc} registrado del aval no coincide con el buró de crédito, revisar si se consultó a la persona correcta!`}
            isCollateralRfcAlert
            setOpen={setIsCollateralRfcAlert}
          />
        </div>
      ) : (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isFacadePhotoAlert}
          className={classes.snackbar3}
        >
          <Alert severity="error">
            {
              comparisonDataError?.GET_COMPARISON_ALERTS?.response?.data
                ?.readable_message
            }
          </Alert>
        </Snackbar>
      )}
      {requirementsDetail()}
      {signaturesDetail()}
      <Box className={classes.AboutMeContainer}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text variant="h3">PROPIETARIO</Text>
          <AdminAction
            isEdit
            isOpenModal={isOpenEditOwnerInfomodal}
            setOpenModal={setIsOpenEditOwnerInfoModal}
            onClickSplashBtn={() => {
              setIsOpenEditOwnerInfoModal(true);
              setUserInfoType("OWNER");
              dispatch(getZipCode(registrationData.address.zip_code));
            }}
            modalTitle={"Editar información propietario"}
            handleCloseSnackbar={handleCloseSnackbar}
            isOpenSnackbar={isOpenEditOwnerInfoSnackbar}
            msgType={isError ? "error" : "success"}
            snackBarMsg={
              isError
                ? propertiesError?.PATCH_INFO_PROPERTIES?.response?.data
                    ?.readable_message
                : "Valores actualizados"
            }
            isLoading={propertiesLoading?.PATCH_INFO_PROPERTIES}
            onClickModalBtn={() => {
              updateInfoProperties(userInfoType, selectedOwnerField);
              setIsOpenEditOwnerInfoModal(false);
            }}
            children={
              <>
                <FormControl style={{ width: 150 }}>
                  <InputLabel>Campo a editar:</InputLabel>
                  <Select
                    value={selectedOwnerField}
                    onChange={handleChangeOwnerField}
                  >
                    <MenuItem value={"name"}>Nombre</MenuItem>
                    <MenuItem value={"address"}>Dirección</MenuItem>
                  </Select>
                </FormControl>
                {selectedOwnerField === "name" ? (
                  <>
                    <TextInput
                      className={classes.formElement}
                      label="Nombre(s)"
                      variant="standard"
                      value={newOwnerValues.name}
                      onChange={handleOwnerValueChange("name")}
                      inputProps={{ maxLength: 30, pattern: "w+(sw+)*" }}
                    />
                    <TextInput
                      className={classes.formElement}
                      label="Apellido Paterno"
                      variant="standard"
                      value={newOwnerValues.last_name}
                      onChange={handleOwnerValueChange("last_name")}
                      inputProps={{ maxLength: 25 }}
                    />
                    <TextInput
                      className={classes.formElement}
                      label="Apellido Materno"
                      variant="standard"
                      value={newOwnerValues.maternal_last_name}
                      onChange={handleOwnerValueChange("maternal_last_name")}
                      inputProps={{ maxLength: 25 }}
                    />
                  </>
                ) : (
                  <>
                    <TextInput
                      className={classes.formElement}
                      label="Código Postal"
                      variant="standard"
                      value={newOwnerValues.zip_code}
                      type={"number"}
                      inputProps={{
                        maxLength: 5,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      onChange={handleOwnerValueChange("zip_code")}
                      autoFocus
                    />
                    <TextInput
                      className={classes.formElement}
                      label={i18n.neighborhood}
                      variant="standard"
                      value={newOwnerValues.neighborhood}
                      onChange={handleOwnerValueChange("neighborhood")}
                      select
                    >
                      {Object.keys(zipCode).length !== 0 &&
                      Object.keys(zipCode.data).length !== 0 ? (
                        zipCode.data.data.map((zipCode) => (
                          <MenuItemLanding value={zipCode.neighborhood}>
                            {zipCode.neighborhood}
                          </MenuItemLanding>
                        ))
                      ) : (
                        <MenuItemLanding value={""}>Sin datos</MenuItemLanding>
                      )}
                    </TextInput>
                    <TextInput
                      className={classes.formElement}
                      label="Municipio"
                      variant="standard"
                      value={newOwnerValues.city}
                      onChange={handleOwnerValueChange("city")}
                    />
                    <TextInput
                      className={classes.formElement}
                      label="Estado"
                      variant="standard"
                      value={newOwnerValues.state}
                      onChange={handleOwnerValueChange("state")}
                      disabled={i18n.country === "mx"}
                    />
                    <TextInput
                      className={classes.formElement}
                      label="Calle"
                      variant="standard"
                      value={newOwnerValues.street_name}
                      onChange={handleOwnerValueChange("street_name")}
                    />
                    <Box className={classes.numberAddress}>
                      <TextInput
                        className={classes.formElementAddress}
                        label="Número exterior"
                        variant="standard"
                        value={newOwnerValues.external_number}
                        onChange={handleOwnerValueChange("external_number")}
                      />
                      <TextInput
                        className={classes.formElementAddress3}
                        label="Número interior"
                        variant="standard"
                        value={newOwnerValues.appartment}
                        onChange={handleOwnerValueChange("appartment")}
                      />
                    </Box>
                  </>
                )}
              </>
            }
          />
        </Box>
        <Box style={{ display: "flex" }}>
          <LabelValueTextList rows={rowsOwner} />
        </Box>
      </Box>
      {partnerInformation()}
      {avalInformation()}
      <Box className={classes.AboutMeContainer}>
        <Text variant="h3">TIENDA</Text>
        <Box style={{ display: "flex" }}>
          <LabelValueTextList rows={rowsShop} />
        </Box>
      </Box>
      <Box className={classes.AboutMeContainer}>
        <Text variant="h3">SOLICITUD</Text>
        <Box style={{ display: "flex" }}>
          <LabelValueTextList rows={rowsRequest} />
          <Box style={{ marginLeft: 25, marginTop: 10 }}>
            <AdminAction
              splashBtnText="Cambiar Asesor de Preventa"
              isOpenModal={isOpenPresaleAdvisorModal}
              setOpenModal={setIsOpenPresaleAdvisorModal}
              modalTitle={"Cambiar Asesor de Preventa"}
              onClickSplashBtn={() => setIsOpenPresaleAdvisorModal(true)}
              handleCloseSnackbar={handleCloseSnackbar}
              isOpenSnackbar={isOpenPresaleAdvisorSnackbar}
              msgType={isError ? "error" : "success"}
              isLoading={loading?.PUT_PRESALE_ADVISORS}
              snackBarMsg={
                isError
                  ? error?.PUT_PRESALE_ADVISORS?.response?.data
                      ?.readable_message
                  : "Asesor de preventa actualizado"
              }
              onClickModalBtn={() => {
                updatePresaleAdvisor(presaleAdvisorId, presaleAdvisorEmail);
                setIsOpenPresaleAdvisorModal(false);
              }}
              children={
                <>
                  <InputLabel>Usuario:</InputLabel>
                  <Select
                    value={presaleAdvisorValue}
                    onChange={handleChangePresaleAdvisor}
                  >
                    {presaleAdvisorsInfo.map((advisor) => (
                      <MenuItem key={advisor.id} value={advisor.id}>
                        {advisor.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

function getAddress(a) {
  if (a && a.street_name) {
    return `${a.street_name} ${a.street_number} ${a.apartment_number || ""}`;
  }
  return "";
}

function getCity(a) {
  if (a && a.neighborhood) {
    return `${a.neighborhood}, ${a.zip_code}, ${a.city} - ${a.state}`;
  }
  return "";
}

function getStatus(s) {
  switch (s) {
    case "IN_PROGRESS":
      return "En Progreso";
    case "SUCCESS":
      return "Aprobado";
    case "ERROR":
      return "Error";
    case "REJECTED":
      return "Rechazado";
    default:
      break;
  }
  return "";
}

function getMaritalStatus(ms) {
  switch (ms) {
    case "MARRIED":
      return "Casad@";
    case "SINGLE":
      return "Solter@";
    case "DIVORCED":
      return "Divorciad@";
    case "FREE_UNION":
      return "Unión Libre";
    case "WIDOW":
      return "Viud@";
    default:
      break;
  }
  return "";
}

function getKycStatus(ks) {
  switch (ks) {
    case "PROCESSING":
      return "En Progreso";
    case "APPROVED":
      return "Aprobada";
    case "REJECTED":
      return "Rechazada";
    case "UNDER_REVIEW":
      return "En Revisión Manual";
    default:
      return "No identificado";
  }
}

function getKycReason(kr) {
  const i18n = I18n(process.env.REACT_APP_COUNTRY);
  switch (kr) {
    case "APPROVED_AUTOMATICALLY":
      return "Aprobado por Mati";
    case "DUPLICATED_DOCUMENT":
      return "Documento duplicado";
    case "REJECTED_AUTOMATICALLY":
      return "Rechazado por Mati";
    case "REVIEW_NEEDED":
      return "Revisión manual necesaria en Mati";
    case "NO_MATCH":
      return `El ${i18n.nacionatilityDocument} no coincide`;
    case "":
      return "En Progreso";
    default:
      return "No identificado";
  }
}

function getFormattedTimeElapsed(tMs) {
  tMs = tMs / 1000;
  const h = Math.floor(tMs / 3600 || 0);
  tMs = tMs % 3600;
  const m = Math.floor(tMs / 60 || 0);
  tMs = tMs % 60;
  const s = Math.floor(tMs || 0);
  return `${pad(h, 2)}:${pad(m, 2)}:${pad(s, 2)}`;
}

function pad(num, places) {
  return String(num).padStart(places, "0");
}

export default RegistrationInfo;
