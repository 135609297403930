import React from "react";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { Box } from "../../../components/Layout";
import useStyles from "./CustomerResidenceInfo.style";
import Text from "../../../components/Text";
import LabelValueTextList from "../../../components/LabelValueTextList/LabelValueTextList";
import I18n from "../../../utils/i18n";

const CustomerResidenceInfo = (props) => {
  const {
    apartment_number,
    delegation,
    neighborhood,
    state,
    street_name,
    street_number,
    zip_code,
    landline_phone,
  } = props;

  const classes = useStyles();

  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  const rows = [
    {
      label: "Dirección",
      value: `${street_name} ${street_number}`,
    },
    {
      label: "Interior",
      value: `${apartment_number}`,
    },
    {
      label: i18n.neighborhood,
      value: `${neighborhood} - ${zip_code}`,
    },
    {
      label: "Municipio",
      value: `${delegation}`,
    },
    {
      label: "Estado",
      value: `${state}`,
    },
    {
      label: "Teléfono de casa",
      value: `${landline_phone}`,
    },
  ];

  return (
    <Box className={classes.customerResidenceInfoContainer}>
      <Text variant="h3">MI CASA</Text>
      <Box style={{ display: "flex" }}>
        <LabelValueTextList rows={rows} />
      </Box>
    </Box>
  );
};

export default CustomerResidenceInfo;
