import React from "react";
import { Box } from "@material-ui/core";
import useStyles from "./StepText.style";
import Text from "../Text";
import I18n from "../../utils/i18n";

export const StepText = (props) => {
  const { step, customerType, newStep } = props;
  const classes = useStyles();

  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  function getDescriptiveName() {
    if (props.phoneValidation === true && step >= 6) {
      return `Validación de ${i18n.appName}`;
    }

    switch (newStep) {
      case "INIT":
        return "Flujo iniciado";
      case "SIGN_UP":
        return "Sobre ti";
      case "EXTRA_DATA":
        return "Sobre ti";
      case "TAX_INFO":
        return "Datos fiscales";
      case "ADDRESS":
        return "Datos fiscales";
      case "ABOUT_STORE":
        return "Sobre tu negocio";
      case "BUSINESS_ADDRESS":
        return "Dirección del negocio";
      case "ACCOUNT_NIP":
        return "NIP Buró";
      case "ACCOUNT_BUREAU":
        return "Buró consultado";
      case "PASSWORD":
        return "Contraseña asignada";
      case "INFO_NEEDED":
        return "Requisitos";
      case "PENDING_APPROVAL":
        return "Mesa de aprobación";
      case "CONTRACT_GENERATED":
        return "Contrato generado";
      case "KYC_REQUEST":
        return "Contrato generado";
      case "PROCESSING_KYC":
        return "Contrato generado";
      case "TITULAR_SIGNED":
        return "Titular firmado";
      case "CONTRACT_SIGNED":
        return "Contrato firmado";
      case "WAITING_CONTRACT_COLLATERALS":
        return "Esperando firma de avales";
      case "END":
        return "Aprobado";
      default:
        break;
    }

    switch (step) {
      case 0:
        return "Sobre mí";
      case 1:
        return "Mi casa";
      case 2:
        return "Mi trabajo";
      case 3:
        return customerType === 0 ? "Mi banco" : "Referencias";
      case 4:
        return "Confirmación";
      case 5:
        return "Validación de teléfono";
      case 6:
        return "MATI";
      default:
        return "";
    }
  }

  function getStepName() {
    if (newStep) {
      return "";
    }

    if (step >= 6 && props.phoneValidation === true) {
      return "Paso 8";
    }

    switch (step) {
      case 0:
        return "Paso 1";
      case 1:
        return "Paso 2";
      case 2:
        return "Paso 3";
      case 3:
        return "Paso 4";
      case 4:
        return "Paso 5";
      case 5:
        return "Paso 6";
      case 6:
        return "Paso 7";
      default:
        return "";
    }
  }

  function getBoxColor() {
    switch (newStep) {
      case "INIT":
      case "SIGN_UP":
      case "EXTRA_DATA":
        return "#b30000";
      case "ADDRESS":
      case "ABOUT_STORE":
        return "#7c1158";
      case "ACCOUNT_NIP":
      case "ACCOUNT_BUREAU":
        return "#4421af";
      case "PASSWORD":
        return "#1a53ff";
      case "INFO_NEEDED":
        return "#00b7c7";
      default:
        break;
    }

    switch (step) {
      case 1:
        return "#946CDE4C";
      case 2:
        return "#69E8FF4C";
      case 3:
        return "#0080FF4C";
      case 4:
        return "#EAE894";
      case 5:
        return "#ACE8A1";
      case 6:
        return "#65DE9B";
      default:
        return "#946CDE4C";
    }
  }

  return (
    <Box className={classes.horizontalContent}>
      <Box
        className={classes.rectangle}
        style={{ backgroundColor: getBoxColor() }}
      />
      <Text className={classes.textStep}>{getStepName()}</Text>
      <Text className={classes.textStyle}>{getDescriptiveName()}</Text>
    </Box>
  );
};

export default StepText;
