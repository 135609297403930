import React from "react";
import CustomerInfoCard from "./CustomerInfoCard/CustomerInfoCard";
import I18n from "../../../utils/i18n";

const CustomerInfoCards = (props) => {
  const i18n = I18n(process.env.REACT_APP_COUNTRY);
  const { birth_date, curp, email, phone_number, genre } = props;
  const {
    apartment_number,
    delegation,
    state,
    street_name,
    street_number,
    zip_code,
    landline_phone,
  } = props;
  const { incomes, incomes_type, job_name, seniority } = props;

  function relationInfo(rel) {
    switch (rel) {
      case "FAMILY_MEMBER":
        return "Familiar";
      case "FRIEND":
        return "Amigo(a)";
      case "NEIGHBOUR":
        return "Vecino(a)";
      default:
        return "No hay información";
    }
  }

  const personalDataRows = [
    {
      label: "Sexo",
      value: genre === "M" ? "Masculino" : "Femenino",
    },
    {
      label: i18n.nacionatilityDocument,
      value: curp,
    },
    {
      label: "Correo",
      value: email,
    },
    {
      label: "Fecha de nacimiento",
      value: birth_date.split("T")[0],
    },
    {
      label: "Teléfono",
      value: phone_number,
    },
  ];

  const addressDataRows = [
    {
      label: "Calle",
      value: street_name,
    },
    {
      label: "Número",
      value: street_number,
    },
    {
      label: "Número interior",
      value: apartment_number,
    },
    {
      label: "CP",
      value: zip_code,
    },
    {
      label: "Delegación",
      value: delegation,
    },
    {
      label: "Estado",
      value: state,
    },
    {
      label: "Número fijo",
      value: landline_phone,
    },
  ];

  const laboralDataRows = [
    {
      label: "Ingreso",
      value: incomes,
    },
    {
      label: "Tipo de ingreso",
      value: incomes_type == 1 ? "Fijo" : "Variable",
    },
    {
      label: "Trabajo",
      value: job_name,
    },
    {
      label: "Antigüedad",
      value: seniority,
    },
  ];

  const referenceDataRows = [
    {
      label: "Nombre",
      value: props[0] ? props[0].name : undefined,
    },

    {
      label: "Teléfono",
      value: props[0] ? props[0].phoneNumber.split(i18n.lada) : undefined,
    },
    {
      label: "Relación",
      value: props[0] ? relationInfo(props[0].relation) : undefined,
    },
    {
      label: "Nombre",
      value: props[1] ? props[1].name : undefined,
    },
    {
      label: "Teléfono",
      value: props[1] ? props[1].phoneNumber.split(i18n.lada) : undefined,
    },
    {
      label: "Relación",
      value: props[1] ? relationInfo(props[1].relation) : undefined,
    },
  ];

  return (
    <>
      <CustomerInfoCard rows={personalDataRows} type="personal_data" />
      <CustomerInfoCard rows={addressDataRows} type="address" />
      <CustomerInfoCard rows={laboralDataRows} type="job_information" />
      {props[0] && (
        <CustomerInfoCard rows={referenceDataRows} type="customer_references" />
      )}
    </>
  );
};

export default CustomerInfoCards;
