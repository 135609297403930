import React from "react";
import { Link } from "react-router-dom";
import AppBarM from "@material-ui/core/AppBar";
import { Toolbar, Grid } from "@material-ui/core";
import logo from "../../assets/logo.svg";
import logo_soy from "../../assets/logo_soy.svg";
import defaultUser from "../../assets/user_profile.png";
import Text from "../Text";
import useStyles from "./AppBar.styles";
import I18n from "../../utils/i18n";

const AppBar = ({ userName = "nuevamente", imgProfile = defaultUser }) => {
  const classes = useStyles();
  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  const useLogo = i18n.country === "mx" ? logo : logo_soy;

  return (
    <div className={classes.root}>
      <AppBarM elevation={0} position="static">
        <Toolbar>
          <Link to="/registers">
            <div className={classes.appLogo}>
              <img className={classes.appLogo} src={useLogo} alt="logo" />
            </div>
          </Link>
          <div className={classes.emptySpace} />
          <div className={classes.rightMenu}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-end"
            >
              <Text className={classes.normalText}>{`¡Hola ${userName}!`}</Text>
              <Link to="/home" className={classes.customLink}>
                <Text className={classes.linkText}>Ir a mi perfil</Text>
              </Link>
            </Grid>
            <div className={classes.containerMenu}>
              <img
                className={classes.userLogo}
                src={imgProfile}
                alt="user_logo"
              />
            </div>
          </div>
        </Toolbar>
      </AppBarM>
    </div>
  );
};

export default AppBar;
