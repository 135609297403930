import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";

import Moment from "moment";
import CreditModal from "../Modal/CreditModal";
import useStyles from "./CreditDetail.style";
import LabeledText from "../../../components/LabeledText/LabelText";
import PageContainer from "../../../components/PageContainer";
import Button from "../../../components/Button";
import Text from "../../../components/Text";
import { Box } from "../../../components/Layout";
import shoppingBag from "../../../assets/shop.svg";
import { getClient } from "./creditDetailActions";
import { dayNamesMap } from "../../../constants/constants";
import { moneyFunction } from "../../../constants/moneyFunction";
import "moment/locale/es";
import * as ACTION from "../actionTypes";
import I18n from "../../../utils/i18n";

const CreditDetailPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state.registersState);

  const closeAlert = () => {
    dispatch({ type: ACTION.UPDATE_CLOSE_ALERT });
  };

  const [showModal, setShowModal] = useState(false);

  const { displayedCustomer: displayedLoan } = useSelector(
    (state) => state.registersState
  );

  useEffect(() => {
    const { customerID } = props.match.params;
    dispatch(getClient(customerID));
  }, []);

  let credit_line_id;
  let shop_name;
  let shopkeeper_phone_number;
  let credit_line_amount;
  let installment;
  let payment_day;
  let credit_line_start_date;
  let installment_plan_list;

  try {
    if (displayedLoan) {
      credit_line_id =
        "credit_line_id" in displayedLoan ? displayedLoan.credit_line_id : {};
      shop_name = displayedLoan.shop_name || displayedLoan.name || "S/N";
      shopkeeper_phone_number = displayedLoan.phone_number || "000 0000 0000";
      credit_line_amount =
        "credit_line_amount" in displayedLoan
          ? "$" + displayedLoan.credit_line_amount
          : "";
      installment =
        "installment" in displayedLoan ? "$" + displayedLoan.installment : "";
      payment_day =
        "payment_day" in displayedLoan ? displayedLoan.payment_day : "";
      credit_line_start_date =
        "credit_line_start_date" in displayedLoan
          ? Moment(displayedLoan.credit_line_start_date).format("DD/MMMM/yyyy")
          : "N/A";
      installment_plan_list =
        "installment_plan_list" in displayedLoan
          ? displayedLoan.installment_plan_list
          : {};
    }
  } catch (e) {
    console.log(e);
  }

  const planList =
    installment_plan_list && installment_plan_list.length > 0
      ? installment_plan_list.map((row) => {
          return (
            <TableRow key={row.name} style={{ maxHeight: 43 }}>
              <TableCell align="center">
                <Text className={classes.textStyle}>{row.installment_num}</Text>
              </TableCell>
              <TableCell align="center">
                <Text className={classes.textStyle}>{row.deadline_date}</Text>
              </TableCell>
              <TableCell align="center">
                <Text className={classes.textStyle}>
                  {moneyFunction(row.starting_balance)}
                </Text>
              </TableCell>
              <TableCell align="center">
                <Text className={classes.textStyle}>
                  {moneyFunction(row.installment)}
                </Text>
              </TableCell>
              <TableCell align="center">
                <Text className={classes.textStyle}>
                  {moneyFunction(row.principal)}
                </Text>
              </TableCell>
              <TableCell align="center">
                <Text className={classes.textStyle}>
                  {moneyFunction(row.interest)}
                </Text>
              </TableCell>
              <TableCell align="center">
                <Text className={classes.textStyle}>
                  {moneyFunction(row.vat)}
                </Text>
              </TableCell>
              <TableCell align="center">
                <Text className={classes.textStyle}>
                  {moneyFunction(row.ending_balance)}
                </Text>
              </TableCell>
            </TableRow>
          );
        })
      : "";

  const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }))(Badge);

  const SmallAvatar = withStyles((theme) => ({
    root: {
      width: 22,
      height: 22,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }))(Avatar);

  return (
    <PageContainer>
      <Snackbar
        open={alert.open}
        onClose={closeAlert}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={alert.severity} onClose={closeAlert}>
          {alert.message}
        </Alert>
      </Snackbar>

      <Link to="/credits" className={classes.controls}>
        <ArrowBackIcon />
        <Text>Regresar</Text>
      </Link>

      <Box style={{ width: "100%" }}>
        <Box className={classes.mainPanel}>
          <Box border={1} className={classes.container}>
            <Box borderBottom={1} className={classes.upperPanel}>
              <Box className={classes.customerStatus}>
                <div className={classes.root}>
                  <StyledBadge
                    overlap="circle"
                    variant="dot"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <Avatar
                      alt="logo"
                      src={shoppingBag}
                      style={{ width: 75, height: 75 }}
                    >
                      T
                    </Avatar>
                  </StyledBadge>
                </div>

                <Box>
                  <Text variant="h2">{shop_name}</Text>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      paddingTop: "5%",
                    }}
                  >
                    <CallOutlinedIcon
                      htmlColor="#0253cc"
                      style={{ width: 17, marginRight: 6 }}
                    />
                    <Text variant="h3">{shopkeeper_phone_number}</Text>
                  </Box>
                </Box>
              </Box>

              <LabeledText label={credit_line_amount} text="Monto solicitado" />
              <LabeledText label={installment} text="Aporte semanal" />
              <LabeledText
                label={dayNamesMap[payment_day]}
                text="Día de Pago"
              />
              <LabeledText
                label={credit_line_start_date}
                text="Fecha de desembolso"
              />

              <Link to={`/credits/${credit_line_id}/update`}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  style={{
                    width: 200,
                    color: "#0253cc",
                    borderColor: "#0253cc",
                  }}
                >
                  Editar solicitud
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box>
          <Text variant="h3" className={classes.textBlue}>
            Tablas de amortizaci&oacute;n
          </Text>
        </Box>

        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            size="medium"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Semana</TableCell>
                <TableCell align="center">Fecha de Pago</TableCell>
                <TableCell align="center">Balance Inicial</TableCell>
                <TableCell align="center">Monto de Cuota</TableCell>
                <TableCell align="center">Pago Capital</TableCell>
                <TableCell align="center">Inter&eacute;s</TableCell>
                <TableCell align="center">
                  {I18n(process.env.REACT_APP_COUNTRY).tax}
                </TableCell>
                <TableCell align="center">Balance Final</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{planList}</TableBody>
          </Table>
        </TableContainer>
      </Box>

      <CreditModal
        open={showModal}
        setOpen={setShowModal}
        loan={displayedLoan}
      />
    </PageContainer>
  );
};

export default CreditDetailPage;
