import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Alert from "@material-ui/lab/Alert";

import { useDispatch, useSelector } from "react-redux";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@material-ui/core/FormControl";
import {
  TextField,
  Select,
  FormHelperText,
  Input,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import { withStyles } from "@material-ui/core/styles";
import { moneyFunction } from "../../../constants/moneyFunction";
import MissingDataAlert from "../../../components/MissingDataAlert/MissingDataAlert";

import { getFindShop, getCreditLine, applyPayment } from "./paymentActions";
import { postPaymentAgreements } from "../../../reducers/payment.action";
import { getFindCustomer } from "../Create/creditCreateActions";
import * as ACTIONS from "../actionTypes";
import useStyles from "./Payment.style";
import SectionTitle from "../../../components/SectionTitle";
import Button from "../../../components/Button";
import PageContainer from "../../../components/PageContainer";
import Text from "../../../components/Text";
import { Box } from "../../../components/Layout";
import I18n from "../../../utils/i18n";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

const CreditCreatePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [inputCustomerValue, setInputCustomerVale] = useState("");
  const [isOpenPaymentAlert, setIsOpenPaymentAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  const { alert } = useSelector((state) => state.registersState);
  const closeAlert = () => {
    dispatch({ type: ACTIONS.UPDATE_CLOSE_ALERT });
  };

  const { error, shops, displayedCreditLine, customersFound } = useSelector(
    (state) => state.registersState
  );

  const { error: paymentError } = useSelector((state) => state.paymentReducer);

  const [values, setValues] = React.useState({
    accountType: "",
    paymentType: "NORMAL",
    shopSelected: "",
    customerSelected: "",
    amount: "",
    dueDate: "",
    cancellationAmount: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSelectShop = () => (event) => {
    console.log(event);
    handleChange("shopSelected")(event);
    dispatch(getCreditLine(event.target.value.credit_line_id));
  };

  const handleSelectCustomer = () => (event) => {
    handleChange("customerSelected")(event);
    dispatch(getCreditLine(event.target.value.credit_line_id));
  };

  const applyAgreementPayment = () => {
    let hasError = false;
    const errors = {};
    Object.entries(values).forEach(([k, v]) => {
      if (v === "" || v == 0) {
        if (values.accountType === "CU") {
          if (k !== "shopSelected") {
            hasError = true;
            errors[k] = errorMsgs[k];
          }
        } else {
          if (k !== "customerSelected") {
            hasError = true;
            errors[k] = errorMsgs[k];
          }
        }
      } else {
        errors[k] = "";
      }
    });
    handleErrorMessages(errors);
    if (hasError) {
      return;
    }
    let body = {
      amount: Number(values.amount),
      cancellation_amount: Number(values.cancellationAmount),
      due_date: values.dueDate,
    };
    const id =
      values.accountType === "CU"
        ? values.customerSelected.credit_line_id
        : values.shopSelected.credit_line_id;
    dispatch(postPaymentAgreements(body, id)).then(handleOpenPaymentAlert());
    setValues({ ...values, amount: "", dueDate: "", cancellationAmount: "" });
  };

  const handleOpenPaymentAlert = () => {
    setTimeout(() => {
      setIsOpenPaymentAlert(true);
    }, 500);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const [errorMessages, setErrorMessages] = React.useState({
    accountType: "",
    paymentType: "",
    shopSelected: "",
    customerSelected: "",
    amount: "",
    dueDate: "",
    cancellationAmount: "",
  });

  const errorMsgs = {
    accountType: "* Seleccione el tipo de crédito !",
    paymentType: "* Seleccione el tipo de pago !",
    shopSelected: "* Seleccione la tienda !",
    customerSelected: "* Seleccione el cliente !",
    amount: "* Ingrese el monto !",
    dueDate: "* Seleccione una fecha válida !",
    cancellationAmount: "* Ingrese el monto !",
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const [inputValue, setInputVale] = useState("");
  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);

  const handleSubmit = () => {
    let hasError = false;
    const errors = {};
    Object.entries(values).forEach(([k, v]) => {
      if (v === "" || v == 0) {
        if (values.accountType === "CU") {
          if (
            k !== "shopSelected" &&
            k !== "dueDate" &&
            k !== "cancellationAmount"
          ) {
            hasError = true;
            errors[k] = errorMsgs[k];
          }
        } else {
          if (
            k !== "customerSelected" &&
            k !== "dueDate" &&
            k !== "cancellationAmount"
          ) {
            hasError = true;
            errors[k] = errorMsgs[k];
          }
        }
      } else {
        errors[k] = "";
      }
    });
    handleErrorMessages(errors);
    if (hasError) {
      return;
    }
    dispatch(
      applyPayment(
        values.accountType === "CU"
          ? values.customerSelected.credit_line_id
          : values.shopSelected.credit_line_id,
        values.amount,
        values.paymentType,
        "ADMIN"
      ),
      setValues({ ...values, amount: "" })
    );
  };

  const handlePaymentButtonClick = () => {
    if (values.paymentType === "PAYMENT_AGREED") {
      applyAgreementPayment();
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
      setInputVale("");
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);

  useEffect(() => {
    if (values.accountType !== "") {
      setInputVale("");
      setValues((prevProps) => ({
        ...prevProps,
        shopSelected: "",
        customerSelected: "",
        amount: "",
      }));
    }
  }, [values.accountType]);

  useEffect(
    () => () =>
      dispatch({
        type: ACTIONS.SET_STATE,
        label: "searchString",
        value: "",
      }),
    []
  );

  useEffect(() => {
    if (paymentError?.POST_PAYMENT_AGREEMENTS) {
      if (paymentError?.POST_PAYMENT_AGREEMENTS !== null) {
        setIsError(true);
      }
    }
  }, [paymentError?.POST_PAYMENT_AGREEMENTS]);

  const shopList =
    shops && shops.length > 0
      ? shops.map((shop, i) => {
          if (shop.credit_line_id === "") {
            return;
          }
          return (
            <MenuItem value={shop}>
              {shop.personhood_name} / {shop.shopkeeper_name}
            </MenuItem>
          );
        })
      : "";

  const customersFoundList =
    customersFound && customersFound.length > 0
      ? customersFound.map((customer) => {
          if (
            customer.credit_status !== "ACTIVED" ||
            customer.credit_line_id === ""
          ) {
            return;
          }
          return (
            <MenuItem value={customer}>
              {customer.full_name} / {customer.phone_number}
            </MenuItem>
          );
        })
      : "";
  console.log(displayedCreditLine);
  return (
    <PageContainer>
      <Snackbar
        open={alert.open}
        onClose={closeAlert}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={alert.severity} onClose={closeAlert}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isOpenPaymentAlert}
        onClose={closeAlert}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={isError ? "error" : "success"}
          onClose={() => setIsOpenPaymentAlert(false)}
        >
          {!isError
            ? "Acuerdo de pago aplicado correctamente"
            : paymentError?.POST_PAYMENT_AGREEMENTS?.response?.data
                ?.readable_message || "Ocurrió un error"}
        </Alert>
      </Snackbar>

      <Link to="/credits" className={classes.controls}>
        <ArrowBackIcon />
        <Text>Regresar</Text>
      </Link>

      <Box className={classes.mainPanel}>
        <SectionTitle>
          Aplicar pago a l&iacute;nea de cr&eacute;dito a tiendas
        </SectionTitle>
      </Box>

      <Box>
        {nothingFoundNoticeShown && (
          <MissingDataAlert status="BAD" text="No se encontraron tiendas" />
        )}
      </Box>

      <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
        <Text variant="h4" className={classes.textBlue}>
          Tipo de cr&eacute;dito
        </Text>
        <Select
          displayEmpty
          style={{ display: "flex" }}
          labelId="demo-simple-select-label"
          id="advisor"
          value={values.accountType}
          onChange={handleChange("accountType")}
        >
          <MenuItem value="" disabled>
            Selecciona un tipo de cr&eacute;dito
          </MenuItem>
          <MenuItem value="SK">Tendero</MenuItem>
          <MenuItem value="CU">Cliente</MenuItem>
        </Select>
        <FormHelperText>{errorMessages.accountType}</FormHelperText>
      </Box>

      {values.accountType === "SK" ? (
        <>
          <Box>
            <Text variant="h4" className={classes.textBlue}>
              Nombre de la tienda
            </Text>
          </Box>

          <Box component="form" className={classes.searchContainer}>
            <InputBase
              value={inputValue}
              onChange={(event) => {
                setInputVale(event.target.value);
                dispatch({
                  type: ACTIONS.SET_STATE,
                  label: "searchString",
                  value: event.target.value,
                });
              }}
              className={classes.input}
              placeholder="Nombre de la tienda"
              inputProps={{ "aria-label": "search user" }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              onClick={() => {
                dispatch(getFindShop());
              }}
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Box>
          <FormHelperText>{errorMessages.shopSelected}</FormHelperText>

          {shops && shops.length >= 1 ? (
            <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>
                Elige una tienda
              </Text>
              <Select
                displayEmpty
                style={{ display: "flex" }}
                labelId="demo-simple-select-label"
                id="advisor"
                value={values.shopSelected}
                onChange={handleSelectShop()}
              >
                <MenuItem value="" disabled>
                  Selecciona una tienda
                </MenuItem>
                {shopList}
              </Select>
            </Box>
          ) : (
            <Box></Box>
          )}
          {values.shopSelected ? (
            <Box>
              <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  id="custom-css-standard-input"
                  label="Responsable de la tienda"
                  defaultValue=""
                  value={values.shopSelected.shopkeeper_name}
                  InputLabelProps={{ shrink: true }}
                />
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  label="Teléfono del responsable"
                  id="custom-css-outlined-input"
                  defaultValue="No viene del WS"
                  value={values.shopSelected.shopkeeper_phone_number}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  label={`${i18n.nacionatilityDocument} del responsable de la tienda`}
                  id="custom-css-outlined-input"
                  defaultValue=""
                  value={values.shopSelected.shopkeeper_curp}
                  InputLabelProps={{ shrink: true }}
                />
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  label="Dirección de la tienda"
                  id="custom-css-standard-input"
                  defaultValue=""
                  value={`${values.shopSelected.street_name} ${values.shopSelected.street_number} ${values.shopSelected.apartment_number} CP ${values.shopSelected.zip_code} - ${values.shopSelected.neighborhood}, ${values.shopSelected.delegation}, ${values.shopSelected.state}`}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}

          {values.shopSelected && displayedCreditLine ? (
            <>
              <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  label="Total para liquidar"
                  id="custom-css-standard-input"
                  defaultValue={moneyFunction(0)}
                  value={moneyFunction(
                    displayedCreditLine?.settlement_amount || 0
                  )}
                  InputLabelProps={{ shrink: true }}
                />
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  label="Cuota"
                  id="custom-css-standard-input"
                  defaultValue={moneyFunction(0)}
                  value={moneyFunction(displayedCreditLine.installment)}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  label="Estado de la línea de crédito"
                  id="custom-css-standard-input"
                  defaultValue=""
                  value={displayedCreditLine.status}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </>
          ) : (
            <Box></Box>
          )}

          {values.shopSelected &&
          displayedCreditLine &&
          displayedCreditLine.next_payment ? (
            <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                label="Próximo Pago"
                id="custom-css-standard-input"
                defaultValue={moneyFunction(0)}
                value={moneyFunction(displayedCreditLine.next_payment.amount)}
                InputLabelProps={{ shrink: true }}
              />
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                label="Próxima Fecha de Pago"
                id="custom-css-standard-input"
                defaultValue=""
                value={displayedCreditLine.next_payment.deadline_date}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          ) : (
            <Box></Box>
          )}
        </>
      ) : (
        <></>
      )}
      {values.accountType === "CU" ? (
        <Box width="100%">
          <Box>
            <Text variant="h4" className={classes.textBlue}>
              Tel&eacute;fono del cliente
            </Text>
          </Box>

          <Box component="form" className={classes.searchContainer}>
            <InputBase
              value={inputCustomerValue}
              onChange={(event) => {
                setInputCustomerVale(event.target.value);
                dispatch({
                  type: ACTIONS.SET_STATE,
                  label: "searchCustomerString",
                  value: event.target.value,
                });
              }}
              className={classes.input}
              placeholder="N&uacute;mero telef&oacute;nico del cliente"
              inputProps={{ "aria-label": "search user" }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              onClick={() => {
                dispatch(getFindCustomer());
              }}
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Box>
          <FormHelperText>{errorMessages.customerSelected}</FormHelperText>

          {customersFound && customersFound.length >= 1 ? (
            <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>
                Elige el cliente
              </Text>
              <Select
                displayEmpty
                style={{ display: "flex" }}
                labelId="demo-simple-select-label"
                id="customer"
                value={values.customerSelected}
                onChange={handleSelectCustomer()}
              >
                <MenuItem value="" disabled>
                  Selecciona un cliente
                </MenuItem>
                {customersFoundList}
              </Select>
            </Box>
          ) : (
            <Box></Box>
          )}

          {values.customerSelected ? (
            <Box>
              <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  id="custom-css-standard-input"
                  label="Responsable de la tienda"
                  defaultValue=""
                  value={values.customerSelected.full_name}
                  InputLabelProps={{ shrink: true }}
                />
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  label="Teléfono del responsable"
                  id="custom-css-outlined-input"
                  defaultValue="No viene del WS"
                  value={values.customerSelected.phone_number}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  label={`${i18n.nacionatilityDocument} del responsable de la tienda`}
                  id="custom-css-outlined-input"
                  defaultValue=""
                  value={values.customerSelected.email}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}

          {values.customerSelected && displayedCreditLine ? (
            <>
              <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  label="Total para liquidar"
                  id="custom-css-standard-input"
                  defaultValue={moneyFunction(0)}
                  value={moneyFunction(
                    displayedCreditLine?.settlement_amount || 0
                  )}
                  InputLabelProps={{ shrink: true }}
                />
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  label="Cuota"
                  id="custom-css-standard-input"
                  defaultValue={moneyFunction(0)}
                  value={moneyFunction(displayedCreditLine.installment)}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20 }}
                  className={classes.margin}
                  label="Estado de la línea de crédito"
                  id="custom-css-standard-input"
                  defaultValue=""
                  value={displayedCreditLine.status}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </>
          ) : (
            <Box></Box>
          )}

          {values.customerSelected &&
          displayedCreditLine &&
          displayedCreditLine.next_payment ? (
            <Box style={{ width: "100%", paddingTop: 15, paddingBottom: 15 }}>
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                label="Próximo Pago"
                id="custom-css-standard-input"
                defaultValue={moneyFunction(0)}
                value={moneyFunction(displayedCreditLine.next_payment.amount)}
                InputLabelProps={{ shrink: true }}
              />
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20 }}
                className={classes.margin}
                label="Próxima Fecha de Pago"
                id="custom-css-standard-input"
                defaultValue=""
                value={displayedCreditLine.next_payment.deadline_date}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
      ) : (
        <Box></Box>
      )}

      <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
        <Text variant="h4" className={classes.textBlue}>
          Tipo de pago
        </Text>
        <Select
          displayEmpty
          style={{ display: "flex" }}
          labelId="demo-simple-select-label"
          id="paymentType"
          value={values.paymentType}
          onChange={handleChange("paymentType")}
        >
          <MenuItem value="NORMAL">Normal</MenuItem>
          <MenuItem value="CANCELLATION_FIRST_PRINCIPAL">
            Condonaci&oacute;n normal
          </MenuItem>
          <MenuItem value="CANCELLATION_FIRST_INTEREST">
            Condonaci&oacute;n a intereses
          </MenuItem>
          <MenuItem value="RESTRUCTURING">Restructuraci&oacute;n</MenuItem>
          <MenuItem value="CHANGE_OF_CONDITIONS">
            Cambio de condiciones
          </MenuItem>
          <MenuItem value="CASHBACK">Cashback</MenuItem>
          <MenuItem value="REFERRAL">Pago por referido</MenuItem>
          <MenuItem value="ADJUSTMENT">Pago de ajuste</MenuItem>
          <MenuItem value="PAYMENT_AGREED">Acuerdo de pago</MenuItem>
        </Select>
        <FormHelperText>{errorMessages.paymentType}</FormHelperText>
      </Box>

      {values.paymentType === "PAYMENT_AGREED" && (
        <>
          <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>
              Fecha máxima de acuerdo de pago
            </Text>
            <Input
              id="due-date"
              style={{ display: "flex" }}
              value={values.dueDate}
              type="date"
              onChange={handleChange("dueDate")}
              placeholder="Fecha de pago"
            />
            <FormHelperText>{errorMessages.dueDate}</FormHelperText>
          </Box>
          <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>
              Monto mínimo de pago para aplicación
            </Text>
            <Input
              id="amount"
              style={{ display: "flex" }}
              value={values.amount}
              type="number"
              onChange={handleChange("amount")}
              placeholder="De $1 a $1,000,000"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
            <FormHelperText>{errorMessages.amount}</FormHelperText>
          </Box>
        </>
      )}

      <Box style={{ width: "35%", paddingTop: 15, paddingBottom: 15 }}>
        <Text variant="h4" className={classes.textBlue}>
          {values.paymentType === "PAYMENT_AGREED"
            ? "Monto a condonar"
            : "¿Cuánto pagó?"}
        </Text>
        <Input
          id="cancellation-amount"
          style={{ display: "flex" }}
          value={
            values.paymentType === "PAYMENT_AGREED"
              ? values.cancellationAmount
              : values.amount
          }
          type="number"
          onChange={
            values.paymentType === "PAYMENT_AGREED"
              ? handleChange("cancellationAmount")
              : handleChange("amount")
          }
          placeholder="De $1 a $1,000,000"
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
        <FormHelperText>
          {values.paymentType === "PAYMENT_AGREED"
            ? errorMessages.cancellationAmount
            : errorMessages.amount}
        </FormHelperText>
      </Box>

      <Box style={{ width: "35%" }}>
        <FormControl className={classes.formStyles}>
          <Box className={classes.buttonContainer}>
            <Button
              variant="outlined"
              className={classes.button}
              style={{ backgroundColor: "#0253cc", color: "white" }}
              onClick={() => {
                handlePaymentButtonClick();
              }}
            >
              Aplicar Pago
            </Button>
          </Box>
        </FormControl>
      </Box>
    </PageContainer>
  );
};

export default CreditCreatePage;
