import I18n from "./i18n";

const i18n = I18n(process.env.REACT_APP_COUNTRY);

export const formatTaxId = (value) => {
  if (i18n.format_rfc) {
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 3) {
      return numericValue; // XXX
    } else if (numericValue.length <= 10) {
      return numericValue.replace(/^(\d{3})(\d{1,7})$/, "$1-$2"); // XXX-XXXXXXX
    } else if (numericValue.length <= 11) {
      return numericValue.replace(/^(\d{3})(\d{7})(\d{1})$/, "$1-$2-$3"); // XXX-XXXXXXX-X
    }
    return numericValue.substring(0, 11);
  }
  return value;
};
