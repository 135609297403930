import I18n from "../../utils/i18n";

export const moneyFunction = (number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: I18n(process.env.REACT_APP_COUNTRY).currency,
    minimunFractionDigits: 2,
  });
  return formatter.format(number);
};
