import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  getFindShop,
  getCreditLine,
  applyPayment,
} from "../Credit/Payment/paymentActions";
import { getAdvisor } from "../Credit/Create/creditCreateActions";
import ExpressExtensionUI from "./ExpressExtension";
import useStyles from "./ExpressExtension.styles";
import { postLightExtension } from "./expressAction";
import I18n from "../../utils/i18n";

export const ExpressExtension = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { error, advisors, shops, customersFound } = useSelector(
    (state) => state.registersState
  );
  const { extensionLigth } = useSelector((state) => state.shopsState);

  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [creatingExtensionCreditLine, setCreatingExtensionCreditLine] =
    useState(false);

  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  const [values, setValues] = React.useState({
    accountType: "",
    shopSelected: "",
    customerSelected: "",
    amount: "",
    installment: "",
    day: "",
    advisor: "",
    bank_name: "",
    bank_account_number: "",
    payment_frequency: "",
    monthly_day: 0,
    interest_rate: 10,
    loan_portfolio_status: "NORMAL",
  });

  useEffect(() => {
    dispatch(getAdvisor());
  }, []);

  useEffect(() => {
    if (creatingExtensionCreditLine) {
      const body = {
        new_amount: parseInt(values.amount),
        new_installment: parseInt(values.installment),
        new_term: parseInt(values.monthly_day),
        new_term_type: values.payment_frequency,
        new_interest_rate: parseInt(values.interest_rate),
        extension_type: "LIGHT",
        sales_advisor_id: values.advisor,
      };
      dispatch(postLightExtension(values.shopSelected.credit_line_id, body));
    }
  }, [creatingExtensionCreditLine]);

  useEffect(() => {
    if (Object.keys(extensionLigth).length !== 0) {
      setValues({
        accountType: "",
        shopSelected: "",
        customerSelected: "",
        amount: "",
        installment: "",
        day: "",
        advisor: "",
        bank_name: "",
        bank_account_number: "",
        payment_frequency: "",
        monthly_day: 0,
        interest_rate: 10,
        loan_portfolio_status: "NORMAL",
      });
      setCreatingExtensionCreditLine(false);
    }
  }, [extensionLigth]);

  const handleChange = (prop) => (event) => {
    if (prop === "interest_rate") {
      if (event.target.value >= 0) {
        setValues({ ...values, [prop]: event.target.value });
      }
    } else if (prop === "amount") {
      if (event.target.value >= 0) {
        setValues({ ...values, [prop]: event.target.value });
      }
    } else if (prop === "installment") {
      if (event.target.value >= 0) {
        setValues({ ...values, [prop]: event.target.value });
      }
    } else if (prop == "shopSelected") {
      setValues({ ...values, [prop]: event.target.value });
      dispatch(getCreditLine(event.target.value.credit_line_id));
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const [errorMessages, setErrorMessages] = React.useState({
    accountType: "",
    shopSelected: "",
    customerSelected: "",
    amount: "",
    installment: "",
    day: "",
    advisor: "",
    bank_name: "",
    bank_account_number: "",
    payment_frequency: "",
    monthly_day: "",
    interest_rate: "",
    loan_portfolio_status: "",
  });

  const errorMsgs = {
    accountType: "* Seleccione el tipo de crédito !",
    shopSelected: "* Seleccione la tienda !",
    customerSelected: "* Seleccione el cliente !",
    amount: "* Ingrese el monto !",
    installment: "* Ingrese el valor de la cuota !",
    advisor:
      values.accountType === "CU"
        ? "* Selecciona la tienda que avala !"
        : "* Seleccione la cartera !",
    bank_name: "* Selecciona el banco del cliente !",
    bank_account_number: "* Ingrese el número de cuenta bancaria !",
    payment_frequency: "* Selecciona la frecuencia de pago",
    interest_rate: "* Ingrese el interés de la línea de crédito",
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const MonthlyDays = () => {
    let arrayDay = [];
    for (let day = 1; day < 27; day++) {
      arrayDay.push(day);
    }
    return arrayDay.map((day) => {
      return <MenuItem value={day}>{day}</MenuItem>;
    });
  };

  const advisorList =
    advisors && advisors.length > 0
      ? advisors.map((adv) => (
          <MenuItem value={adv.sales_advisor_id}>{adv.name}</MenuItem>
        ))
      : "";

  const shopList =
    shops && shops.length > 0
      ? shops.map((shop, i) => {
          return (
            <MenuItem value={shop}>
              {shop.personhood_name}/{shop.shopkeeper_name}
            </MenuItem>
          );
        })
      : "";

  const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: "green",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "white",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "red",
        },
        "&:hover fieldset": {
          borderColor: "yellow",
        },
        "&.Mui-focused fieldset": {
          borderColor: "white",
        },
      },
    },
  })(TextField);

  const handleSubmit = () => {
    console.log(advisors);
    let hasError = false;
    const errors = {};
    Object.entries(values).forEach(([k, v]) => {
      if (v === "" || v === 0) {
        if (k !== "monthly_day") {
          if (k !== "day") {
            if (values.accountType === "CU") {
              if (k !== "shopSelected") {
                hasError = true;
                errors[k] = errorMsgs[k];
              }
            } else if (
              k !== "customerSelected" &&
              k !== "bank_name" &&
              k !== "bank_account_number"
            ) {
              hasError = true;
              errors[k] = errorMsgs[k];
            }
          }
        }
      } else {
        errors[k] = "";
      }
    });
    if (values.amount % 500 !== 0) {
      hasError = true;
      errors.amount = "* El monto del crédito debe ser múltiplo de 500 !";
    }
    if (values.payment_frequency === "MONTHLY") {
      if (!(values.monthly_day > 0 && values.monthly_day < 27)) {
        hasError = true;
        errors.monthly_day = "* Selecciona un día de pago !";
      }
    } else {
      if (values.day === "") {
        hasError = true;
        errors.day = "* Selecciona un día";
      }
    }
    handleErrorMessages(errors);
    if (hasError) {
      return;
    }

    setCreatingExtensionCreditLine(true);
  };

  return (
    <ExpressExtensionUI
      classes={classes}
      dispatch={dispatch}
      values={values}
      setValues={setValues}
      handleChange={handleChange}
      errorMessages={errorMessages}
      setErrorMessages={setErrorMessages}
      errorMsgs={errorMsgs}
      handleErrorMessages={handleErrorMessages}
      nothingFoundNoticeShown={nothingFoundNoticeShown}
      showNothingFoundNotice={showNothingFoundNotice}
      inputValue={inputValue}
      setInputValue={setInputValue}
      MonthlyDays={MonthlyDays}
      advisorList={advisorList}
      shops={shops}
      shopList={shopList}
      CssTextField={CssTextField}
      creatingExtensionCreditLine={creatingExtensionCreditLine}
      setCreatingExtensionCreditLine={setCreatingExtensionCreditLine}
      handleSubmit={handleSubmit}
      i18n={i18n}
    />
  );
};

export default ExpressExtension;
