import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Divider,
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStyles from "./CustomerTransactions.styles";
import Box from "../../components/Layout/Box";
import PageContainer from "../../components/PageContainer/PageContainer";
import Text from "../../components/Text";
import Toggle from "../../components/Toggle/Toggle";
import LabeledText from "../../components/LabeledText/LabeledText";
import LabeledDate from "../../components/LabeledDate/LabeledDate";
import CutoffList from "./CutoffList/CutoffList";
import CurrentTransactions from "./CurrentTransactions";
import CustomerInfoCards from "./CustomerInfoCards/CustomerInfoCards";
import { getCustomer } from "../Customers/customersActions";
import TransactionTable from "./TransactionTable/TransactionTable";
import Collections from "./Collections.js/Collections";
import { dayNames } from "../../constants/constants";
import { dateFunction, simpleDateFunction } from "./dateFunction";
import CreditInformationPage from "../Shop/SubMenu/CreditInformation/CreditInformationPage";
import I18n from "../../utils/i18n";

export const CustomerTransactions = (props) => {
  const classes = useStyles();
  const { customerID } = props.match.params;
  const dispatch = useDispatch();
  const { displayedCustomer: customer } = useSelector(
    (state) => state.customersState
  );
  const [currentTab, setCurrentTab] = useState(0);
  const [currentCutoff, setCurrentCutoff] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expanded, toggleExpanded] = useState(false);
  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  useEffect(() => {
    dispatch(getCustomer(customerID));
  }, []);

  let personalData;
  let residentialInfo;
  let laboralInfo;
  let transactions;
  let references;
  let cutoffs = [];
  let credit_line_id;
  let creditData;
  let full_name;
  let available;
  let current;
  let balance;
  let amount;
  let minimum;
  let next_payment;
  let credit_status;
  let phone_number;
  let record_date;
  let last_deadline_date;
  let last;
  let payed = [];
  let overdue = [];
  let cutoffs_status;
  let on_time;
  let pending = [];
  let date_trans = [];
  let sorted_cut = [];
  let current_tx = [];
  let deadline;
  let paymentDay;

  if (customer) {
    personalData = "personal_data" in customer ? customer.personal_data : {};
    credit_line_id = customer?.credit_line_id || "";
    creditData = "credit_details" in customer ? customer.credit_details : {};
    laboralInfo = "laboral_data" in customer ? customer.laboral_data : {};
    cutoffs = "cutoffs" in customer ? customer.cutoffs : [];
    transactions = "transactions" in customer ? customer.transactions : {};
    references = "references" in customer ? customer.references : [];
    available = creditData.available;
    amount = creditData.credit_amount;
    current = creditData.current;
    balance = creditData.balance;
    minimum = creditData.minimum_payment;
    next_payment = creditData.next_payment;
    credit_status = creditData.credit_status;
    residentialInfo = customer.address;
    full_name = personalData.full_name;
    phone_number = personalData.phone_number;
    record_date = personalData.record_date;
    paymentDay =
      personalData.preferred_payment_day === 0
        ? "-"
        : dayNames[personalData.preferred_payment_day - 1];

    if (cutoffs.length > 0) {
      last = cutoffs.length - 1;
      last_deadline_date = cutoffs[last].deadline_date;
      cutoffs_status = cutoffs.map((value) => value.status);
      payed = cutoffs_status.filter((two) => two == 2);
      overdue = cutoffs_status.filter((one) => one == 1);
      pending = cutoffs.map((value) => value.status == 1);
      on_time = cutoffs_status.length - overdue.length;
      deadline = cutoffs.map((value) => value.deadline_date);
      pending = cutoffs.filter(
        (value) => value.status == 1 && value.balance > 0
      );
      {
        transactions.length > 0 &&
          (date_trans = transactions.sort((a, b) =>
            a.date > b.date ? 1 : a.date < b.date ? -1 : 0
          ));
      }
      sorted_cut = cutoffs.sort((a, b) =>
        a.date > b.date ? 1 : a.date < b.date ? -1 : 0
      );
      sorted_cut.map((value) => (value.transactions = []));
      const sorted_last = sorted_cut.length - 1;
      let j = 0;

      for (let i = 0; i < date_trans.length; i++) {
        if (date_trans[i].date < sorted_cut[sorted_last].date) {
          const tx = date_trans[i];
          while (sorted_cut[j].date < tx.date) {
            j++;
          }
          sorted_cut[j].transactions.push(tx);
        } else {
          current_tx.push(date_trans[i]);
        }
      }
    } else {
      current_tx = transactions;
    }
  }

  function cutPay(t) {
    const product = [];
    let toNum;
    let sum = 0;
    for (let i = 0; i < t.length; i++) {
      if (t[i].product_id == 2) {
        toNum = Number(t[i].amount);
        product.push(toNum);
      }
    }
    for (let j = 0; j < product.length; j++) {
      sum += product[j];
    }
    return sum;
  }

  function payFee(t) {
    const product = [];
    let toNum;
    let sum = 0;
    for (let i = 0; i < t.length; i++) {
      if (t[i].product_id == 1) {
        toNum = Number(t[i].amount);
        product.push(toNum);
      }
    }
    for (let j = 0; j < product.length; j++) {
      sum += product[j];
    }
    return sum;
  }

  function blocked() {
    if (credit_status == "CANCELLED") {
      return <Chip label="BLOQUEADO" color="primary" style={{ margin: 10 }} />;
    }
  }
  if (!customer) {
    return <Text>El cliente no tiene datos</Text>;
  }

  const reversedCutoffs = cutoffs ? [...cutoffs].reverse() : [];

  return (
    <PageContainer>
      <Box className={classes.rootContainer}>
        <Link to="/customers" className={classes.controls}>
          <ArrowBackIcon />
          <Text> Regresar</Text>
        </Link>
      </Box>
      <Box className={classes.mainPanel}>
        <Box border={1} className={classes.container}>
          <Box borderBottom={1} className={classes.upperPanel}>
            <Box className={classes.customerStatus}>
              {customer && (
                <Box>
                  <Text variant="h2">{full_name}</Text>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      paddingTop: "5%",
                    }}
                  >
                    <CallOutlinedIcon
                      htmlColor="#0253cc"
                      style={{ width: 20, marginRight: 6 }}
                    />
                    <Text variant="h3">{phone_number.split(i18n.lada)}</Text>
                  </Box>
                  {blocked()}
                </Box>
              )}
            </Box>
            <LabeledText label={`$${amount}`} text="Límite de crédito" />
            <LabeledText label={`$${balance}`} text="Saldo actual" />
            {blocked() ? (
              <LabeledText label="$0.00" text="Disponible actual" />
            ) : (
              <LabeledText label={`$${available}`} text="Disponible actual" />
            )}
            <LabeledText label={`$${next_payment}`} text="Próximo pago" />
            <LabeledText label={`$${minimum}`} text="Pago mínimo" />
            <LabeledText label={`$${current}`} text="Gasto corriente actual" />
            <Toggle
              style={{ padding: "0.9%", alignSelf: "flex-start" }}
              onToggle={(toggled) => setIsExpanded(toggled)}
            />
          </Box>
          {isExpanded && (
            <Box className={classes.lowerPanel}>
              <LabeledText text="Día de Corte" label={paymentDay} />
              <LabeledDate
                label="Fecha de afiliación"
                date={dateFunction(record_date)}
              />
              {blocked() ? (
                <LabeledDate label="Próxima fecha de pago" date="INMEDIATO" />
              ) : last_deadline_date ? (
                <LabeledDate
                  label="Próxima fecha de pago"
                  date={dateFunction(last_deadline_date)}
                />
              ) : (
                <LabeledText label="Próxima fecha de pago" text="Sin cortes" />
              )}
              <LabeledText
                text="Cortes desde afiliación"
                label={cutoffs.length}
              />
              <LabeledText text="Cortes vencidos" label={overdue.length} />
              <LabeledText text="Cortes al corriente" label={on_time || 0} />
            </Box>
          )}
        </Box>
        <Box>
          <Tabs
            className={classes.tabs}
            value={currentTab}
            onChange={(event, newValue) => setCurrentTab(newValue)}
            aria-label="holi"
          >
            <Tab label="Cortes" />
            <Tab label="Transacciones corrientes" />
            <Tab label="Cortes Pendientes" />
            <Tab label="Información de cliente" />
            <Tab label="Gestionar cliente" />
            {credit_line_id !== "" && <Tab label="Información del crédito" />}
          </Tabs>
          {currentTab === 0 && (
            <Box
              className={classes.transactions}
              style={{ alignContent: "column" }}
            >
              <div>
                <FormControl>
                  <FormHelperText>Listado de cortes</FormHelperText>
                </FormControl>
                {cutoffs.length > 0 ? (
                  cutoffs.map((cut, idx) => (
                    <CutoffList
                      key={cut.cutoff_id}
                      click={(e) => {
                        setCurrentCutoff(idx);
                      }}
                      selected={currentCutoff}
                      name={cut.name}
                      amount={cut.amount}
                      date={new Date(cut.date)}
                      deadline_date={new Date(cut.deadline_date)}
                      balance={cut.balance}
                      status={cut.status}
                      cutoff_id={cut.cutoff_id}
                      list={idx}
                    />
                  ))
                ) : (
                  <Text variant="h3">{full_name} no tiene cortes</Text>
                )}
              </div>
              <Box>
                {sorted_cut.map(
                  (c, idx) =>
                    currentCutoff === idx && (
                      <Box
                        key={c.cutoff_id}
                        border={1}
                        style={{ width: "100%" }}
                        className={classes.cutoffContainer}
                      >
                        <Box borderBottom={1} className={classes.upperPanel}>
                          <Box className={classes.customerStatus}>
                            <Box>
                              <Text variant="h3">{c.name}</Text>
                            </Box>
                          </Box>
                          <LabeledText
                            label={`$ ${c.balance}`}
                            text="Saldo del corte"
                          />
                          <LabeledText label={c.amount} text="Monto a pagar" />
                          <Toggle
                            style={{ padding: "0.9%", alignSelf: "flex-start" }}
                            onToggle={(toggled) => {
                              toggleExpanded(toggled);
                            }}
                          />
                        </Box>
                        {expanded && (
                          <Box className={classes.lowerPanel}>
                            <Box style={{ height: "125%" }}>
                              <LabeledText
                                label={`${simpleDateFunction(c.date)}`}
                                text="fecha de corte"
                              />
                              <LabeledText
                                label={`${simpleDateFunction(c.deadline_date)}`}
                                text="fecha de vencimiento de corte"
                              />
                            </Box>
                            <Box className={classes.cutOffTotals}>
                              <LabeledText
                                text="total de compras"
                                label={`$${cutPay(c.transactions)}`}
                              />
                              <LabeledText
                                text="total de pagos"
                                label={`$${payFee(c.transactions)}`}
                              />
                            </Box>
                          </Box>
                        )}
                        <TransactionTable {...c.transactions} />
                      </Box>
                    )
                )}
              </Box>
            </Box>
          )}
          {currentTab === 1 && (
            <Box
              className={classes.transactions}
              style={{ alignContent: "column" }}
            >
              <CurrentTransactions {...current_tx} />
            </Box>
          )}
          {currentTab === 2 && (
            <Box className={classes.pendingCutoffTable}>
              <Table>
                <TableHead>
                  <TableRow selected>
                    <TableCell>
                      <TableSortLabel>Fecha de Corte</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel># de Corte</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Saldo al corriente</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Saldo Pendiente</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Vencimiento</TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pending.map((index) => (
                    <TableRow key={index.cutoff_id}>
                      <TableCell component="th">
                        {`${simpleDateFunction(index.date)} `}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {index.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {`$ ${index.amount}`}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {`$ ${index.balance}`}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {`${index.deadline_date
                          .split("T")[0]
                          .replace(/-/gm, "/")} `}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
          {currentTab === 3 && (
            <Box className={classes.customerInfoCards}>
              <CustomerInfoCards
                {...personalData}
                {...residentialInfo}
                {...laboralInfo}
                {...references}
              />
            </Box>
          )}
          {currentTab === 4 && (
            <Box>
              <Collections customerID={customerID} />
            </Box>
          )}
          {currentTab === 5 && (
            <CreditInformationPage creditLineId={credit_line_id} />
          )}
        </Box>
      </Box>
    </PageContainer>
  );
};
export { CustomerTransactions as default };
