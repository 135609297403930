import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import Barcode from "react-barcode";
import {
  Box,
  IconButton,
  MenuItem,
  Modal,
  Snackbar,
  Link,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import Close from "@material-ui/icons/Close";
import { copyTextToClipboard } from "../../../../constants/copyContent";
import useStyles from "../SubMenuPage.styles";
import Text from "../../../../components/Text";
import TextInput from "../../../../components/TextInput";
import Icons from "../../../../assets/icons";
import * as ACTION from "../../../../reducers/transactions.actionTypes";
import { getConektaReference, getArcusReference } from "./General.actions";
import CopyButton from "../../../../components/CopyButton/CopyButton";
import Button from "../../../../components/Button";
import {
  putChangeBankAccount,
  clearPutChangeBankAccount,
} from "../../../../reducers/users.action";
import { listBanks, rdListBanks } from "../../../../constants/constants";
import { getPolicy } from "../../../../reducers/insurance.action";
import moment from "moment";
import { formatCurrency } from "../../../../utils/utils";
import I18n from "../../../../utils/i18n";

const GeneralPage = ({
  shokeeper_detail,
  credit_line_shop_detail,
  accountId,
  references_detail,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [conektaReferenceTxt, setConektaReferenceTxt] = useState("-");
  const [conektaReferenceImg, setConektaReferenceImg] = useState("");
  const [arcusReferenceTxt, setArcusReferenceTxt] = useState("-");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [policyData, setPolicyData] = useState([]);

  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  const useBanks = i18n.country === "mx" ? listBanks : rdListBanks;

  const { isFetchingTransaction, conektaReference, arcusReference, error } =
    useSelector((state) => state.transactionsReducer);

  const { dataPolicy } = useSelector((state) => state.insuranceReducer);

  const onClickGetConektaReference = () => {
    if (conektaReference) {
      return;
    }
    dispatch(
      getConektaReference(accountId, credit_line_shop_detail.account_type_id)
    );
  };

  const onClickGetArcusReference = () => {
    if (arcusReference) {
      return;
    }
    dispatch(
      getArcusReference(accountId, credit_line_shop_detail.account_type_id)
    );
  };

  useEffect(() => {
    dispatch({
      type: ACTION.SET_STATE,
      payload: {
        label: "conektaReference",
        value: undefined,
      },
    });
    dispatch({
      type: ACTION.SET_STATE,
      payload: {
        label: "arcusReference",
        value: undefined,
      },
    });
    dispatch(getPolicy(accountId));
  }, []);

  useEffect(() => {
    if (Object.keys(dataPolicy).length !== 0) {
      setPolicyData(dataPolicy?.data?.policies);
    }
  }, [dataPolicy]);

  useEffect(() => {
    if (conektaReference) {
      setConektaReferenceTxt(conektaReference.reference_payment);
      setConektaReferenceImg(conektaReference.url_bar_code);
    } else {
      setConektaReferenceTxt("-");
      setConektaReferenceImg("");
    }
  }, [conektaReference]);

  useEffect(() => {
    if (arcusReference) {
      setArcusReferenceTxt(arcusReference.code);
    } else {
      setArcusReferenceTxt("-");
    }
  }, [arcusReference]);

  const sendChangeBankAccount = () => {
    let body = {
      bank_id: bankName,
      bank_account_number: bankNumber,
    };
    dispatch(putChangeBankAccount("SK", accountId, body))
      .then(() => {
        setIsOpenModal(false);
        setBankName("");
        setBankNumber("");
        dispatch(clearPutChangeBankAccount());
        window.location.reload();
      })
      .catch((error) => {
        setIsOpenModal(false);
        setIsSnackBarOpen(true);
        setBankName("");
        setBankNumber("");
        dispatch(clearPutChangeBankAccount());

        setTimeout(() => {
          setIsSnackBarOpen(false);
        }, 3000);
      });
  };

  const _avalsComponent = (list) => {
    return list.map(
      (item) =>
        item.bureau_decision === "APPROVED" && (
          <div
            style={{
              display: "flex",
              width: "400px",
              height: "258px",
              border: "1px solid #e8e8fb",
              flexDirection: "column",
              borderRadius: 5,
              padding: 15,
              marginRight: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                src={Icons.accountGeneral}
                style={{ width: 30, height: 30, marginRight: 5 }}
              />
              <Text
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {item.aval_type === "PARTNER"
                  ? "Datos del Conyuge"
                  : "Datos del Aval"}
              </Text>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
            >
              <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                Nombre:
              </Text>
              <Text
                style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
              >
                {item?.name || ""} {item?.lastname || ""}{" "}
                {item?.maternal_lastname || ""}
              </Text>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                Correo:
              </Text>
              <Text
                style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
              >
                {item?.email || ""}
              </Text>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                Celular:
              </Text>
              <Text
                style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
              >
                {item?.phone || ""}
              </Text>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                {i18n.rfc}:
              </Text>
              <Text
                style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
              >
                {item?.rfc || ""}
              </Text>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                Dirección:
              </Text>
              <Text
                style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
              >
                {(item?.address && item.address?.street_name) || ""}{" "}
                {(item?.address && item.address?.street_number) || ""}{" "}
                {(item?.address && item.address?.neighborhood) || ""}{" "}
                {(item?.address && item.address?.city) || ""}{" "}
                {(item?.address && item.address?.state) || ""}{" "}
                {(item?.address && item.address?.zip_code) || ""}
              </Text>
            </div>
          </div>
        )
    );
  };

  return (
    <div
      className={classes.transactionContainer}
      style={{ alignItems: "flex-start", flexDirection: "column" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "400px",
            height: "258px",
            border: "1px solid #e8e8fb",
            flexDirection: "column",
            borderRadius: 5,
            padding: 15,
            marginRight: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={Icons.storeGeneral}
              style={{ width: 30, height: 30, marginRight: 5 }}
            />
            <Text
              style={{ color: "#000000", fontSize: "16px", fontWeight: "bold" }}
            >
              Datos de la tienda
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>Nombre:</Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {credit_line_shop_detail?.shop_name || ""}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>
              Teléfono fijo:
            </Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {credit_line_shop_detail?.shop_phone_number || ""}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>
              Dirección:
            </Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {credit_line_shop_detail?.street_name || ""}{" "}
              {credit_line_shop_detail?.street_number || ""}{" "}
              {credit_line_shop_detail?.neighborhood || ""}{" "}
              {credit_line_shop_detail?.delegation || ""}{" "}
              {credit_line_shop_detail?.state || ""}
            </Text>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "400px",
            height: "258px",
            border: "1px solid #e8e8fb",
            flexDirection: "column",
            borderRadius: 5,
            padding: 15,
            marginRight: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={Icons.accountGeneral}
              style={{ width: 30, height: 30, marginRight: 5 }}
            />
            <Text
              style={{ color: "#000000", fontSize: "16px", fontWeight: "bold" }}
            >
              Datos del responsable
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>Nombre:</Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {credit_line_shop_detail?.name || ""}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>Correo:</Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {(shokeeper_detail?.data &&
                shokeeper_detail.data?.owner_data &&
                shokeeper_detail.data.owner_data?.Email) ||
                ""}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>Celular:</Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {(shokeeper_detail?.data &&
                shokeeper_detail.data?.owner_data &&
                shokeeper_detail.data.owner_data?.PhoneNumber) ||
                ""}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>
              {i18n.rfc}:
            </Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {(shokeeper_detail?.data &&
                shokeeper_detail.data?.registration_data &&
                shokeeper_detail.data.registration_data?.shop &&
                shokeeper_detail.data.registration_data?.shop?.rfc) ||
                ""}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>
              Dirección:
            </Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {(shokeeper_detail?.data &&
                shokeeper_detail.data?.registration_data &&
                shokeeper_detail.data.registration_data?.address &&
                shokeeper_detail.data.registration_data.address.street_name) ||
                ""}{" "}
              {(shokeeper_detail?.data &&
                shokeeper_detail.data?.registration_data &&
                shokeeper_detail.data.registration_data?.address &&
                shokeeper_detail.data.registration_data.address
                  .street_number) ||
                ""}{" "}
              {(shokeeper_detail?.data &&
                shokeeper_detail.data?.registration_data &&
                shokeeper_detail.data.registration_data?.address &&
                shokeeper_detail.data.registration_data.address.neighborhood) ||
                ""}{" "}
              {(shokeeper_detail?.data &&
                shokeeper_detail.data?.registration_data &&
                shokeeper_detail.data.registration_data?.address &&
                shokeeper_detail.data.registration_data.address.city) ||
                ""}{" "}
              {(shokeeper_detail?.data &&
                shokeeper_detail.data?.registration_data &&
                shokeeper_detail.data.registration_data?.address &&
                shokeeper_detail.data.registration_data.address.state) ||
                ""}
            </Text>
          </div>
        </div>
        {shokeeper_detail?.data &&
          shokeeper_detail.data?.registration_data &&
          shokeeper_detail.data.registration_data?.avals &&
          shokeeper_detail.data.registration_data.avals.length !== 0 &&
          _avalsComponent(shokeeper_detail.data.registration_data.avals)}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "400px",
            border: "1px solid #e8e8fb",
            flexDirection: "column",
            borderRadius: 5,
            padding: 15,
            marginRight: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={Icons.balanceGeneral}
              style={{ width: 30, height: 30, marginRight: 5 }}
            />
            <Text
              style={{ color: "#000000", fontSize: "16px", fontWeight: "bold" }}
            >
              Datos bancarios
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>Banco:</Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {credit_line_shop_detail?.bank || ""}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>
              N&uacute;mero de cuenta:
            </Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              *{credit_line_shop_detail?.bank_number || ""}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>Titular:</Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {credit_line_shop_detail?.name || ""}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text
              style={{ color: "#3a4760", fontSize: "16px", cursor: "pointer" }}
              onClick={onClickGetConektaReference}
            >
              Mostrar Oxxo:
            </Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {conektaReferenceTxt}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text
              style={{ color: "#3a4760", fontSize: "16px", cursor: "pointer" }}
              onClick={onClickGetArcusReference}
            >
              Mostrar 7eleven:
            </Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {arcusReferenceTxt}
            </Text>
          </div>
          <Button
            className={classes.buttonChanceBank}
            variant="contained"
            color="secondary"
            onClick={() => setIsOpenModal(true)}
          >
            Cambiar cuenta de banco
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            width: "400px",
            height: "200px",
            border: "1px solid #e8e8fb",
            flexDirection: "column",
            borderRadius: 5,
            padding: 15,
            marginRight: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={Icons.balanceGeneral}
              style={{ width: 30, height: 30, marginRight: 5 }}
            />
            <Text
              style={{ color: "#000000", fontSize: "16px", fontWeight: "bold" }}
            >
              Identificadores internos
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>
              N&uacute;mero de Tel&eacute;fono:
            </Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {shokeeper_detail?.data?.registration_data?.phone_number || ""}
              <CopyButton
                elementToCopy={
                  shokeeper_detail?.data?.registration_data?.phone_number || ""
                }
                toolTipText={"Copiar número de teléfono"}
              />
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>
              ID de cuenta:
            </Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {shokeeper_detail?.data?.registration_data?.id || ""}
              <CopyButton
                elementToCopy={
                  shokeeper_detail?.data?.registration_data?.id || ""
                }
                toolTipText={"Copiar id de la cuenta"}
              />
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
            <Text style={{ color: "#3a4760", fontSize: "16px" }}>
              ID de cr&eacute;dito:
            </Text>
            <Text style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}>
              {credit_line_shop_detail?.credit_line_id || ""}
              <CopyButton
                elementToCopy={credit_line_shop_detail?.credit_line_id || ""}
                toolTipText={"Copiar id del crédito"}
              />
            </Text>
          </div>
        </div>
        <div style={{ display: "flex", width: "400px", height: "200px" }} />
      </div>
      {references_detail?.length && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 15,
          }}
        >
          {references_detail?.map((ref) => (
            <div
              style={{
                display: "flex",
                width: "400px",
                height: "140px",
                border: "1px solid #e8e8fb",
                flexDirection: "column",
                borderRadius: 5,
                padding: 15,
                marginRight: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src={Icons.reference}
                  style={{ width: 30, height: 30, marginRight: 5 }}
                />
                <Text
                  style={{
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Referencias
                </Text>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
              >
                <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                  Nombre:
                </Text>
                <Text
                  style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
                >
                  {ref?.full_name || ""}
                </Text>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 15 }}
              >
                <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                  N&uacute;mero de cuenta:
                </Text>
                <Text
                  style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
                >
                  {ref?.phone_number || ""}
                </Text>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 15 }}
              >
                <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                  Relación:
                </Text>
                <Text
                  style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
                >
                  {ref?.relationship_type_desc_sp || ""}
                </Text>
              </div>
            </div>
          ))}
        </div>
      )}
      {Object.keys(policyData).length !== 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 15,
          }}
        >
          {policyData?.map((policy) => (
            <div
              style={{
                display: "flex",
                width: "400px",
                border: "1px solid #e8e8fb",
                flexDirection: "column",
                borderRadius: 5,
                padding: 15,
                marginRight: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src={Icons.balanceGeneral}
                  style={{ width: 30, height: 30, marginRight: 5 }}
                />
                <Text
                  style={{
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {policy?.policy_type !== "SINGLE_LIFE_INSURANCE" &&
                  policy?.policy_type !== "FAMILY_LIFE_INSURANCE"
                    ? "Seguro de Deuda"
                    : policy?.policy_type === "SINGLE_LIFE_INSURANCE"
                    ? "Seguro de Vida (Soltero)"
                    : "Seguro de Vida (Casado)"}
                </Text>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
              >
                <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                  Poliza:
                </Text>
                <Text
                  style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
                >
                  {policy?.policy_number || "-"}
                </Text>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 15 }}
              >
                <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                  Fecha de contratación:
                </Text>
                <Text
                  style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
                >
                  {moment(policy?.issue_date).format("L") || "-"}
                </Text>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 15 }}
              >
                <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                  Monto cubierto:
                </Text>
                <Text
                  style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
                >
                  {formatCurrency(policy?.coverage_amount?.number) +
                    " " +
                    policy?.coverage_amount?.currency || "-"}
                </Text>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 15 }}
              >
                <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                  Prima mensual:
                </Text>
                <Text
                  style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
                >
                  {formatCurrency(policy?.insurance_payment_amount?.number) +
                    " " +
                    policy?.insurance_payment_amount?.currency || "-"}
                </Text>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 15 }}
              >
                <Text style={{ color: "#3a4760", fontSize: "16px" }}>
                  Estado:
                </Text>
                <Text
                  style={{ color: "#000000", fontSize: "16px", marginLeft: 5 }}
                >
                  {policy?.policy_status === "ACTIVE"
                    ? "Activo"
                    : "Inactivo" || ""}
                </Text>
              </div>
              <Link
                href={policy?.url}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#0253cc",
                  fontSize: "14px",
                  marginTop: 15,
                  textDecoration: "underline",
                }}
              >
                Ver contrato
              </Link>
            </div>
          ))}
        </div>
      )}
      <div>
        <br />
        {conektaReferenceImg && (
          <div>
            <div>Código de barras para el pago desde Oxxo:</div>
            <br />
            <img src={conektaReferenceImg} />
          </div>
        )}
        {arcusReference && (
          <div>
            <div>Código QR para el pago desde 7eleven:</div>
            <br />
            <QRCode value={arcusReferenceTxt} size="128" />
            <br />
            <Barcode value={arcusReferenceTxt} width="1" fontSize="10" />
          </div>
        )}
      </div>
      <Modal
        open={isOpenModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalEditBank}
      >
        <Box className={classes.modalContainerEditBank}>
          <Box className={classes.rowContainer}>
            <Text className={classes.titleModal}>Cambiar cuenta de banco</Text>
            <IconButton
              className={classes.buttonClose}
              onClick={() => (
                setIsOpenModal(false), setBankName(""), setBankNumber("")
              )}
            >
              <Close style={{ color: "#0253cc" }} />
            </IconButton>
          </Box>
          <TextInput
            className={classes.formElementModal}
            label="Banco"
            variant="standard"
            select
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
          >
            {useBanks.map((bank, index) => (
              <MenuItem key={index} value={bank.id}>
                {bank.name}
              </MenuItem>
            ))}
          </TextInput>
          <TextInput
            className={classes.formElementModal}
            label={i18n.interbankName}
            variant="standard"
            type="number"
            value={bankNumber}
            onChange={(e) => setBankNumber(e.target.value)}
            inputProps={{ maxLength: i18n.interbankLength }}
            placeholder={`Escribe la ${i18n.interbankName} interbancaria a ${i18n.interbankLength} dígitos`}
          />
          <Box className={classes.buttonModalContainer}>
            <Button
              style={{
                backgroundColor: "#FFF",
                color: "#0253cc",
                fontWeight: "600",
                fontSize: "12px",
              }}
              onClick={() => (
                setIsOpenModal(false), setBankName(""), setBankNumber("")
              )}
            >
              Cancelar
            </Button>
            <Button
              className={classes.buttonConfirmChange}
              variant="contained"
              color="secondary"
              onClick={sendChangeBankAccount}
              isDisabled={
                bankName !== "" && bankNumber.length === 18 ? false : true
              }
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar open={isSnackBarOpen}>
        <Alert severity="error" variant="filled">
          <AlertTitle>{"ERROR"}</AlertTitle>
          Error al modificar la cuenta de banco
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GeneralPage;
