import React, { useEffect } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../Shops/OrderedTableHead.style";
import Text from "../../components/Text";
import I18n from "../../utils/i18n";

export const OrderedTableHead = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, orderBy, sort } = useSelector((state) => state.customersState);
  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  useEffect(() => {}, [orderBy, sort]);

  const headCells = [
    {
      id: "account_id",
      numeric: false,
      disablePadding: false,
      label: "ID Sucursal",
    },
    {
      id: "trade_name",
      numeric: false,
      disablePadding: false,
      label: "Nombre Bodega",
    },
    {
      id: "ws_trade_name",
      numeric: false,
      disablePadding: false,
      label: "Nombre Sucursal",
    },
    {
      id: "name_complete",
      numeric: false,
      disablePadding: false,
      label: "Dueño",
    },
    {
      id: "phone_number",
      numeric: true,
      disablePadding: false,
      label: "Teléfono",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Correo electrónico",
    },
    {
      id: "rfc",
      numeric: false,
      disablePadding: false,
      label: `${i18n.rfc} Dueño`,
    },
    {
      id: "business_rfc",
      numeric: false,
      disablePadding: false,
      label: `${i18n.rfc} Bodega`,
    },
    {
      id: "balance",
      numeric: true,
      disablePadding: false,
      label: "Balance al corte",
    },
    {
      id: "qr",
      numeric: false,
      disablePadding: false,
      label: "QR",
    },
  ];

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
            sort={orderBy === headCell.id ? sort : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sort : "asc"}
            >
              <Text className={classes.titleLabel}>{headCell.label}</Text>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderedTableHead;
