import React from "react";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import ErrorTwoToneIcon from "@material-ui/icons/ErrorTwoTone";
import { useHistory } from "react-router-dom";
import Text from "../Text";
import { StepArrowIcon } from "../Icons/StepArrowIcon";
import useStyles from "../../containers/CustomerDetails/CustomerDetails.style";
import Box from "../Layout/Box";
import I18n from "../../utils/i18n";
const i18n = I18n(process.env.REACT_APP_COUNTRY);

const StepArrow = (props) => {
  const classes = useStyles();
  let stepText = "";
  let arrowColor = "#14b497";
  const history = useHistory();

  const { step, done, alert, error, selected, current, disabled } = props;

  switch (step) {
    case 1:
      stepText = "SOBRE MÍ";
      break;
    case 2:
      stepText = "MI CASA";
      break;
    case 3:
      stepText = "MI TRABAJO";
      break;
    case 4:
      stepText = "REFERENCIAS";
      break;
    case 5:
      stepText = "CONFIRMACIÓN";
      break;
    case 6:
      stepText = "VALIDACIÓN TELÉFONO";
      break;
    case 7:
      stepText = "MATI";
      break;
    case 8:
      stepText = `VALIDACIÓN ${i18n.appName.toUpperCase()}`;
      break;
    default:
      break;
  }

  if (current) {
    arrowColor = "#0253cc";
  } else if (disabled) {
    arrowColor = "#3a4760";
  } else if (error) {
    arrowColor = "#ea4b4b";
  }
  return (
    <Box className={classes.stepArrowContainer}>
      <StepArrowIcon
        onClick={() => {
          if (!disabled) {
            history.push(props.to);
          }
        }}
        aria-label="delete"
        className={classes.stepArrowChild}
        color={arrowColor}
        size={2.2}
        disabled={disabled}
      >
        <Box className={classes.stepArrowLabel}>
          {done && <CheckIcon htmlColor="white" />}
          {error && <ErrorTwoToneIcon htmlColor="white" />}
          {alert && <ErrorTwoToneIcon htmlColor="#ffc300" />}
          <Text>{`${props.step} | ${stepText}`}</Text>
        </Box>
      </StepArrowIcon>
    </Box>
  );
};

export default StepArrow;

StepArrow.propTypes = {
  step: PropTypes.number.isRequired,
};
