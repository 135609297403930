import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "../../../components/Layout/Box";
import PageContainer from "../../../components/PageContainer";
import SectionTitle from "../../../components/SectionTitle";
import { SettlementsTable } from "./SettlementsTable";
import useStyles from "./ShopsPage.styles";
import MissingDataAlert from "../../../components/MissingDataAlert/MissingDataAlert";
import {
  getSearchShops,
  getSettlementDetailByType,
  getTransactionTypes,
  postTransaction,
} from "../ShopsPage.actions";
import Button from "../../../components/Button";
import Text from "../../../components/Text";
import Modal from "../../../components/CustomModal/CustomModal";
import {
  banks,
  massiveTransactionLayouts,
  rdBanks,
} from "../../../constants/constants";
import I18n from "../../../utils/i18n";

const SettlementDetails = (props) => {
  const { resourceId } = props.match.params;
  const resourceType = "POSITIVE";

  const { selectedShops } = useSelector((state) => state.shopsState);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
  const [registerModalIsVisible, setRegisterModalIsVisible] = useState(false);
  const { searchShops, shops, error, transactionTypes } = useSelector(
    (state) => state.shopsState
  );
  const [selectedSearchShop, setSelectedSearchShop] = useState(undefined);
  const [depositOption, setDepositOption] = useState(undefined);
  const [depositAmount, setDepositAmount] = useState(0);
  const [depositSuccess, setDepositSuccess] = useState(false);
  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  const useBanks = i18n.country === "mx" ? banks : rdBanks;

  useEffect(() => {
    if (depositSuccess) {
      setTimeout(() => {
        setDepositSuccess(false);
        setRegisterModalIsVisible(false);
      }, 2000);
    }
  }, [depositSuccess]);

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);

  useEffect(() => {
    dispatch(getSettlementDetailByType(resourceId, resourceType));
    dispatch(getSearchShops());
    dispatch(getTransactionTypes());
  }, []);

  const generateCSVFile = () => {
    const generateStoreObject = (layoutType) => {
      const mapStoreToLayout = (layoutType, layoutKey, selectedShop) => {
        const toTitleCase = (phrase) =>
          phrase
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

        if (layoutType === "LTX05") {
          switch (layoutKey) {
            case "depositAccount":
              return selectedShop.bank_account;
            case "bankCode":
              return (
                useBanks.find(
                  (bank) => bank.value === selectedShop.shopkeeper_account_bank
                ) || { code: "ERROR" }
              ).code;
            case "beneficiary":
              return toTitleCase(selectedShop.shopkeeper_full_name)
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, "");
            default:
              return "undefined";
            case "amount":
              return selectedShop.balance;
            case "concept":
              return selectedShop.shopkeeper_full_name;
            case "orderingReference":
              return selectedShop.shopkeeper_id.split("-")[0];
            case "beneficiaryEmail":
              return selectedShop.email;
          }
        } else if (layoutType === "LTX07") {
          switch (layoutKey) {
            case "depositAccount":
              return selectedShop.bank_account;
            case "bankCode":
              return banks.find(
                (bank) => bank.title === selectedShop.shopkeeper_account_bank
              );
            case "beneficiary":
              return toTitleCase(selectedShop.shopkeeper_full_name)
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, "");
            default:
              return "ERROR";
            case "amount":
              return selectedShop.balance;
            case "concept":
              return selectedShop.shopkeeper_full_name;
            case "orderingReference":
              return selectedShop.shopkeeper_id.split("-")[0];
            case "beneficiaryEmail":
              return selectedShop.email;
            case "dateOfApplication":
              return (
                ("0" + new Date().getDate()).slice(-2) +
                ("0" + (new Date().getMonth() + 1)).slice(-2) +
                new Date().getFullYear()
              );
          }
        }
      };

      const layout = massiveTransactionLayouts[layoutType];

      if (layout === undefined) {
        throw Error(`Can't find layout type ${layoutType}`);
      }

      return (selectedShop) => {
        const storeObject = layout.fields
          .map(({ fieldName }) => fieldName)
          .reduce(
            (object, currentVal) => ({
              ...object,
              [currentVal]: null,
            }),
            {}
          );

        layout.fields.forEach((field) => {
          let formattedValue = "";
          let storeValue = "";

          if (field && "value" in field) {
            storeValue = field.value;
          } else {
            storeValue = mapStoreToLayout(
              layoutType,
              field.fieldName,
              selectedShop
            );
          }

          formattedValue = storeValue;

          const { fieldRules } = field;

          if (storeValue) {
            const { padding, length } = fieldRules;

            if (length) {
              try {
                formattedValue = storeValue.slice(0, length);
              } catch (e) {
                console.warn(e, storeValue);
              }
            }

            if ("type" in fieldRules) {
              switch (fieldRules.type) {
                case "currency":
                  formattedValue = formattedValue
                    .replace("$", "")
                    .replace(".", "")
                    .replace(",", "");
                  break;
                case "concept":
                  formattedValue = `Liquidacion YoFio ${new Date()
                    .toISOString()
                    .split("T")[0]
                    .replace(/-/gm, "")}`;
                  break;
                default:
                  break;
              }
            }

            if ("align" in fieldRules) {
              if (fieldRules.align === "right") {
                console.log(
                  `align ${formattedValue} to the ${fieldRules.align} to ${length} size with ${padding} character`
                );
                formattedValue = formattedValue.padStart(length, padding);
              } else {
                formattedValue = formattedValue.padEnd(length, padding);
              }
            }
          }

          storeObject[field.fieldName] = formattedValue;
        });

        console.log(storeObject);

        return storeObject;
      };
    };

    const arrayToCSV = (
      data = null,
      columnDelimiter = "",
      lineDelimiter = "\n"
    ) => {
      let result;
      let ctr;
      let keys;

      if (data === null || !data.length) {
        return null;
      }

      keys = Object.keys(data[0]);

      result = "";

      data.forEach((item) => {
        ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) {
            result += columnDelimiter;
          }

          result +=
            typeof item[key] === "string" && item[key].includes(columnDelimiter)
              ? `${item[key]}`
              : item[key];
          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    };
    const download = (content, fileName, mimeType) => {
      const a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        // html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        window.location.href = `data:application/octet-stream,${encodeURIComponent(
          content
        )}`; // only this mime type is supported
      }
    };

    const santanderShops = selectedShops.filter(
      (selectedShop) => selectedShop.shopkeeper_account_bank === "santander"
    );
    const otherShops = selectedShops.filter(
      (selectedShop) => selectedShop.shopkeeper_account_bank !== "santander"
    );

    if (santanderShops.length > 0) {
      const SantanderShopsForCSV = santanderShops.map((santanderShop) =>
        generateStoreObject("LTX07")(santanderShop)
      );
      const santanderDownloadContent = arrayToCSV(SantanderShopsForCSV, "");
      download(
        santanderDownloadContent,
        "tiendas-santander.txt",
        "text/csv;encoding:utf-8"
      );
    }

    if (otherShops.length > 0) {
      const otherShopsForCSV = otherShops.map((otherShop) =>
        generateStoreObject("LTX05")(otherShop)
      );
      const otherDownloadContent = arrayToCSV(otherShopsForCSV, "");
      download(
        otherDownloadContent,
        "tiendas-otros.txt",
        "text/csv;encoding:utf-8"
      );
    }
  };

  const handleDepositConfirm = () => {
    dispatch(
      postTransaction({
        account_id: selectedSearchShop.account_shopkeeper_id,
        transaction_type_id: depositOption.transaction_type_id,
        amount: parseFloat(depositAmount),
      })
    ).then((responseCode) => {
      if (responseCode === 200) {
        setDepositSuccess(true);
      }
    });
  };
  return (
    <PageContainer>
      <Box className={classes.rootContainer}>
        <Box
          className={classes.topContainer}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <SectionTitle>Liquidaciones Detalle</SectionTitle>
          {nothingFoundNoticeShown && (
            <MissingDataAlert status="BAD" text="No se encontraron Tiendas" />
          )}
          <Button
            disabled={selectedShops.length === 0}
            onClick={generateCSVFile}
          >
            <Text>Descargar CSV</Text>
          </Button>
          <Button onClick={() => setRegisterModalIsVisible(true)}>
            <Text>Caja</Text>
          </Button>

          <Modal
            title="Caja"
            closeButton
            open={registerModalIsVisible}
            setOpen={(value) => {
              setRegisterModalIsVisible(value);
            }}
          >
            {!depositSuccess && (
              <Box className={classes.depositModal}>
                <Box component="form">
                  <Autocomplete
                    id="combo-box-demo"
                    className={classes.shopSelector}
                    options={searchShops}
                    getOptionLabel={(option) =>
                      option.personhood_name +
                      " (" +
                      option.shopkeeper_phone_number +
                      ")" +
                      " [" +
                      option.shopkeeper_name +
                      "]"
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tiendas"
                        variant="outlined"
                      />
                    )}
                    onChange={(event, value, reason) => {
                      setSelectedSearchShop(value);
                    }}
                    autoComplete
                  />

                  <Autocomplete
                    id="combo-box-demo"
                    className={classes.shopSelector}
                    options={transactionTypes}
                    getOptionLabel={(option) => option.description}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Transacción"
                        variant="outlined"
                      />
                    )}
                    onChange={(event, value, reason) => {
                      setDepositOption(value);
                    }}
                    autoComplete
                  />
                </Box>
                <TextField
                  className={classes.amountInput}
                  label="Monto"
                  placeholder="$00.00"
                  value={depositAmount}
                  onChange={(event) => setDepositAmount(event.target.value)}
                />
                <Button
                  style={{
                    backgroundColor:
                      selectedShops === undefined || depositOption === undefined
                        ? "gray"
                        : "#0253cc",
                    color: "white",
                    width: "30%",
                    alignSelf: "flex-end",
                  }}
                  onClick={() => {
                    handleDepositConfirm();
                  }}
                  disabled={
                    selectedShops === undefined || depositOption === undefined
                  }
                >
                  <Text>Confirmar</Text>
                </Button>
              </Box>
            )}
            {depositSuccess && (
              <Box className={classes.depositModal}>
                <Text>Transacción exitosa</Text>
              </Box>
            )}
          </Modal>
        </Box>
        <Box className={classes.tableContainer}>
          <SettlementsTable resources={shops} />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default SettlementDetails;
