import React from "react";
import Text from "../../../components/Text";
import Box from "../../../components/Layout/Box";
import useStyles from "./ReferenceInfo.style";
import MissingDataAlert from "../../../components/MissingDataAlert/MissingDataAlert";
import LabelValueTextList from "../../../components/LabelValueTextList/LabelValueTextList";
import I18n from "../../../utils/i18n";

const CustomerReferenceInfo = (props) => {
  const classes = useStyles();
  const i18n = I18n(process.env.REACT_APP_COUNTRY);

  let missingInfo;

  if (!props[0] || !props[1]) {
    missingInfo = true;
  }

  function relationInfo(rel) {
    switch (rel) {
      case "FAMILY_MEMBER":
        return "Familiar";
      case "FRIEND":
        return "Amigo(a)";
      case "NEIGHBOUR":
        return "Vecino(a)";
      default:
        return missingInfo;
    }
  }

  const rows = [
    {
      label: "Nombre",
      value: props[0] ? props[0].name : undefined,
    },

    {
      label: "Teléfono",
      value: props[0] ? props[0].phoneNumber.split(i18n.lada) : undefined,
    },
    {
      label: "Relación",
      value: props[0] ? relationInfo(props[0].relation) : undefined,
    },
    {
      label: "Nombre",
      value: props[1] ? props[1].name : undefined,
    },
    {
      label: "Teléfono",
      value: props[1] ? props[1].phoneNumber.split(i18n.lada) : undefined,
    },
    {
      label: "Relación",
      value: props[1] ? relationInfo(props[1].relation) : undefined,
    },
  ];

  return (
    <Box className={classes.ReferenceInfo}>
      {missingInfo && (
        <MissingDataAlert text="El cliente no ha completado estos datos." />
      )}
      <Text variant="h3">REFERENCIAS</Text>
      <Box style={{ display: "flex" }}>
        <LabelValueTextList rows={rows} />
      </Box>
    </Box>
  );
};

export default CustomerReferenceInfo;
